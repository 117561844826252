import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ProductImage from '../../../assets/assets/images/dummy_zourie_auctions1.png';
import Textarea from '@mui/joy/Textarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { orderItemFeedback } from '../../../redux/actions/orderActions';
import ReactStars from "react-rating-stars-component";
import Rating from "../../../components/product/sub-components/ProductRating";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
};

const LeaveFeedbackHistory = ({
  open2,
  handleOpen2,
  handleClose2,
  inFeedback
}) => {
  const user = useSelector(store => store.userData.user)

  const dispatch = useDispatch();
  const { addToast } = useToasts();


  const [feedback, setFeedback] = useState({
    user: null,
    product: null,
    review: '',
    rating: null
  })

  const ratingChanged = (newRating) => {
    setFeedback({ ...feedback, rating: newRating })
  };

  const handleFeedbackOfProduct = (product) => {

    if (feedback.review && feedback.rating) {

      dispatch(orderItemFeedback(feedback, setFeedback, addToast));
      handleClose2();

    }
    else {
      addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true })
    }
  };




  useEffect(() => {
    setFeedback({
      ...feedback,
      user: user.id,
      product: inFeedback?.id,
    })
  }, [inFeedback])


  return (
    <>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='seller-review-container'>
          <div className='cancel_order_modal_close'>
            <FontAwesomeIcon onClick={() => handleClose2()} icon={faXmark} />
          </div>
          <div className="feedback_modal-wrrpr">
            <h6>Leave a Feedback</h6>
            <div className='feedback_modal-body'>
              <img src={inFeedback && inFeedback.product.product_image[0].upload_image} alt="" />
              <div className='feedback_product_info'>
                <ul className="star_rating star_color">
                  <Rating ratingValue={inFeedback && inFeedback.product.review_avg} />
                </ul>
                <h1>{inFeedback && inFeedback.product.product_title}</h1>
                <p dangerouslySetInnerHTML={{ __html: inFeedback && inFeedback.product.description }}></p>
              </div>
            </div>
            <div className='feedback_modal-rating'>
              <h4>Write your experience about this Seller.</h4>
              <textarea
                onChange={(e) => setFeedback({ ...feedback, review: e.target.value })}
                className='feedback_modal-rating_textarea'
                maxLength='450'
              />
              <div className='feedback_modal-rating_Star_body'>
                <div className='feedback_modal-rating_Star'>
                  <h4>Rate this Seller:</h4>
                  <ul className="star_rating ">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={24}
                      activeColor="#ffd700"
                    />
                  </ul>
                </div>
                <div className='myform_dv feedback_modal-btn'>
                  <button
                    className="myform_btn"
                    type='button'
                    disabled={feedback.rating < 0}
                    onClick={() => handleFeedbackOfProduct(inFeedback)}>Submit</button>
                  <button type='button' className='cancel_Btn' onClick={() => handleClose2()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default LeaveFeedbackHistory;