import axios from 'axios';
import { BEST_SELLERS_PRODUCTS, NEW_ARRIVALS_PRODUCTS } from "../../consts";

export const NEW_ARRIVALS = "FETCH_NEW_ARRIVALS";
export const BEST_SELLERS = "FETCH_BEST_SELLERS";


const newArrivalsSuccess = newArrivals => ({
    type: NEW_ARRIVALS,
    payload: newArrivals
  });

const bestSellersSuccess = bestSellers => ({
    type: BEST_SELLERS,
    payload: bestSellers
  });


export const fetchNewArrivals = newArrivals => {
  const token = localStorage.getItem("Token");
    return dispatch => {
      axios.get(NEW_ARRIVALS_PRODUCTS,{
        headers: {
          Authorization: `Token ${token}`,
        }
      })
      .then(response => {
      dispatch(newArrivalsSuccess(response.data));
    })
    .catch(error => {
      console.log(error);
    });
    };
  };


export const fetchBestSellersProducts = bestSellers => {
    return dispatch => {
      axios.get(BEST_SELLERS_PRODUCTS)
      .then(response => {
      dispatch(bestSellersSuccess(response.data));
    })
    .catch(error => {
      console.log(error);
    });
    };
  }