import PropTypes from "prop-types";
import React, { useEffect, Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Sidebar from "./Sidebar";
import OrderDetailsSeller from "./OrderDetailsSeller";


const OrderDeatilsSellerPage = () => {



    return (
        <Fragment>
            <MetaTags>
                <title>Zourie | Manage Orders</title>
                <meta
                    name="description"
                    content="Compare page of Zourie react minimalist eCommerce template."
                />
            </MetaTags>
            <LayoutOne headerTop="visible" header3="hide">

                <section className="information_panels">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 info_pnl_sidebar">
                                <Sidebar />
                            </div>
                            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                <OrderDetailsSeller />
                            </div>
                        </div>
                    </div>
                </section>

            </LayoutOne>
        </Fragment>
    )
}

OrderDeatilsSellerPage.propTypes = {
    location: PropTypes.object
};

export default OrderDeatilsSellerPage;
