import PropTypes from "prop-types";
import React, { Fragment, useState} from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { userLogin, UserForgotPassword } from "../../redux/actions/Login-Register";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../src/redux/actions/Login-Register";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import Loader from "./Loader";



// import setActiveKey




const mapStateToProps = (state) => {
  return {
    products: state.productData.products,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // product: () => dispatch(product())
  };
};


const ForgetPassword = (user) => {
  const loader = useSelector(state => state.userData.isLoading);

  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");



  const { addToast } = useToasts();

  const handleLoginSubmit = e => {
    dispatch(userLogin(email, password, addToast));
    fetchUserProfile(user.key);

  };


  const handleSubmitPassword = e => {
    e.preventDefault();
    dispatch(UserForgotPassword(email, addToast));
    setEmail('')
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
  };





  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Forgot Password</title>
        <meta
          name="description"
        />
      </MetaTags>
     
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/login"}>
        Forgot Password
      </BreadcrumbsItem>
      { loader && 
        <Loader />
      }
      <LayoutOne headerTop="visible">
    

        <div className="login-register-area pt-40 pb-40 bg_gray2">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ml-auto mr-auto">
              <h1 className="login-register-heading">Forgot Password?</h1>
                <div className="login-register-wrapper login-register-bg">
                 


                
                      <div eventKey="login">
                        <div className="login-form-container">
                          <div className="login-register-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="valid_error_dv">
                                <div className="myform_dv">
                                  <label>Email</label>
                                  <div className="myform_icon">
                                    <input
                                      type="text"
                                      name="user-name"
                                      placeholder="Email"
                                      value={email}
                                      onChange={e => setEmail(e.target.value)}
                                      {...register("email", {
                                        required: true,
                                        value: email,
                                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        onChange: (e) => {
                                          const lowercaseEmail = e.target.value ? e.target.value.toLowerCase() : '';
                                          setEmail(lowercaseEmail)}
                                      })}
                                      className={errors.email ? 'myform_field valid_error_inpt' : 'myform_field'}
                                    />
                                     <img className="icon" src={process.env.PUBLIC_URL + '/assets/images/icon_email.svg'} alt=""/>
                                  </div>
                                </div>
                                {errors.email && <p className="valid_error_txt">Email is required</p>}
                              </div>

                              <div className="button-box button-box2">
                                <Link className="btn" to="/login">
                                  <span> Cancel</span>
                                </Link>
                                <button type="Submit" onClick={handleSubmitPassword}>
                                  <span>Submit</span>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>

      </LayoutOne>
    </Fragment>
  );
};

ForgetPassword.propTypes = {
  location: PropTypes.object
};


export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
