import {FETCH_AUCTIONS_SUCCESS} from "../actions/auctionActions";

const initState = {
  auctions: []
};

const auctionReducer = (state = initState, action) => {
  if (action.type === FETCH_AUCTIONS_SUCCESS) {
    return {
      ...state,
      auctions: action.payload
    };
  }

  return state;
};

export default auctionReducer;