import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import PaymentSuccessImage from "../../assets/assets/images/payment-success.jpg";
import { Link } from "react-router-dom";







const PaymentSuccess = (props) => {
  const [textToCopy, setTextToCopy] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setCopySuccess(false);
    }
  };

  useEffect(() => {
    if (props?.location?.state?.orderId) {
      setTextToCopy(props?.location?.state?.orderId);
    }
  }, [props?.location?.state?.orderId])



  return (
    <Fragment>

      <MetaTags>
        <title>Zourie | Payment Success</title>
        <meta name="description" />
      </MetaTags>

      <LayoutOne headerTop="visible">

        <div className="login-register-area">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center pt-100 pb-100 d-flex justify-content-center">
                <div className="payment-success-container">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/payment_success.jpg`} alt="" />
                  <h3>Thank You for Ordering</h3>
                  <p>Thank you so much for your order. we really appreciate
                    you supporting our small business!</p>
                  <p className="">
                    Order Number:<span># {textToCopy}</span>

                    <span
                      onClick={handleCopyClick}
                    ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_176_3238)">
                          <path d="M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z" stroke="#388087" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8" stroke="#388087" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_176_3238">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </p>
                  {copySuccess && <p>Text copied to clipboard!</p>}
                  <div className="payment-success-container-buttons">
                    <button className="view-order-btn"><Link to="/purchase-history">View Order</Link></button>
                    <button className="continue-shop-btn"><Link to="/">Continue Shopping</Link></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

PaymentSuccess.propTypes = {
  location: PropTypes.object
};


export default PaymentSuccess
