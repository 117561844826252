/* eslint-disable no-lone-blocks */
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

import MetaTags from "react-meta-tags";
import moment from "moment";
import axios from "axios"
import { useToasts } from "react-toast-notifications";
import LayoutOne from "../../layouts/LayoutOne";
import { Link } from "react-router-dom";
import { Subscription_CANCEL, Subscription_UPGRADE } from "../../../src/consts";
import { getSubsStatus } from "../../redux/actions/subscriptionplanActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const SubscriptionDetail = () => {
  const { addToast } = useToasts();
  const subscriptionplan = useSelector((state) => state?.subscriptionplans)
  const subscriptionplanpayment = useSelector((state) => state.subscriptionplans?.buyplan?.plan_status)


  const dispatch = useDispatch();
  const { id } = useParams()
  const token = localStorage.getItem("Token");
  const history = useHistory();
  const cancelSubscription = async () => {
    try {
      const response = await axios.post(Subscription_CANCEL, {}, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (response.status = 200) {
        addToast('Subscription canceled successfully', { appearance: "success", autoDismiss: true });
            history.push("/buy-subscription");
      } else {
        addToast('Current subscription may be already expired, cancelled, paused, or inactive', { appearance: "error", autoDismiss: true });

      }
    } catch (error) {
      addToast('Current subscription may be already expired, cancelled, paused, or inactive', { appearance: "error", autoDismiss: true });
   
       console.error('Error occurred while canceling subscription', error);
    }
  };
 
  useEffect(() => {
    dispatch(getSubsStatus());
  
   
  }, []);


  

  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Seller Charges And Fees</title>
        <meta
          name="description"
          content="seller subscription"
        />
      </MetaTags>
      <LayoutOne headerTop="visible">
        <section className="bg_gray2">
          <div className="container">
            <div className="row">
              {subscriptionplan?.buyplan ?
                <div className="col-12">
                  <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                    <h1 className="subscription-heading">Subscription Detail</h1>
                    <div className="subscription-wrapper login-register-bg">
                      <div className="subscription-wrapper-container">
                        <div className="subscription-wrapper-form">
                          <h3>Plan Name: {subscriptionplan?.buyplan?.plan?.title}</h3>
                          <div className="heading9">
                            <p>Purchased On: {moment(subscriptionplan?.buyplan?.created_at).format('DD-MM-YYYY ')}</p>

                            <p>Subscription Type: {subscriptionplan?.buyplan?.plan_type}</p>
                            <p>Price: ${subscriptionplan?.buyplan?.plan?.monthly_fees}</p>
                            <p className="buy_plan_title">Description: <span dangerouslySetInnerHTML={{ __html: subscriptionplan?.buyplan?.plan?.discription }} /></p>
                            {(moment().format('DD-MM-YYYY') == moment(subscriptionplan?.buyplan?.end_date).format('DD-MM-YYYY')) ?
                              <>
                                <p className="buy_plan_title" style={{
                                  color: 'red',
                                  fontWeight: 'bold'
                                }}> 
                                </p>
                                <div className="subcription-btns">
                                  <Link to="/buy-subscription"  className="upgrade_button">Upgrade Subscription</Link>
                                  <Link className="subsc_button" onClick={cancelSubscription}>Cancel Subscription</Link>
                                </div>
                              </> : (moment().format('YYYY-MM-DD  HH:mm:ss') > moment(subscriptionplan?.buyplan?.end_date).format('YYYY-MM-DD  HH:mm:ss')) ? <>
                                <p className="buy_plan_title" style={{
                                  color: 'red',
                                  fontWeight: 'bold'
                                }}>
                                </p>
                              </>
                                :
                                <p>Expire On: {moment(subscriptionplan?.buyplan?.end_date).format('DD-MM-YYYY HH:mm:ss')}</p>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> :
                <div className="col-12">
                  <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                    <h1 className="subscription-heading">Subscription Detail</h1>
                    <div className="subscription-wrapper login-register-bg">
                      <div className="subscription-wrapper-container">
                        <div className="subscription-wrapper-form">
                          <h3>No Subscription Found</h3>
                          <div className="heading9">
                            <p>You have not purchased any subscription yet.</p>
                            <Link to="/seller-charges-and-fees" className="subsc_button">Subscription</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
      </LayoutOne>
    </Fragment>
  );
};


export default SubscriptionDetail;