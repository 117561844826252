import React, { Fragment, useState } from "react";

import MetaTags from "react-meta-tags";

import LayoutOne from "../../layouts/LayoutOne";
import StatementImg from "../../assets/assets/images/statement.svg";

import ReactPaginate from 'react-paginate';
import { useEffect } from "react";
import axios from "axios";
import { fetchFinancialStatements } from "../../redux/actions/transactionActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GENERATE_CSV } from "../../consts";
import Loader from "./Loader";
import { SET_LOADING } from "../../redux/actions/subscriptionplanActions";
import { Pagination } from "@mui/material";


const Financial = () => {

  const dispatch = useDispatch();
  const statements = useSelector(store => store.transactionData?.financialStatements);
  const user = useSelector(store => store.userData?.user);
  const loader = useSelector(store => store.transactionData?.loading);

  const [currentItems, setCurrentItems] = useState(null);
  const itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);


  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(5);
    setPageCount(5);

  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    setItemOffset(5);
  };

  useEffect(() => {
    dispatch(fetchFinancialStatements());
  }, [])

  const handleCsvGenerate = (month, year, monthName) => {
    const token = localStorage.getItem('Token'); // Use the correct key for your token
    dispatch({
      type: SET_LOADING,
      payload: true
    });
    axios.get(GENERATE_CSV + `${user.id}/${year}/${month}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
      responseType: 'blob', // Set the responseType to 'blob' to handle binary data
    })
      .then((response) => {
        // Check if the request was successful
        if (response.status === 200) {
          // Get the content disposition header to determine the filename
          const contentDisposition = response.headers['content-disposition'];
          const filename = contentDisposition
            ? contentDisposition.split('filename=')[1]
            : 'download.csv';

          // Create a Blob object from the response data
          const blob = new Blob([response.data], { type: 'text/csv' });

          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element to trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.download = `${monthName}_${year}_financial_statement.csv`;

          // Trigger a click event on the link to start the download
          a.click();

          // Release the URL object
          window.URL.revokeObjectURL(url);
          dispatch({
            type: SET_LOADING,
            payload: false
          });
        } else {
          // Handle other response statuses as needed
          console.error('Failed to retrieve CSV data');
          dispatch({
            type: SET_LOADING,
            payload: false
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch({
          type: SET_LOADING,
          payload: false
        });
      });
  };
  const [finacialStatements, finacialSetStatements] = useState(statements?.results);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(statements?.num_pages);

  const handlePageChange = async (event, newPage) => {
    const pageNumber = newPage; 
    setCurrentPage(pageNumber)
   
  };
  

  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Seller Charges And Fees</title>
        <meta
          name="description"
          content="seller subscription"
        />
      </MetaTags>
      <LayoutOne headerTop="visible">
        {loader && <Loader />}
        <section className="bg_gray2">
          <div className="container">
            <div className="row">
              <h1 className="payout_title">Financial Statements</h1>
              <div className="financial_wrrpr">
             
                <p>These PDF files provide a summary of your selling activity, including orders, refunds,  payment disputes, listing fees etc.</p>
              </div>
            </div>
            <div className="row">
              <table className="payout_table">
                <tr>
                  <th>Statement Name</th>
                  <th>Date Range</th>
                  <th>Action</th>
                </tr>


                {finacialStatements?.map((statement, index) => (
                  <tr>
                    <td>{statement.month_name} {statement.year} Statement</td>
                    <td>
                      {moment(statement.start_date).format("MMMM DD, YYYY")} - {moment(statement.end_date).format("MMMM DD, YYYY")}
                      {/* February 1, 2023 - February 28, 2023 */}
                    </td>
                    <td>
                      <button className="view_btn" type="button"
                        onClick={
                          () => handleCsvGenerate(statement.month_number, statement.year, statement.month_name)
                        }>Generate CSV</button>
                    </td>
                  </tr>
                ))}
              </table>


            </div>


          </div>
        </section>
      </LayoutOne>
    </Fragment>
  );
};


export default Financial;