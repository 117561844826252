import { SET_LOADING } from "../actions/subscriptionplanActions";
import {FETCH_FINANCIAL_STATEMENT_SUCCESS, FETCH_PAYOUTS_SUCCESS, FETCH_TRANSACTION_SUCCESS} from "../actions/transactionActions";

const initState = {
    transactionDetails: [],
    financialStatements: [],
    payouts: [],
};

const transactionReducer = (state = initState, action) => {
  if (action.type === FETCH_TRANSACTION_SUCCESS) {
    return {
      ...state,
      transactionDetails: action.payload
    };
  }
  else if (action.type === FETCH_FINANCIAL_STATEMENT_SUCCESS) {
    return {
      ...state,
      financialStatements: action.payload
    };
  }
  else if (action.type === SET_LOADING  ) {
    return {
      ...state,
      loading: action.payload
    };
  }
  else if (action.type === FETCH_PAYOUTS_SUCCESS) {
    return {
      ...state,
      payouts: action.payload
    };
  }

  return state;
};

export default transactionReducer;