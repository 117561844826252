import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  FormControl,
  Form,
} from "react-bootstrap";

const MobileSearch = () => {

  const [formState, setFormState] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  return (
    <div className="offcanvas-mobile-search-area">
   

        <Form onSubmit={handleSubmit}>
          <FormControl type="search" 
            placeholder="Enter your keyword...." 
            aria-label="Enter your keyword...."
            name="query"  
            onChange={e => {
              setFormState({ ...formState, name: e.target.value });
            }}
          />
            <Link to={'/search-result?query='+formState['name']}>
          <i className="fa fa-search" />
            </Link>
        </Form>
    </div>
  );
};

export default MobileSearch;
