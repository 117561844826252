import { SAVE_PRODUCT_IMAGE } from "../../consts";
import { SAVED_PRODUCT_SUCCESS } from "../actions/productActions";

const initState = {
  product: [],
  productImage: []
};



export const saveProductReducer = (state = initState, action) => {
  if (action.type === SAVED_PRODUCT_SUCCESS) {
    return {
      ...state,
      product: action.payload
    };
  } else if (action.type === SAVE_PRODUCT_IMAGE) {
    return {
      ...state,
      productImage: action.payload
    }
  }
  return state;
}
