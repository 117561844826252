import { SALES_HISTORY } from '../../consts';
import { fetchVerifiedAccount } from '../actions/Login-Register';
import { FETCH_MY_ORDERS, FETCH_MY_PRODUCTS } from '../actions/dashboardActions';
import { FETCH_ALL_MESSAGSES_SUCCESS } from '../actions/getMessagesActions';
import { PRODUCT_SEARCH_RESULTS } from '../actions/productActions';
import { BUY_SUBPLAN_SUCCESS, CLEAR_SUBSPLAN } from '../actions/subscriptionplanActions';
import { FETCH_PAYOUTS_SUCCESS } from '../actions/transactionActions';
import * as types from '../types/usertypes';

const initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
    isLoading: false,
    error: false,
    address: null,
    addressList: []
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_USER:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case types.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.payload
            };
        case types.LOGIN_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                error: action.payload
            };
        case types.REGISTER_USER:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case types.REGISTER_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.payload
            };
        case types.REGISTER_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                error: action.payload
            };

        case types.LOGOUT_USER:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                user: null,
                token: null
            };

        case types.USER_PROFILE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.payload
            };

        case types.EDIT_USER:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.payload
            };

        case types.USER_ADDRESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                address: action.payload
            };

        case types.SAVE_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                bankDetails: action.payload
            };

        case types.CHANGE_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                userEmailChange: action.payload
            };

        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                userPasswordChange: action.payload
            };

        case types.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                userForgotPasswordReset: action.payload
            };

        case types.START_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case types.STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };


        case types.VERIFY_STRIPE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                verifyAccount: action.payload
            };

        case types.VERIFY_STRIPE_ACCOUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                verifyAccount: action.payload
            };

        case types.SAVE_ADDRESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                addressList: action.payload
            };


        default:
            return state;
    }
}


export const UserLogout = (history) => {
    localStorage.clear('token');
    return dispatch => {
        dispatch({
            type: types.LOGOUT_USER,
            payload: initialState
        });
        dispatch({
            type: CLEAR_SUBSPLAN,
        })
        dispatch({
            type: FETCH_PAYOUTS_SUCCESS,
            payload: []
        })
        dispatch({
            type: FETCH_MY_ORDERS,
            payload: []
        })
        dispatch({
            type: SALES_HISTORY,
            payload: []
        })
        dispatch({
            type: FETCH_MY_PRODUCTS,
            payload: []
        })
        dispatch({
            type: PRODUCT_SEARCH_RESULTS,
            payload: {
                data: null,
                total: null
            }
        });
        dispatch({
            type: FETCH_ALL_MESSAGSES_SUCCESS,
            payload: []
        })

        dispatch({
            type: types.VERIFY_STRIPE_ACCOUNT_SUCCESS,
            payload: null
        });



        
    };
}




export default UserReducer;
