import React from 'react';
import VendorAddListingPro from '../AddListingPro';
import NotFound from '../../other/NotFound';
import moment from 'moment';
import EditListingPro from '../EditListingPro';

const ConditionalRouteRenderer = ({ subscriptionplans, add }) => {
  const isProListing = subscriptionplans?.buyplan?.pro_listing;

  if (
    isProListing == true &&
    (moment(subscriptionplans?.buyplan?.end_date))
  ) {
    return add ? <VendorAddListingPro /> : <EditListingPro />
  } else {
    return <NotFound />;
  }
};

export default ConditionalRouteRenderer;
