import React, { Fragment, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import LayoutOne from '../../layouts/LayoutOne'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPayoutTransactions } from '../../redux/actions/transactionActions'
import moment from 'moment'
import Loader from './Loader'

const TransactionDetail = (props) => {

  const transactionDetails = useSelector((state) => state?.transactionData?.transactionDetails)
  const loader = useSelector(store => store.transactionData?.loading);
  const dispatch = useDispatch();
  const id = props.history.location?.state?.id

  useEffect(() => {
    if (id) {
      dispatch(fetchPayoutTransactions(id))
    }
  }, [])


  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Seller Charges And Fees</title>
        <meta
          name="description"
          content="seller subscription"
        />
      </MetaTags>
      <LayoutOne headerTop="visible">
        {loader && <Loader />}

        <section className="bg_gray2">
          <div className="container">
            <h1 className="payout_title">Transaction Details</h1>
            <div className="financial_wrrpr">
              <p>Deducted fees are charged by Zourie.</p>
            </div>
            <div className='generate-csv-header'>
              <div>
                <h4>Payout Transaction: <span>(Date Range: {props.history.location?.state?.startDate} - {props.history.location?.state?.endDate})</span></h4>
              </div>
              <div>
                <button type="button" className='generate-csv-btn'>Generate CSV</button>
              </div>
            </div>
            <div className="row">
              <div className='col-md-12'>
                <div className='responsive-table'>
                  <table className="payout_table transaction_table">

                    <tr>
                      <th>Date</th>
                      <th>Order ID</th>
                      <th>Details</th>
                      <th>Type</th>
                      <th>Seller Fees</th>
                      <th>Amount</th>
                      <th>Total (USD)</th>
                    </tr>


                    {transactionDetails?.length == 0 ? (
                      <tr>
                        <td colSpan="7" style={{ textAlign: 'center', padding: '100px 0' }}>No Transaction Found</td>
                      </tr>
                    ) : (
                      <>

                        {transactionDetails?.payout_detail?.map((item, index) => (
                          <>
                            {item?.type === "Product Sold" ? (
                              <>
                                <tr className='merge-table-row'>
                                  <td>{moment(item?.order_item?.created_at).format("MMM DD")}</td>
                                  <td>{item?.order_item?.order_id?.id}</td>
                                  <td className='transaction_detail_title'>
                                    <strong>{item?.order_item?.product?.product_title} <br></br>({item?.order_item?.quantity} item)</strong>
                                  </td>
                                  <td>
                                    <strong>{item?.order_item?.product?.product_type}</strong>
                                    Processed by {item?.order_item?.order_id?.ordered_by}
                                  </td>
                                  <td></td>
                                  <td>${item?.order_item?.product?.discounted_price}</td>
                                  <td></td>

                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <strong>Fees</strong>
                                    Commission fee <br></br> Listing fees <br></br> {item?.order_item?.product?.marketing_option_per === null ? '-' : 'Marketing fees'}<br></br> Transaction Payouts
                                  </td>
                                  <td>
                                    <strong></strong>
                                    Fixed fee 9.9% <br />
                                    Fixed fee {item?.order_item?.product?.listing_fee} <br />
                                    {item?.order_item?.product?.marketing_option_per === null ? '-' : <>Seller choose {item?.order_item?.product?.marketing_option_per}</>}
                                  </td>
                               
                                  <td><strong></strong>$({((item?.order_item?.product?.discounted_price) * (9.9 / 100)).toFixed(2)})<br />?  <br />{item?.order_item?.product?.marketing_option_per === null ? '-' : <>{item?.order_item?.product?.marketing_option_per}</>}</td>
                                  <td>${parseFloat(item?.unit_price)}</td>
                                </tr>


                              </>
                            ) : (
                              <>
                                {item?.type === "Product Added" ? (<>
                                  <tr key={index}>
                                    <td>{moment(item?.created_at).format("MMM DD")}</td>
                                    <td>-</td>
                                    <td className='transaction_detail_title'><strong>{item?.product?.product_title}</strong></td>
                                    <td><strong>Fees</strong>Listing Fee</td>
                                    <td>Fixed fee ${0.19}</td>
                                    <td>${0.19}</td>
                                    <td>${0.19}</td>
                                  </tr>
                                </>
                                ) : (
                                  <>
                                    {item?.subscription != null ? (<>
                                      {item?.type === "Subscription" ? (<>
                                        <tr key={index}>
                                          <td>{moment(item?.subscription?.created_at).format("MMM DD")}</td>
                                          <td>-</td>
                                          <td className='transaction_detail_title'>-</td>

                                          <td><strong>Fees</strong>Subscription Fee</td>
                                          <td>-</td>
                                          <td>${item?.subscription?.price}</td>
                                          <td>-</td>
                                        </tr>
                                      </>
                                      ) : ('')}
                                    </>) : (
                                      <>
                                        {item?.type === "Product Refunded" ? (<>
                                          <tr key={index}>
                                            <td>{moment(item?.created_at).format("MMM DD")}</td>
                                            <td>-</td>
                                            <td className='transaction_detail_title'><strong>{item?.product?.product_title}</strong></td>
                                            <td><strong>Fees</strong>Refund Fee</td>
                                            <td>Fixed fee ${0.19}</td>
                                            <td>${0.19}</td>
                                            <td>${0.19}</td>
                                          </tr>

                                        </>) : ('')}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )
                            }
                           
                          </>

                        ))}


                      </>
                    )}



                    

                  </table>
                </div>
              </div>

              <div className='col-md-12'>
                <div className='transaction_table_footer'><h4>Total Payouts: ${transactionDetails?.total_payout_amount?.toFixed(2)}</h4></div>
              </div>
            </div>



          </div>
        </section>
      </LayoutOne>
    </Fragment>
  )
}

export default TransactionDetail