// export const API_URL = 'http://127.0.0.1:8000/';     
 // export const API_URL = 'http://192.168.0.43:8000/';
  export const API_URL = 'https://zourie1.zourie.com/';       
//  export const API_URL = 'https://zourie-staging.herokuapp.com/';


export const GET_BANNERS = API_URL + 'apiadmin/products/banner/';
export const TESTIMONIALS = API_URL + 'apiadmin/products/testimonial/';
export const PAGE = API_URL + 'products/page/';
export const BLOGS = API_URL + 'apiadmin/products/blog/';
export const GET_PRODUCTS = API_URL + 'apiadmin/products/product/';
export const GET_VENDOR_PRODUCTS = API_URL + 'apiadmin/products/product/?added_by=';
export const SAVE_PRODUCT_IMAGE = API_URL + 'products/v2/';
export const UPDATE_PRODUCT_IMAGE = API_URL + 'products/product-image/';
export const FEEDBACK_PRODUCT = API_URL + 'products/v1/';
export const AUCTION_PRODUCTS = API_URL + 'apiadmin/products/product/';
export const TODAY_TOP_PRODUCTS = API_URL + 'products/top-products/';
export const CATEGORIES = API_URL + 'products/category/';
export const NEWSLETTER_SUBMISSION = API_URL + 'apiadmin/products/newsletter/';
export const REPORT_VENDOR = API_URL + 'apiadmin/products/report/';
export const NEW_ARRIVALS_PRODUCTS = API_URL + 'products/admin-selected/?product_type=new_arrivals';
export const ALL_VENDORS = API_URL + 'products/get-all-vendors/';
export const BEST_SELLERS_PRODUCTS = API_URL + 'products/admin-selected/?product_type=features_best_sellers';

export const USER_REGISTER = API_URL + 'accounts/signup/';
export const USER_LOGIN = API_URL + 'accounts/login/';
export const USER_PROFILE = API_URL + 'accounts/user-detail/';
export const VERIFY_TOKEN = API_URL + 'accounts/forget-password-confirm/';
export const NEW_PASSWORD = API_URL + 'accounts/confirm-password/';
export const PASSWORD_RESET = API_URL + 'accounts/forget-password/';
export const CHANGE_EMAIL_REQ = API_URL + 'accounts/change-email/';
export const VERIFY_EMAIL_TOKEN = API_URL + 'accounts/verify-email/';
export const CHANGE_PASSWORD_REQ = API_URL + 'accounts/change-password/';
export const VERIFY_ACCOUNT_STRIPE = API_URL + 'accounts/verify-account/'
export const GET_VENDOR_DETAILS = API_URL + 'accounts/get-vendor-detail/'
// export const PASSWORD_RESET_CONFIRM = API_URL + 'accounts/dj-rest-auth/password/reset/confirm/';
// export const GET_USER_DETAILS = API_URL + 'accounts/user-detail/';

export const GET_MY_PRODUCTS = API_URL + 'products/my-products/'; 
export const GET_MY_FAVOURITE_PRODUCTS = API_URL + 'apiadmin/products/favourites/';
export const EDIT_USER = API_URL + 'accounts/edit-profile/';
export const ADD_PROFILE_IMAGE = API_URL + 'accounts/profile-image/';
export const ADD_ADDRESS = API_URL + 'accounts/v1/';
export const ADD_FAVOURITE_PRODUCT = API_URL + 'apiadmin/products/favourites/';
export const CREATE_STATUS_FOR_PRODUCT = API_URL + 'apiadmin/products/productstatus/';
export const SAVE_PRODUCT_ORDER = API_URL + 'shopping/order/';
export const PAYMENT_GATEWAY = API_URL + 'shopping/payment/';
export const PRODUCT_PAYMENT_GATEWAY = API_URL + 'shopping/product-payment/';
export const GET_MY_STATS = API_URL + 'shopping/dashboard/';
export const GET_PAGES = API_URL + 'products/page/';
export const GET_FAQS = API_URL + 'apiadmin/products/faq/';
export const CONTACT_US = API_URL + 'products/contact-us/';
export const SALES_HISTORY = API_URL + 'shopping/sales-history/';
export const GET_MY_ORDERS = API_URL + 'shopping/vendor-orders/'
export const UPDATE_ORDER_STATUS = API_URL + 'shopping/update-status/';
export const GET_FOOTER_CONTENT = API_URL + 'apiadmin/products/setting/';
export const ORDER_CANCEL_ITEM = API_URL + 'shopping/order-item-cancelled/';
export const RETURN_ORDER_ITEM = API_URL + 'shopping/order-return/';
export const SAVE_BANK_DETAILS = API_URL + 'accounts/user/account-detail/';
export const GET_TOTAL_SALES = API_URL + 'shopping/vendor-total-sales/';
export const GET_CANCEL_RETURNS_ORDERS = API_URL + 'shopping/order-items-cancel-and-return/';
export const GET_MARKETING_LISTINGS = API_URL + 'products/marketing-listing-products/';

export const GET_PURCHASE_HISTORY = API_URL + 'shopping/order-for-user/';
export const GET_FOLLOWERS = API_URL + 'followers/my-follow/';
export const GET_REVIEWS = API_URL + 'products/my-reviews/';
export const POST_FOLLOWING_REQUEST = API_URL + 'followers/follow-vendor/';
export const GET_JOBS = API_URL + 'apiadmin/job/jobs/';
// export const CONTACT_SELLER = API_URL + 'seller_question/contact-seller/';
export const GET_ALL_QUESTIONS = API_URL + 'seller_question/questions/';
export const POST_ANSWER = API_URL + 'seller_question/answers/';
export const POST_UNFOLLOWING_REQUEST = API_URL + 'followers/unfollow/';
export const CONTACT_SELLER = API_URL + 'contact_seller/contactseller_message/';
export const GET_ALL_MESSAGES = API_URL + 'contact_seller/specific_seller_message/';
export const MESSAGE_REPLY = API_URL + 'contact_seller/contact_message_reply/';

export const GET_SUBSCRIPTION_PLANS = API_URL + 'subscription/subscription-plan/'
export const GET_SUBSCRIPTION_PLAN_STATUS = API_URL + 'subscription/subscription-status/'
export const BUY_SUBSCRIPTION_PLAN = API_URL + 'subscription/buy-subscription/'

export const TRANSACTION_DETAILS = API_URL + 'shopping/vendor-payout-details/'
export const UPLOAD_TO_GCP = API_URL + 'products/upload-to-gcp/'

//  api Cart
export const BUY_NOW_PRODUCT = API_URL + 'shopping/buy-now/'
export const DECREMENT_CART_QUANTITY = API_URL + 'shopping/decrement-cart/'
export const ADD_TO_CART = API_URL + 'shopping/add-to-cart/';
export const INCREMENTED_CART_QUANTITY = API_URL + 'shopping/update-cart-item/'

export const FINANCIAL_STATEMENTS_API = API_URL + 'shopping/financial-circle-periods/'
export const GENERATE_CSV = API_URL + 'shopping/finacial-statements/generate-csv/'
export const PAYOUTS_API = API_URL + 'shopping/payouts/'
export const FILTER_PAYOUTS_API = API_URL + 'shopping/payouts/filter/'
export const UPDATE_TRACKING_NUMBER = API_URL + 'shopping/update-tracking-number-order/'
export const ORDER_DETAIL = API_URL + 'shopping/order-detail/'
export const UPDATE_ORDER_STATUS_BY_VENDOR = API_URL + 'shopping/update-status/'

export const IMAGEEDIT = API_URL + 'products/v2/'
// subscription
export const Subscription_CANCEL = API_URL + 'subscription/cancel/'
export const Subscription_UPGRADE = API_URL + 'subscription/upgrade/'
export const Recently_SEARCH = API_URL + 'products/add-recently-searched/'
export const SEARCH_GETTING = API_URL + 'products/recently-searched/'


