import {REPORT_VENDOR_SUCCESS} from "../../redux/actions/reportVendorActions";

const initState = {
    reportVendor: {},
};


export const reportVendorReducer = (state = initState, action) => {
    if (action.type === REPORT_VENDOR_SUCCESS) {
      return {
        ...state,
        reports: action.payload
      };
    }
    return state;
};