import {FETCH_JOBS_SUCCESS} from "../actions/jobActions";

const initState = {
  jobsList: []
};

const jobReducer = (state = initState, action) => {
  if (action.type === FETCH_JOBS_SUCCESS) {
    return {
      ...state,
      jobsList: action.payload
    };
  }

  return state;
};

export default jobReducer;