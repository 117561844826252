import axios from "axios";
import { ALL_VENDORS, GET_FOOTER_CONTENT } from "../../consts";

export const FETCH_FOOTERCONTENT_SUCCESS = "FETCH_FOOTERCONTENT_SUCCESS";
export const FETCH_ALL_VENDORS = "FETCH_ALL_VENDORS";


const fetchFooterContentSuccess = footerData => ({
    type: FETCH_FOOTERCONTENT_SUCCESS,
    payload: footerData
});

const fetchAllVendors = vendors => ({
    type: FETCH_ALL_VENDORS,
    payload: vendors
});

    
export const fetchFooterData = footerData => {
    return dispatch => {
        axios.get(GET_FOOTER_CONTENT)
        .then(response => {
            dispatch(fetchFooterContentSuccess(response.data));
        })
        .catch(error => {
            console.log(error);
        })
    };

};

export const fetchVendors = () => {
    return dispatch => {
        axios.get(ALL_VENDORS)
        .then(response => {
            dispatch(fetchAllVendors(response.data));
        })
        .catch(error => {
            console.log(error);
        })
    };

}