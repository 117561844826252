import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import {  orderDetails } from "../../../redux/actions/orderActions";
import Loader from "../../other/Loader";
import { useHistory } from 'react-router-dom';
import { fetchCancelAndReturnOrders, fetchMyOrders } from "../../../redux/actions/dashboardActions";
import Pagination from '@mui/material/Pagination';




const VendorOrders = (props) => {
  const url = window.location.pathname.split('/');
  const dataUrl = url[1];

  const history = useHistory();
  
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  const { addToast } = useToasts();
  const dispatch = useDispatch();


  useEffect(() => {
    if (props?.vendorOrdersData) {
      const endOffset = itemOffset + itemsPerPage;
      if (dataUrl === 'return-and-cancellations') {
        setCurrentItems(props.vendorOrdersData.order_details && props.vendorOrdersData.order_details.filter(v => (v.item_status === "Cancelled" || v.item_status === "Returned")).slice(itemOffset, endOffset));
        setPageCount(Math.ceil(props.vendorOrdersData.order_details && props.vendorOrdersData.order_details.filter(v => (v.item_status === "Cancelled" || v.item_status === "Returned")).length / itemsPerPage));
      } else if (dataUrl === 'vendor') {
        setCurrentItems(props.vendorOrdersData && props.vendorOrdersData?.slice(0, 5))
      } else {
        setCurrentItems(props?.vendorOrdersData?.results?.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(props?.vendorOrdersData?.results?.length / itemsPerPage));
      }
    }
  }, [dataUrl, itemOffset, itemsPerPage, props.vendorOrdersData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % props?.vendorOrdersData?.order_details?.length;
    setItemOffset(newOffset);
  };

  const [loading, setLoading] = useState(false);


  const handleOrderDetails = (id) => {
    dispatch(orderDetails(
      id,
      setLoading
    ))
  }

  const handleChangePage = (event, newPage) => {
    if (props.vendorOrdersData.title === "Cancelled & Returned Orders" || dataUrl === 'return-and-cancellations') {
      fetchCancelAndReturnOrders(setLoading, newPage);
    }
    
    dispatch(fetchMyOrders(setLoading, newPage));
    setPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };


  return (
    <>
      <div className="das_box4">
        <div className="das_box4_hd">
          <h3>{dataUrl === 'return-and-cancellations' ? "Return and Cancellations Orders" : "Vendor Orders"}</h3>
        </div>
        {loading && <Loader />}
        <table
          className="table table-striped das_box_tbl das_box_custom"
        >
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>

                ID's

              </th>
              <th>Product Detail</th>
              <th>

                Cost
              </th>
              <th>Order Status</th>
            </tr>
          </thead>
          {props?.vendorOrdersData?.title === "Cancelled & Returned Orders" ? (
            <>
              <tbody>
                {props?.vendorOrdersData?.results?.length > 0 ? (
                    props?.vendorOrdersData?.results?.map((order, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          history.push('/order-details-seller')
                          handleOrderDetails(order?.order_id?.id)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <td className="product_id">
                          <h5>Shipment ID: <span>#{order && order?.id}</span></h5>
                          <h5>Order ID: <span>#{order?.order_id?.id}</span></h5>
                        </td>
                        <td className="product_detail">
                          <img src={order?.product_image} alt='' />
                          <div>
                            <h4>{order?.product?.product_title}</h4>
                            <h3>Price: ${order?.unit_price}</h3>
                            <h3>Qty: {order?.quantity}</h3>
                          </div>
                        </td>
                        <td className="product_cost">
                          <h5>Shipping Cost: <span>${order?.shipping_price}</span></h5>
                          <h5>Total Cost: <span>${order?.sub_total}</span></h5>
                        </td>
                        <td>
                          {order?.item_status}

                        </td>
                      </tr>
                    ))
                 ) :
                  <tr>
                    <td colSpan="7" className="no_data_table">no data!</td>
                  </tr>
                }
              </tbody>

            </>
          ) :
            <tbody>
              {currentItems !== null && currentItems?.length > 0 ?
                currentItems?.map((order, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      history.push('/order-details-seller')
                      handleOrderDetails(order.order_id.id)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <td className="product_id">
                      <h5>Shipment ID: <span>#{order && order.id}</span></h5>
                      <h5>Order ID: <span>#{order.order_id.id}</span></h5>
                    </td>
                    <td className="product_detail">
                      <img src={order.product.product_image[0].upload_image} alt='' />
                      <div>
                        <h4>{order.product.product_title}</h4>
                        <h3>Price: ${order.unit_price}</h3>
                        <h3>Qty: {order.quantity}</h3>
                      </div>
                    </td>
                    <td className="product_cost">
                      <h5>Shipping Cost: <span>${order.shipping_price}</span></h5>
                      <h5>Total Cost: <span>${order.sub_total}</span></h5>
                    </td>
                    <td>
                      {order.item_status}

                    </td>
                  </tr>
                )) : <tr><td colSpan="7" className="no_data_table">no data!</td></tr>
              }
            </tbody>
          }
        </table>


        {(dataUrl === 'vendor-orders' || dataUrl === "return-and-cancellations") &&
          <div className="d-flex justify-content-center">
            <Pagination
              count={props?.vendorOrdersData.num_pages}
              className='custom-pagination-ul'
              color='primary'
              size="large"
              variant="outlined"
              onChange={(event, newPage) => handleChangePage(event, newPage)}
            />
          </div>
        }
      </div>
    </>
  );
};

export default VendorOrders;
