import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import { connect, useDispatch, useSelector } from "react-redux";
import { UserLogout } from "../../redux/reducers/Login-Register";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoneyBills } from "@fortawesome/free-solid-svg-icons";
import { getSubsStatus } from "../../redux/actions/subscriptionplanActions";

const ShopSidebar = (props) => {


  const { sbactive } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const subscription = useSelector(state => state?.subscriptionplans?.buyplan)

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(UserLogout(history));
    history.push('/');

  }
  const subscriptionplanpayment = useSelector((state) => state?.subscriptionplans?.buyplan?.is_sub_really_active)

  const id = props.user.id;

  const [sidebar_trigger, setSidebar_trigger] = useState(false)

  useEffect(() => {
  }, [])


  return (
    <>




      <div className="sidebar_trigger" onClick={() => { setSidebar_trigger(true) }}><i className="fa fa-bars" aria-hidden="true"></i></div>


      <div className={`sidebar_wrrpr ${sidebar_trigger === true ? 'at_act' : ''}`}>

        <div className="sidebar_close" onClick={() => { setSidebar_trigger(false) }}></div>

        <Link to="/vendor" className='sidebar_head'>
          <FontAwesomeIcon icon={faBars} />
          <h3>Menu</h3>
        </Link>

        <div className='sidebar_widget' style={{ padding: '0' }}>
          <Accordion>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive={sbactive === 'Dashboard' ? 'true' : 'false'}>
                <Link to="/vendor" className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/dashboard_icon.svg'} alt='Dashboard' />Dashboard</Link>
              </div>
            </div>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive={sbactive === 'MyAccount' ? 'true' : 'false'}>
                <Link to="/vendor-account" className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/account_settings_icon.svg'} alt='Account Settings' />Account Settings</Link>
              </div>
            </div>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive='false'>
                <Link to={`/vendor/${id}`} className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/seller_storefront_icon.svg'} alt='Seller Storefront' />Seller Storefront</Link>
              </div>
            </div>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive={sbactive === 'AllMessages' ? 'true' : 'false'}>
                <Link to="/all-messages" className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/message.svg'} alt='Messages' />Messages</Link>
              </div>
            </div>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive={sbactive === 'Followers' ? 'true' : 'false'}>
                <Link to="followers" className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/followers_icon.svg'} alt='Followers' />Followers</Link>
              </div>
            </div>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive={sbactive === 'Review' ? 'true' : 'false'}>
                <Link to="review" className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/reviews_icon.svg'} alt='Reviews' />Reviews</Link>
              </div>
            </div>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive='false'>
                <Link to="/help-center" className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/help_center_icon.svg'} alt='Help Center' />Help Center</Link>
              </div>
            </div>

            <div className="accordion__item">
              <div className="accordion__heading" sbactive='false'>
                <Link to="/seller-community" className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/zourie_seller_community_icon.svg'} alt='Zourie Seller Community' />Zourie Seller Community</Link>
              </div>
            </div>

            {subscriptionplanpayment === false ? (
              <div className="accordion__item">
                <div className="accordion__heading" sbactive='false' onClick={() =>  getSubsStatus()} >
                  <Link to="/buy-subscription" className="accordion__button no_before">
                    <FontAwesomeIcon icon={faMoneyBills} />
                    Subscription
                  </Link>
                </div>
              </div>
            ) : (
              <div className="accordion__item">
                <div className="accordion__heading" sbactive='false' onClick={() =>  getSubsStatus() }>
                  <Link to="/subscription-detail" className="accordion__button no_before">
                    <FontAwesomeIcon icon={faMoneyBills} />
                    Subscription
                  </Link>
                </div>
              </div>
            )}

            {subscription != 'undefined' && subscription != null &&
              <div className="accordion__item">
                <div className="accordion__heading" sbactive='false'>
                  <Link to="/payouts" className="accordion__button no_before"><FontAwesomeIcon icon={faMoneyBills} />Payouts</Link>
                </div>
              </div>
            }


            <form className="accordion__item" onClick={onSubmit}>
              <div className="accordion__heading" sbactive="false">
                <button className="accordion__button no_before"><img src={process.env.PUBLIC_URL + '/assets/images/log_out_icon.svg'} alt='Log Out' type="submit" />Log Out</button>
              </div>
            </form>


          </Accordion>
        </div>

      </div>



    </>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userData.user
  }
};

export default connect(mapStateToProps)(ShopSidebar);
