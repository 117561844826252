import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import { connect, useDispatch } from 'react-redux';
import Sidebar from "../vendor/Sidebar";
import { Link, useHistory } from "react-router-dom";
import NoUser from "../../assets/assets/images/no_user.jpg";
import { sendMessageReply } from "../../redux/apicalls";
import { useToasts } from "react-toast-notifications";

const MessageDetailPage = ({ messagesData, ...props })=> {

  let history = useHistory();
  let dispatch  = useDispatch();  
  const { addToast } = useToasts();
  const [messgData,setMessgData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [replyMssg, setReplyMssg] = useState("");
  
  useEffect(() => {
    const filteredData = messagesData.filter((f) => f.id === props?.location.state.data.id);
    setMessgData(filteredData[0]);
  }, [messagesData]);

  const handleSubmit = (e,id) =>{
    e.preventDefault();
    setSubmitting(true);
    const data = {"mess_id": id,"message" : replyMssg}
    try{
      dispatch(sendMessageReply(data,addToast));
    }finally {
      setSubmitting(false);
      setReplyMssg("")
    }
  }

  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Messages</title>
        <meta
          name="description"
          content="Messages page of Zourie react minimalist eCommerce template."
        />
      </MetaTags>

      <LayoutOne headerTop="visible">
        <section className="cart-main-area pb-100">
          <div className="container">
            <div className="row">
              <div className="col-3">
                <Sidebar />
              </div>
              <div className="col-9">
                <div className="mssg_top">
                  <Link onClick={()=>history.goBack()}>
                    <span><img className="icon" src={process.env.PUBLIC_URL + '/assets/images/Back.png'} alt=''/>Back</span>
                  </Link>
                </div>
                <div className="mssg_body">
                  <div className="heading">
                    <h2>Messages</h2>
                  </div>

                  {messgData?.all_replies.length > 0 && messgData.all_replies.map((item) => 
                  <div className="mssg_body_wrapper">
                    <div className="icon"><img className="icon" src={NoUser} alt=''/></div>
                      <div className="detail_wrapper">
                        <div className="user_detail">
                          <span className="txt">{item.username}</span>
                          <span>{item.created_at}</span>
                        </div>
                        <div className="user_mssg">
                          <span>{item.message}</span>
                        </div>
                      </div>
                  </div>
                  )}
                  <form className="myform_dv" onSubmit={(e)=>handleSubmit(e,messgData.id)}>
                    <textarea rows="5" className="mssg_textarea" placeholder="Enter Your Message Here" required  value={replyMssg} onChange={(e)=> setReplyMssg(e.target.value)}></textarea>
                    <button type="submit" disabled={submitting} className="btn1 myform_btn">{submitting ? "Sending" : "Send"}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutOne>
    </Fragment>
  );
};

MessageDetailPage.propTypes = {
  messagesData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  messagesData: state.messageData?.messagesData,
})

export default connect(mapStateToProps)(MessageDetailPage);