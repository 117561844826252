import axios from 'axios';
import { GET_FOLLOWERS } from '../../consts';

export const FETCH_FOLLOWERS_SUCCESS = "FETCH_FOLLOWERS_SUCCESS";


const fetchFollowersSuccess = followersList => ({
  type: FETCH_FOLLOWERS_SUCCESS,
  payload: followersList
});


export const fetchFollowers = () => {
  return dispatch => {
    const token = localStorage.getItem("Token");
    axios.get(GET_FOLLOWERS, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(response => {
      dispatch(fetchFollowersSuccess(response.data),
      );
    })
    .catch(error => {
      console.log(error);
    });
  };
};