import axios from 'axios';
import { TODAY_TOP_PRODUCTS } from "../../consts";

export const FETCH_TODAY_TOP_PICK = "FETCH_TODAY_TOP_PICK";


const todayTopPickSuccess = top_pick_products => ({
    type: FETCH_TODAY_TOP_PICK,
    payload: top_pick_products
  });


export const fetchTodayTopPick = top_pick_products => {
    return dispatch => {
      axios.get(TODAY_TOP_PRODUCTS)
      .then(response => {
      dispatch(todayTopPickSuccess(response.data));
    })
    .catch(error => {
      console.log(error);
    });
    };
  };