import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import PrivacySettings from "../../pages/other/PrivacySettings";
import { connect } from "react-redux";

const FooterOne = (footerData) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };




  return (
    <>

      <footer className='footer_wrrpr'>







        <Container>
          <Row>
            <Col xl={4} lg={4} md={12} sm={12} xs={12} className='footer1'>
              <div className='footerdv'>
                <Link to="" className='footer_logo'>
                  <img src={process.env.PUBLIC_URL + '/assets/images/ZourieLogoFooterPng.png'} alt="Zourie Logo" />
                </Link>




                <p className='fdv_txt'>{footerData.footerData[0] && footerData.footerData[0].content}</p>
            
              </div>
            </Col>
            <Col xl={8} lg={8} md={12} sm={12} xs={12}>
              <Row>

                <Col xl={4} lg={4} md={4} sm={4} xs={12} className='footer2'>
                  <div className='footerdv'>
                    <h3 className='fdv_txt'>About us</h3>
                    <ul className='footer_menu'>
                      <li><Link to="/career">Careers</Link></li>
                      <li><Link to="#">Our Stores</Link></li>
                      <li><Link to="#">Our Cares</Link></li>
                      <li><Link to="/page/terms-conditions">Terms & Conditions</Link></li>
                      <li><Link to="/page/privacy-policy">Privacy Policy</Link></li>
                    </ul>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={12} className='footer3'>
                  <div className='footerdv'>
                    <h3 className='fdv_txt'>Customer Care</h3>
                    <ul className='footer_menu'>
                      <li><Link to="/help-center">Help Center</Link></li>
                      <li><Link to="#">How to Buy</Link></li>
                      <li><Link to="#">Track Your Order</Link></li>
                      <li><Link to="#">Corporate & Bulk Purchasing</Link></li>
                      <li><Link to="/page/returns-refunds">Returns & Refunds</Link></li>
                    </ul>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={12} className='footer4'>
                  <div className='footerdv'>
                    <h3 className='fdv_txt'>Contact Us</h3>
                    <p className='fdv_txt'>{footerData.footerData[0] && footerData.footerData[0].contact_address} <br /> </p>
                    <p className='fdv_txt' >{footerData.footerData[0] && footerData.footerData[0].email}</p>
                    <p className='fdv_txt' >{footerData.footerData[0] && footerData.footerData[0].phone}</p>
                    <ul className='social_icon'>
                      <li><Link to={{ pathname: footerData.footerData[0] && footerData.footerData[0].facebook }} target="_blank"><img src={process.env.PUBLIC_URL + '/assets/images/facebook.png'} alt="Facebook" /></Link></li>
                      <li><Link to={{ pathname: footerData.footerData[0] && footerData.footerData[0].twitter }} target="_blank"><img src={process.env.PUBLIC_URL + '/assets/images/twitter.png'} alt="Twitter" /></Link></li>
                      <li><Link to={{ pathname: footerData.footerData[0] && footerData.footerData[0].instagram }} target="_blank"><img src={process.env.PUBLIC_URL + '/assets/images/instagram.png'} alt="Instagram" /></Link></li>
                      <li><Link to={{ pathname: footerData.footerData[0] && footerData.footerData[0].youtube }} target="_blank"><img src={process.env.PUBLIC_URL + '/assets/images/youtube.png'} alt="Youtube" /></Link></li>
                      <li><Link to={{ pathname: footerData.footerData[0] && footerData.footerData[0].google }} target="_blank"><img src={process.env.PUBLIC_URL + '/assets/images/google.png'} alt="Google" /></Link></li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>







      
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}
        >
          <i className="fa fa-angle-double-up"></i>
        </button>
      </footer>
    </>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
  footerData: PropTypes.object,
};

const mapStateToProps = state => ({
  footerData: state.footerData.footerData,

});



export default connect(mapStateToProps, null)(FooterOne);
