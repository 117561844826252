import { ORDER_DEATILS_SUCCESS, CANCEL_ORDER_SUCCESS } from "../actions/orderActions";

const initState = {
  orderItemCancel: [],
  orderDetailsData: {}
};

const orderReducer = (state = initState, action) => {
  if (action.type === CANCEL_ORDER_SUCCESS) {
    return {
      ...state,
      orderItemCancel: action.payload
    };
  }
  else if (action.type === ORDER_DEATILS_SUCCESS) {
    return {
      ...state,
      orderDetailsData: action.payload
    };
  }

  return state;
};

export default orderReducer;