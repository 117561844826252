import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { confirmedPassword } from "../../redux/actions/Login-Register";
import { connect, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import axios from "axios";
import { VERIFY_TOKEN } from "../../consts";
import AlertMessage from "./AlertMessage";
import Loader from "./Loader";







const mapStateToProps = (state) => {
  return {
    products: state.productData.products,
    user: state.user
  };
};



const NewPassword = (user) => {
  const dispatch = useDispatch();
 
  const history = useHistory();

  const [data, setData] = useState({
    password: "",
    confirm_password: "",
  });


  const { addToast } = useToasts();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
   
    handlePassword();
  };

  const [res, setRes] = useState(null);
  const [token, setToken] = useState(null);

  const [loader, setLoader] = useState(false);

  const getVerifyToken1 = window.location.pathname.split('/');
  const getVerifyToken2 = getVerifyToken1[2];


  const [response, setResponse] = useState({ success: false, error: false });

  useEffect(() => {
    verify(getVerifyToken2);
    setToken(getVerifyToken2);
  }, [getVerifyToken2])


  const verify = (getVerifyToken2) => {
    setLoader(true);
    axios.get(VERIFY_TOKEN + getVerifyToken2 + "/")
      .then(response => {
        if (response.status === 200) {
          setRes(response.status);
          setResponse({ success: true })
          setLoader(false);
        }
      })
      .catch(err => {
        setLoader(false);
        setResponse({ error: true })
      }
      )
  }



  const handlePassword = () => {

    if (data.password === data.confirm_password) {
      dispatch(confirmedPassword(getVerifyToken2, { 'password': data.password, 'confirm_password': data.confirm_password }, addToast));
      history.push("/login");
    } else {
      addToast("Password and Confirm Password are not same", {
        appearance: "error",
        autoDismiss: true,
      });
    }

  };





  return (
    <Fragment>

      <>
      <MetaTags>
        <title>Zourie | New Password</title>
        <meta
          name="description" />
      </MetaTags>
     
        {loader && 
          <Loader />
        }
        <LayoutOne headerTop="visible">
        

          {response.success ?
            <div className="login-register-area pt-50 pb-100" >
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                  <h1 className="login-register-heading">Confirm New Password?</h1>
                    <div className="login-register-wrapper login-register-bg">
              


                          <div eventKey="login">
                            <div className="login-form-container">
                              <div className="login-register-form">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                  <div className="valid_error_dv token">
                                    <input
                                      type="input"
                                      name="token"
                                      placeholder="Token"
                                      value={token}
                                      disabled
                                    />

                                  </div>

                                  <div className="valid_error_dv">
                                    <input
                                      type="password"
                                      name="user-password"
                                      placeholder="Password"
                                      value={data.password}
                                      onChange={e => setData({ ...data, password: e.target.value })}
                                      maxLength="255"
                                      {...register("password", {
                                        required: true,
                                        value: data.password,
                                        onChange: (e) => setData({ ...data, password: e.target.value }),
                                      })}
                                      className={errors.password && 'valid_error_inpt'} />
                                    {errors.password && <p className="valid_error_txt">Password is required</p>}

                                  </div>


                                  <div className="valid_error_dv">
                                    <input
                                      type="password"
                                      name="confirm-password"
                                      placeholder="Confirm Password"
                                      value={data.confirm_password}
                                      onChange={e => setData({ ...data, confirm_password: e.target.value })}
                                      maxLength="255"
                                      {...register("confirm-password", {
                                        required: true,
                                        value: data.confirm_password,
                                        onChange: (e) => setData({ ...data, confirm_password: e.target.value }),
                                      })}
                                      className={errors.confirm_password && 'valid_error_inpt'}
                                    />
                                    {errors.confirm_password && <p className="valid_error_txt">Confirm Password is required</p>}
                                  </div>

                                  <div className="button-box button-box2">
                                    <Link className="btn" to="/login">
                                      <span> Cancel</span>
                                    </Link>
                                    <button type="Submit" onClick={handleSubmit}>
                                      <span>Submit</span>
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div> :(
              <div style={{minHeight:'300px'}}></div>
            )
          }

          {response.error &&
            <div className="login-register-area pt-100 pb-100">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">

                    <AlertMessage
                      icons="file_error"
                      title="Something went wrong!"
                      description="May be the user is already verified or the link has expired. You can click again on your verification link or contact us."
                    />

                  </div>
                </div>
              </div>
            </div>
          }

        </LayoutOne></>

    </Fragment>
  );
};

NewPassword.propTypes = {
  location: PropTypes.object
};


export default connect(mapStateToProps, null)(NewPassword);
