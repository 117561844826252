import axios from "axios";
import { GET_PRODUCTS } from "../../consts";

export const FETCH_AUCTIONS_START = "FETCH_AUCTIONS_START";
export const FETCH_AUCTIONS_SUCCESS = "FETCH_AUCTIONS_SUCCESS";
export const FETCH_AUCTIONS_FAILURE = "FETCH_AUCTIONS_FAILURE";


const fetchAuctionSuccess = auctions => ({
    type: FETCH_AUCTIONS_SUCCESS,
    payload: auctions
});


export const fetchAuctions = auctions => {
    return dispatch => {
        axios.get(GET_PRODUCTS,
            {
                params: {
                    product_type: "Auction",
                    limit: 10
                }
            }
        ).then(response => {
            dispatch(fetchAuctionSuccess(response.data.results));
        })
            .catch(error => {
                console.log(error);
            })
    };

};