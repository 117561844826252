import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import AllInboxMessages from "./AllInboxMessages";

import ComposeMessageSideBar from "./ComposeMessageSideBar";
import MessageDetail from "./MessageDetail";

const ComposeMessage = (props) => {
 
  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Messages</title>
        <meta
          name="description"
          content="Messages page of Zourie react minimalist eCommerce template."
        />
      </MetaTags>

      <LayoutOne headerTop="visible">
        <section className="cart-main-area pb-100">
            <div className="container">
              <div className="row mb-2">
                <div className="col-6 contact_detail">
                  <p>Contact <span>{props.location.data && props.location.data[0].added_by.first_name} {props.location.data && props.location.data[0].added_by.last_name}</span></p>
                </div>
                <div className="col-6 feedback_page">
                  <p>Provide feedback for this page</p>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <ComposeMessageSideBar data={props.location.data} Reviews={props.location.Reviews} Reviews_count={props.location.Reviews_count} Total_Products={props.location.Total_Products} />
                </div>
                <div className="col-9">
                  <MessageDetail data={props.location.data}/>
                </div>
              </div> 
            </div> 
        </section>
      </LayoutOne>
    </Fragment>
  );
};

ComposeMessage.propTypes = {
  location: PropTypes.object,
};



export default ComposeMessage;
