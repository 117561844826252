import axios from 'axios';
import { TESTIMONIALS } from '../../consts';

export const FETCH_TESTIMONIALS_SUCCESS = "FETCH_TESTIMONIALS_SUCCESS";


const fetchTestimonialSuccess = testimonials => ({
  type: FETCH_TESTIMONIALS_SUCCESS,
  payload: testimonials
});


export const fetchTestimonials = testimonials => {
  return dispatch => {
    axios.get(TESTIMONIALS)
    .then(response => {
      dispatch(fetchTestimonialSuccess(response.data),
      );
    })
    .catch(error => {
      console.log(error);
    });
  };
};