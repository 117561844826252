import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import axios from "axios";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import NewOrders from "../vendor/components/NewOrders";
import { GET_ORDERS } from "../../consts";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import { Link } from "react-router-dom";



const MessagesSideBar = ({ messagesData }) => {

  return (
    <>
      <div className="">
        <div className="">
          <div className="row">
            <div className="col-12">

              <div className="sidebar-heading">
                <p>My Messages</p>
              </div>

              <div className="sidebar_accordion">
                <Accordion className="accordion_div" allowMultipleExpanded preExpanded={['accordionTab1', 'accordionTab2', 'accordionTab3', 'accordionTab4']}>
                  <AccordionItem uuid='accordionTab1'>
                    <AccordionItemHeading>
                      <AccordionItemButton>Inbox</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul className="pr_rgt_accordion_ul">
                        <li className="compose-messages"><Link to="/all-messages">Compose Message</Link></li>
                        <li className="active-msgs"><Link to="all-messages">All Messages({messagesData.length && messagesData.length})</Link></li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid='accordionTab2'>
                    <AccordionItemPanel>
                      <ul className="content_msgs">
                        <li><Link to="all-messages">Sent</Link></li>
                        <li><Link to="all-messages">Trash</Link></li>
                        <li><Link to="all-messages">Archive</Link></li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem uuid='accordionTab3'>
                    <AccordionItemHeading>
                      <AccordionItemButton>My Folders</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem uuid='accordionTab4'>

                    <AccordionItemPanel>
                      <ul className="more_options">
                        <li>More Options</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MessagesSideBar.propTypes = {
  messagesData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  messagesData: state.messageData?.messagesData
})

export default connect(mapStateToProps)(MessagesSideBar);