import React from 'react'

const AlertMessage = (props) => {
  const { title, description, icons } = props;
  return (
    <>

      <div className="alert_message_dv">
        {icons === "file_error" && <img src={`${process.env.PUBLIC_URL}/assets/images/file_error.webp`} alt="" />}
        {icons === "payment_success" && <img src={`${process.env.PUBLIC_URL}/assets/images/payment_success.jpg`} alt="" />}
        {icons === "payment_cancel" && <img src={`${process.env.PUBLIC_URL}/assets/images/payment_cancel.jpg`} alt="" />}

        <h3>{title}</h3>
        <p>{description}</p>
      </div>

    </>
  )
}

export default AlertMessage