import axios from "axios";
import { GET_FAQS } from "../../consts";

export const FETCH_FAQS_START = "FETCH_FAQS_START";
export const FETCH_FAQS_SUCCESS = "FETCH_FAQS_SUCCESS";
export const FETCH_FAQS_FAILURE = "FETCH_FAQS_FAILURE";


const fetchFaqsSuccess = faqs => ({
    type: FETCH_FAQS_SUCCESS,
    payload: faqs
});

    
export const fetchfaqs = faqs => {
    return dispatch => {
        axios.get(GET_FAQS)
        .then(response => {
            dispatch(fetchFaqsSuccess(response.data));
        })
        .catch(error => {
            console.log(error);
        })
    };

};