import PropTypes from "prop-types";
import React, { Fragment, useState} from "react";
import MetaTags from "react-meta-tags";


import LayoutOne from "../../layouts/LayoutOne";


import AlertMessage from "./AlertMessage";






const PaymentCancelled = () => {







  return (
    <Fragment>

      <MetaTags>
        <title>Zourie | Payment Cancel</title>
        <meta name="description" />
      </MetaTags>

      <LayoutOne headerTop="visible">




        <div className="login-register-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">


                <AlertMessage
                  icons="payment_cancel"
                  title="Payment cancelled"
                  description="There is an error occurred that's why your payment is cancelled. Please try again."
                />
                

              </div>
            </div>
          </div>
        </div>




      </LayoutOne>

    </Fragment>
  );
};

PaymentCancelled.propTypes = {
  location: PropTypes.object
};


export default PaymentCancelled
