import axios from "axios";
import { GET_SUBSCRIPTION_PLANS, BUY_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_PLAN_STATUS } from "../../consts";

export const FETCH_SUBSBUY_SUCCESS = "FETCH_SUBSBUY_SUCCESS";
export const FETCH_SUBSBUY_FAILURE = "FETCH_SUBSBUY_FAILURE";
export const BUY_SUBPLAN_SUCCESS = "BUY_SUBPLAN_SUCCESS";
export const BUY_SUBPLAN_FAILURE = "BUY_SUBPLAN_FAILURE";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_SUBSPLAN = "CLEAR_SUBSPLAN";

const screenWidth = window.screen.width;
const screenHeight = window.screen.height;
const windowWidth = 520;
const windowHeight = 1000;

const left = Math.max(0, Math.floor((screenWidth - windowWidth) / 2));
const top = Math.max(0, Math.floor((screenHeight - windowHeight) / 2));


export const fetchSubsbuy = () => {
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        axios.get(GET_SUBSCRIPTION_PLANS)
            .then(response => {
                dispatch({
                    type: FETCH_SUBSBUY_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: SET_LOADING,
                    payload: false
                });
            })
            .catch(error => {
                dispatch({
                    type: SET_LOADING,
                    payload: false
                });
                dispatch({
                    type: FETCH_SUBSBUY_FAILURE,
                    payload: "Something went wrong in getting detail, please refresh"
                });
            })
    };
};

export const buySubsplan = (data, user, addToast, history) => {
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        const token = localStorage.getItem("Token");
        
        if (user) {
            axios.post(BUY_SUBSCRIPTION_PLAN, data, { headers: { Authorization: "Token " + token } })
            .then(response => {
                if (response.status === 200) {
                    addToast("Subscribed successfully", {
                        appearance: "success",
                        autoDismiss: true
                    });

                    setTimeout(() => {
                      
                        dispatch(getSubsStatus());
                    }, 2000);

                    history.push(`/subscription-detail`);
                 
                } else {
                    handleErrorResponse(response, addToast, dispatch, history);
                }
            })
            .catch(error => {
                handleErrorResponse(error.response, addToast, dispatch, history);
            })
            .finally(() => {
                dispatch({
                    type: SET_LOADING,
                    payload: false
                });
            });
        } else {
            addToast &&
                addToast("Please login to buy plan", {
                    appearance: "error",
                    autoDismiss: true
                });
            dispatch({
                type: SET_LOADING,
                payload: false
            });
            dispatch({
                type: BUY_SUBPLAN_FAILURE,
                payload: "Please login to buy plan"
            });

            history.push('/login');
        }
    };
};

const handleErrorResponse = (response, addToast, dispatch, history) => {
    if (response.status === 400) {
       
        addToast && addToast("Address is required", {
            appearance: "error",
            autoDismiss: true
        });
        dispatch({
            type: BUY_SUBPLAN_FAILURE,
            payload: "Please login to buy plan"
        });
        history.push('/vendor-account?response=true');
    } else {
        addToast && addToast("Something went wrong in buying plan, please refresh", {
            appearance: "error",
            autoDismiss: true
        });
        dispatch({
            type: BUY_SUBPLAN_FAILURE,
            payload: "Something went wrong in buying plan, please refresh"
        });
    }
};
export const getSubsStatus = () => {
    return dispatch => {
        const token = localStorage.getItem("Token");
        axios.get(GET_SUBSCRIPTION_PLAN_STATUS, { headers: { Authorization: "Token " + token } })
            .then(response => {
                dispatch({
                    type: BUY_SUBPLAN_SUCCESS,
                    payload: response.data
                });

            })
            .catch(error => {
                dispatch({
                    type: BUY_SUBPLAN_FAILURE,
                    payload: "Something went wrong in getting detail, please refresh"
                });
            })
    };
};
