import { FETCH_TODAY_TOP_PICK } from "../actions/topPickProductActions";


const initialState = {
  top_pick_products: []
}


const topPickProductReducer = (state = initialState, action) => {
    if (action.type === FETCH_TODAY_TOP_PICK) {
      return {
        ...state,
        top_pick_products: action.payload      
      };
    }
    return state;
};

export default topPickProductReducer;