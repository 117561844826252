import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import { Card, Container, InputGroup, FormControl } from "react-bootstrap";


const ComingSoon = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Coming Soon</title>
        <meta
          name="description"
          content="Furniture home of Zourie react minimalist eCommerce template."
        />
      </MetaTags>
      <LayoutOne headerTop="visible">
        
























        
      <section className='become_vendor_wrrpr coming_soon_wrrpr' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bg1.png'})` }}>
        <div className="become_vendor_dv coming_soon_dv">
          <h3>The next big thing is coming soon, <br /> We cannot wait to show you what's in store.</h3>
          <div className="coming_soon_frm">
            <input type="text" placeholder="Enter your email address......" />
            <button>Notify me</button>
          </div>
          <p>“Notify me when website is lounched”</p>
        </div>

      </section>



















       









      </LayoutOne>
    </Fragment>
  )
}

export default ComingSoon;