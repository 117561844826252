import axios from 'axios';
import { GET_PURCHASE_HISTORY } from '../../consts';
import { FETCH_PURCHASEHISTORY_SUCCESS, SET_LOADING } from '../reducers/purchaseHistoryReducer';



const fetchPurchaseHistorySuccess = purchaseHistory => ({
  type: FETCH_PURCHASEHISTORY_SUCCESS,
  payload: purchaseHistory
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});


export const fetchPurchaseHistory = (pageNumber) => {

  return dispatch => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("Token");
    axios.get(GET_PURCHASE_HISTORY, {
      headers: {
        Authorization: "Token " + token,
      },
      params: {
        limit: 10,
        page:pageNumber,
      },
    })
    .then(response => {
      dispatch(fetchPurchaseHistorySuccess(response.data),);
      dispatch(setLoading(false));
    })
    .catch(error => {
      console.log(error);
      dispatch(setLoading(false));
    });
  };
};