import axios from "axios";
import { POST_FOLLOWING_REQUEST, POST_UNFOLLOWING_REQUEST,CONTACT_SELLER, MESSAGE_REPLY } from "../consts";
import { fetchFollowers } from "../redux/actions/followersActions";
import { fetchMessagesData } from "../redux/actions/getMessagesActions";

export const sendFollowingRequest = (data, addToast) => {
  return (dispatch) => {
    const token = localStorage.getItem("Token");
    axios
      .post(POST_FOLLOWING_REQUEST, data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(fetchFollowers());
        if (response.status === 201) {
          addToast &&
            addToast("Follow successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });

          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        addToast &&
          addToast(err.response.data.message, {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
      });
  };
};

export const sendUnfollowingRequest = (data, addToast) => {
  return (dispatch) => {
    const token = localStorage.getItem("Token");
    axios
      .post(POST_UNFOLLOWING_REQUEST, data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(fetchFollowers());
        if (response.status === 200) {
          addToast &&
            addToast("Unfollow successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });

          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        addToast &&
          addToast(err.response.data.message, {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
      });
  };
};

export const sendMessage = (data, addToast, history) => {
  return (dispatch) => {
    const token = localStorage.getItem("Token");
    axios
      .post(CONTACT_SELLER, data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          addToast &&
            addToast("Message sent successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
            history.push('/all-messages');
          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        addToast &&
          addToast("Some thing went wrong", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
      });
  };
};

export const sendMessageReply = (data, addToast) => {
  return (dispatch) => {
    const token = localStorage.getItem("Token");
    axios
      .post(MESSAGE_REPLY, data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchMessagesData(response.data.user_id));
          addToast &&
            addToast("Message sent successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        addToast &&
          addToast("Some thing went wrong", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
      });
  };
};