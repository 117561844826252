import axios from "axios";
import { BLOGS } from "../../consts";

export const FETCH_BLOG_START = "FETCH_BLOG_START";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOG_FAILURE = "FETCH_BLOG_FAILURE";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";


const fetchBlogsSuccess = blogs => ({
    type: FETCH_BLOGS_SUCCESS,
    payload: blogs
});


    
export const fetchBlogs = blogs => {
    return dispatch => {
        axios.get(BLOGS)
        .then(response => {
            dispatch(fetchBlogsSuccess(response.data));
        })
        .catch(error => {
            console.log(error);
        })
        
    };
};
