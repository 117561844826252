import React from 'react'
import {
  Button,
  Modal,
} from "react-bootstrap";
import { Link } from 'react-router-dom';

const PrivacySettings = (props) => {
  const { showPopup1, handleClosePopup1 } = props


  return (
    <>



      <Modal className="privacy_settings_popup" show={showPopup1} onHide={handleClosePopup1}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Settings</Modal.Title>
          <p>Last updated on April 2022</p>
        </Modal.Header>
        <Modal.Body className="privacy_settings_dv1">

          <div className="privacy_settings_dv2">
            <h4>Zourie uses cookies and similar technologies to give you a better experience, enabling things like:</h4>
            <ul>
              <li>Basic site functions.</li>
              <li>Ensuring secure, safe transactions.</li>
              <li>Secure account login.</li>
              <li>Remembering account, browser, and regional preferences.</li>
              <li>Remembering privacy and security settings.</li>
              <li>Analysing site traffic and usage.</li>
              <li>Personalized search, content, and recommendations.</li>
              <li>Helping sellers understand their audience.</li>
              <li>Showing relevant, targeted ads on and off Zourie.</li>
            </ul>
            <p>Detailed information can be found in Zouri’s <Link to="#">Cookies & Similar Technologies Policy and our Privacy Policy.</Link></p>
          </div>

          <div className="privacy_settings_dv2">
            <h3>Licensing Policy and Settings</h3>
            <h4>Here are terms of our Standard License:</h4>
            <ul>
              <li>The standard license grant you a non-exclusive  right to make use of template you have purchased.</li>
              <li>You are licensed to use the item to create one End product for yourself or for one client and the End product can be distributed for free.</li>
            </ul>
            <h4>If you opt for on Extended License:</h4>
            <ul>
              <li>You are licensed to use the item to create one End product for yourself or for one client and the End product can be distributed for free.</li>
            </ul>
          </div>

          <div className="privacy_settings_dv2">
            <h3>Required Cookies & Technologies</h3>
            <p>Some of the technologies we use are necessary for critical functions like security and site integrity, account authentication, security and privacy preferences, internal site usage and maintenance data, and to make the site work correctly for browsing and transactions.</p>
          </div>

          <div className="privacy_settings_dv2">
            <h3>Personalized Advertising</h3>
            <p>These are third party technologies used for things like interest based Zourie ads.</p>
            <p>We do this with marketing and advertising partners (who may have their own information they’ve collected). Saying no will not stop you from seeing Zourie ads or impact Zourie's own personalization technologies, but it may make the ads you see less relevant or more repetitive. Find out more in our <Link to="#">Cookies & Similar Technologies Policy.</Link></p>
          </div>

        </Modal.Body>
      </Modal>





    </>
  )
}

export default PrivacySettings