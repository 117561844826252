import React, { Fragment  } from "react";
import MetaTags from "react-meta-tags";

import LayoutOne from "../../layouts/LayoutOne";
import { Link, useHistory } from "react-router-dom";







const ExpireSubscription = () => {
  const history = useHistory();


  const redirectToSub = () => {
    history.push('/seller-charges-and-fees')
  }



  return (
    <Fragment>

      <><MetaTags>
        <title>Zourie | Subscription Expired</title>
        <meta
          name="description" />
      </MetaTags>
      
        <LayoutOne headerTop="visible">
        
          <div class="thankyoucontent">
            <div class="wrapper-1">
              <div class="wrapper_expire">
                <img src="/assets/images/expire.png" alt=""/>
                <h1>
                  Your Subscription has been Expired
                </h1>
        
                <Link to='/buy-subscription' className="subsc_button" >Subscription</Link>
              </div>
            </div>
          </div>






        </LayoutOne></>

    </Fragment>
  );
};


export default ExpireSubscription;
