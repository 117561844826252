import { FETCH_ALL_VENDORS, FETCH_FOOTERCONTENT_SUCCESS } from "../actions/footerActions";


const initState = {
  footerData: []
};

const footerReducer = (state = initState, action) => {
  if (action.type === FETCH_FOOTERCONTENT_SUCCESS) {
    return {
      ...state,
      footerData: action.payload
    };
  } else if (action.type === FETCH_ALL_VENDORS) {
    return {
      ...state,
      vendors: action.payload
    };
  }

  return state;
};

export default footerReducer;