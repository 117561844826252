import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, path, component, render }) => {

  return (
    <Route
      path={path}
      render={(props) =>
        isAuthenticated ? (
          component ? (
            React.createElement(component, props)
          ) : (
            render(props)
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
