import axios from 'axios';
import { GET_REVIEWS } from '../../consts';

export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";


const fetchReviewsSuccess = reviewsList => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: reviewsList
});


export const fetchReviews = (setLoading, pageNumber) => {
  return dispatch => {
    setLoading(true);
    const token = localStorage.getItem("Token");
    axios.get(GET_REVIEWS, {
      headers: {
        Authorization: "Token " + token,
      },
      params:{
        limit: 10,
        page: pageNumber
      }
    })
    .then(response => {
      dispatch(fetchReviewsSuccess(response.data),
      );
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  };
};