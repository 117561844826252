import { FETCH_SUBSBUY_SUCCESS, FETCH_SUBSBUY_FAILURE, BUY_SUBPLAN_SUCCESS, BUY_SUBPLAN_FAILURE, SET_LOADING, CLEAR_SUBSPLAN } from "../actions/subscriptionplanActions";

const initState = {
  loading: true,
  plans: [],
  error: "",
  buyplan_checked: false,
  buyplan: null,
  buyplan_error: ""
};

const subscriptionplanReducer = (state = initState, action) => {
  if (action.type === FETCH_SUBSBUY_SUCCESS) {
    return {
      ...state,
      plans: action.payload,
      loading: false
    };
  } else if (action.type === FETCH_SUBSBUY_FAILURE) {
    return {
      ...state,
      plans: [],
      loading: true,
      error: action.payload
    };
  } else if (action.type === BUY_SUBPLAN_SUCCESS) {
    return {
      ...state,
      buyplan_checked: true,
      buyplan: action.payload,
      buyplan_error: ""
    };
  } else if (action.type === BUY_SUBPLAN_FAILURE) {
    return {
      ...state,
      buyplan_checked: false,
      buyplan: null,
      buyplan_error: action.payload
    };
  } else if (action.type === SET_LOADING) {
    return {
      ...state,
      loading: action.payload
    };
  } else if (action.type === CLEAR_SUBSPLAN) {
    return {
      ...state,
      buyplan_checked: false,
      buyplan: null,
      buyplan_error: ""
    };
  }


  return state;
};

export default subscriptionplanReducer;