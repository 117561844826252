import axios from "axios";
import {
  USER_LOGIN,
  USER_REGISTER,
  PASSWORD_RESET,
  USER_PROFILE,
  EDIT_USER,
  ADD_ADDRESS,
  NEW_PASSWORD,
  SAVE_BANK_DETAILS,
  CHANGE_EMAIL_REQ,
  CHANGE_PASSWORD_REQ,
  GET_VENDOR_DETAILS,
} from "../../consts";
import { UserLogout } from "../reducers/Login-Register";
import * as types from "../types/usertypes";
import { getSubsStatus } from "./subscriptionplanActions";



const fetchUserSuccess = (user) => ({
  type: types.USER_PROFILE,
  payload: user,
});

const editUser = (user) => ({
  type: types.EDIT_USER_SUCCESS,
  payload: user,
});

const updateAddress = (address) => ({
  type: types.UPDATE_ADDRESS_SUCCESS,
  payload: address,
});

const saveAddress = (address) => ({
  type: types.SAVE_ADDRESS_SUCCESS,
  payload: address,
});

const saveAddressNew = (address) => ({
  type: types.SAVE_ADDRESS_SUCCESS,
  payload: address,
});


const saveBankDetails = (bankDetails) => ({
  type: types.SAVE_BANK_DETAILS_SUCCESS,
  payload: bankDetails,
});

const updateBankDetails = (bankDetails) => ({
  type: types.UPDATE_BANK_DETAILS_SUCCESS,
  payload: bankDetails,
});

const chnageEmailDetails = (userEmailChange) => ({
  type: types.CHANGE_EMAIL_SUCCESS,
  payload: userEmailChange,
});

const chnagePasswordDetails = (userPasswordChange) => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  payload: userPasswordChange,
});

const forgotPasswordDetails = (userForgotPasswordReset) => ({
  type: types.PASSWORD_RESET_SUCCESS,
  payload: userForgotPasswordReset,
});

export const startLoading = () => ({
  type: types.START_LOADING,
});

export const stopLoading = () => ({
  type: types.STOP_LOADING,
});

export const fetchVerifiedAccount = (verifyAccount) => ({
  type: types.VERIFY_STRIPE_ACCOUNT_SUCCESS,
  payload: verifyAccount,
});



const token = localStorage.getItem("Token");

export const userLogin = (email, password, history, addToast) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(USER_LOGIN, {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("Token", response.data.token);
          dispatch(fetchUserProfile(response.data.token));
          dispatch(getSubsStatus());
          addToast &&
            addToast("Login successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
      
          dispatch(stopLoading());

          return true;
        }

    
      })
      .catch((error) => {
        dispatch(stopLoading());
        if (error.response.data.non_field_errors) {
          addToast &&
            addToast(error.response.data.non_field_errors, {
              appearance: "error",
              autoDismiss: true,
              pauseOnHover: true,
            });
        } else {
          addToast &&
            addToast(error.response.data.message, {
              appearance: "error",
              autoDismiss: true,
              pauseOnHover: true,
            });
        }
      });
  };
};

export const UserRegister = (
  email,
  password,
  confirm_pass,
  first_name,
  last_name,
  isVendor,
  history,
  addToast
) => {
  if (isVendor !== true) {
    isVendor = false;
  }
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(USER_REGISTER, {
        email: email,
        password: password,
        confirm_pass: confirm_pass,
        first_name: first_name,
        last_name: last_name,
        is_staff: isVendor,
      })
      .then((response) => {
       


        if (response.status === 201) {
          addToast &&
            addToast("Register successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
          dispatch(stopLoading());
          history.push("/login");
          return true;
        }
      
      })
      .catch((err) => {
        dispatch(stopLoading());
        if (err.response.data.email) {
          addToast &&
            addToast(err.response.data.email, {
              appearance: "error",
              autoDismiss: true,
              pauseOnHover: true,
            });
        }
        else {
          addToast &&
            addToast(err.response.data.non_field_errors, {
              appearance: "error",
              autoDismiss: true,
              pauseOnHover: true,
            });
        }


        dispatch({
          type: types.REGISTER_USER_FAILURE,
          payload: err,
        });
    
      });
  };
};

export const UserForgotPassword = (email, addToast) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(PASSWORD_RESET, {
        email: email,
      })
      .then((response) => {
        dispatch(forgotPasswordDetails(response.data));
        if (response.status === 200) {
          addToast &&
            addToast("Password reset email sent!", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
          dispatch(stopLoading());
          return true;
        }
      })
      .catch((err) => {
        dispatch({
          type: types.PASSWORD_RESET_FAILURE,
          payload: err,
        });
        dispatch(stopLoading());
        addToast &&
          addToast("Please fill a valid email", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
      });
  };
};

export const fetchUserProfile = (key) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(USER_PROFILE, {
        headers: {
          Authorization: `Token ${key}`,
        },
      })
      .then((response) => {
        dispatch(
          fetchUserSuccess(response.data),
        );
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.USER_PROFILE_FAILURE,
          payload: err,
        });
      });
  };
};

export const editUserProfile = (
  data,
  addressData,
  addToast
) => {

  const profileData = {
    'profile': {
      profile_image: data.profileImage,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phone,
      dob: data.dob,
      store_name: data.storeName,
      bio: data.bio,
    },
    'address': {
      user: addressData.user,
      id: addressData.id,
      house_number: addressData.houseNumber,
      area: addressData.area,
      zip_code: addressData.zipCode,
      state: addressData.state,
      city: addressData.city,
    }
  }

  return (dispatch) => {
    dispatch(startLoading());
    axios
      .patch(EDIT_USER, profileData, {
        headers: {
          Authorization: "Token " + token
        },
      })
      .then((response) => {
        dispatch(editUser(response.data));


        if (response.status === 200) {
          dispatch(fetchUserProfile(token));
          addToast &&
            addToast("Profile updated successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
          dispatch(stopLoading());
          return true;
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.EDIT_USER_PROFILE_FAILURE,
          payload: err,
        });
        addToast &&
          addToast("Profile not updated", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });

        return false;
      });
  };
};

export const editUserAddress = (
  userId,
  houseNumber,
  city,
  zipCode,
  state,
  area,
  addressId,
  addToast
) => {


  const data = {
    user: userId,
    house_number: houseNumber,
    city: city,
    state: state,
    zip_code: zipCode,
    area: area,
  };
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(ADD_ADDRESS + addressId + "/", data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(updateAddress(response.data));

        if (response.status === 200) {
          addToast("Address updated successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          dispatch(fetchUserProfile(token));
          dispatch(stopLoading());

          return true;
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.EDIT_USER_PROFILE_FAILURE,
          payload: err,
        });
        addToast &&
          addToast("Address not updated", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
        return false;
      });
  };
};

export const saveUserAddress = (
  data,
  addToast
) => {

  const token = localStorage.getItem("Token");

  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(ADD_ADDRESS, data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(saveAddress(response.data));
        if (response.status === 201) {
          addToast("Address added successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          dispatch(fetchUserProfile(token));
          dispatch(stopLoading());
          return true;
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.SAVE_ADDRESS_FAILURE,
          payload: err,
        });

        addToast(err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: true,
        });

        return false;
      });
  };
}

export const confirmedPassword = (getVerifyToken2, data, addToast) => {

  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(NEW_PASSWORD + getVerifyToken2 + "/", data)
      .then((response) => {
        dispatch({
          type: types.CONFIRM_PASSWORD_SUCCESS,
          payload: response.data,
        });
        if (response.status === 200) {
          addToast &&
            addToast("Password changed successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
          dispatch(stopLoading());
          return true;
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.CONFIRM_PASSWORD_FAILURE,
          payload: err,
        });
        addToast &&
          addToast("Password not changed", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
      });
  };
};


export const userBankDetails = (
  bankDetails,
  addToast
) => {


  const data = {
    bank_name: bankDetails.bankName,
    account_holder_name: bankDetails.accountHolderName,
    bank_routing_number: bankDetails.bankRoutingNumber,
    account_number: bankDetails.accountNumber
  };


  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(SAVE_BANK_DETAILS, data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(saveBankDetails(response.data));

        if (response.status === 200) {
          addToast("Bank details added successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          dispatch(fetchUserProfile(token));
          dispatch(stopLoading());
          return true;
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.SAVE_BANK_DETAILS_FAILURE,
          payload: err,
        });
        addToast("Bank details not updated", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: true,
        });
        return false;
      });
  };
}

export const updateUserBankDetails = (
  id,
  userId,
  bankDetails,
  addToast
) => {


  const data = {
    bank_name: bankDetails.bankName,
    user: userId,
    account_holder_name: bankDetails.accountHolderName,
  };

  bankDetails.bankRoutingNumber && (
    Object.assign(data, { bank_routing_number: bankDetails.bankRoutingNumber })
  )

  bankDetails.accountNumber && (
    Object.assign(data, { account_number: bankDetails.accountNumber })
  )


  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(SAVE_BANK_DETAILS + id + "/", data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(updateBankDetails(response.data));

        if (response.status === 200) {
          addToast("Bank details updated successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          dispatch(fetchUserProfile(token));
          dispatch(stopLoading());
          return true;
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.UPDATE_BANK_DETAILS_FAILURE,
          payload: err,
        });
        addToast("Bank details not updated", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: true,
        });
        return false;
      });
  };
}

export const changeEmailReq = (data, addToast) => {

  return (dispatch) => {
    dispatch(startLoading());
    axios
      .put(CHANGE_EMAIL_REQ, data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(chnageEmailDetails(response.data));
        if (response.status === 200) {
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          dispatch(stopLoading());
          return true;
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
        dispatch({
          type: types.CHANGE_EMAIL_FAILURE,
          payload: err,
        });
        addToast &&
          addToast("Request for email change not sent", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
        return false;
      });
  };
};

export const changePasswordReq = (data, addToast, history) => {
  const requestData = {
    old_password: data.password,
    new_password: data.newPassword,
    confirm_password: data.confirmPassword,
  }
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(CHANGE_PASSWORD_REQ, requestData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        dispatch(chnagePasswordDetails(response.data));
        if (response.status === 200) {
          addToast && addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          dispatch(stopLoading());
          setTimeout(() => {
            history.push('/login')
            dispatch(UserLogout(history));
          }, 2000);
          return true;
        }
      })
      .catch((err) => {
        dispatch({
          type: types.CHANGE_PASSWORD_FAILURE,
          payload: err,
        });
        addToast &&
          addToast(err.response.data.message, {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
        dispatch(stopLoading());
        return false;
      });
  };
};


export const checkVerifiedAccount = () => {
  return dispatch => {
    axios.get(GET_VENDOR_DETAILS, {
      headers: {
        Authorization: "Token " + localStorage.getItem("Token")
      }
    }).then((response) => {
      if (response.status === 200) {
      
        dispatch(fetchVerifiedAccount(response.data.message));
      }
    }).catch((error) => {
      console.log(error, "error")
    })
  }
}

