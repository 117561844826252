import { FETCH_PAGINATED_DATA_SUCCESS, SET_CURRENT_PAGE } from './../actions/paginationActions';

const initialState = {
  data: [],       
  currentPageNew: 5,  
};

const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAGINATED_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPageNew: action.payload,
      };

    default:
      return state;
  }
};

export default paginationReducer;
