import axios from "axios";
import {  GET_MY_FAVOURITE_PRODUCTS } from "../../consts";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";





export const addToWishlist = (item, event, addToast) => {
  event.stopPropagation();
  const token = localStorage.getItem("Token")
  return dispatch => {
    if (addToast) {
      addToast("Added To Wishlist", {
        appearance: "success",
        autoDismiss: true
      });
    }
    axios.get(`${GET_MY_FAVOURITE_PRODUCTS}`,
      {
        headers: {
          Authorization: "Token " + token
        },
      }
    )
      .then(response => {
   
      })

    dispatch({ type: ADD_TO_WISHLIST, payload: item });
  };
};


export const deleteFromWishlist = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
  };
};

export const deleteAllFromWishlist = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};
