import {FETCH_FOLLOWERS_SUCCESS} from "../actions/followersActions";

const initState = {
  followersList: []
};

const followersReducer = (state = initState, action) => {
  if (action.type === FETCH_FOLLOWERS_SUCCESS) {
    return {
      ...state,
      followersList: action.payload
    };
  }

  return state;
};

export default followersReducer;