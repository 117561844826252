import React, { useState, useEffect } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatIcon from '../../assets/assets/images/chat.svg';
import productDetails from '../../assets/assets/images/product-order-deatils.png'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LeaveFeedbackHistory from "../vendor/components/LeaveFeedbackHistory";
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';


const OrderDetailsSeller = () => {
    const msfCount = 4;
    const history = useHistory();
    const orderDeatilsData = useSelector((state) => state.ordersData?.orderDetailsData)
    const [open2, setOpen2] = useState(false);
    const [inFeedback, setInFeedback] = useState(null);
    const [shippingPriceTotal, setShippingPriceTotal] = useState(0);






    const handleFeedbackProduct = (productData) => {
        setOpen2(true);
        setInFeedback(productData);
    }

    const handleClose2 = () => {
        setOpen2(false);
    }

    let items = orderDeatilsData?.order_item?.map((item, i) => {
        return item
    })


    const timestamp = "2023-11-21T07:59:16.157240Z";
    const formattedDate = new Date(timestamp).toISOString().split('T')[0].replace(/-/g, '_');

    useEffect(() => {
        const totalShippingCost = items.reduce((acc, item) => acc + item.product.shipping_cost, 0);
                setShippingPriceTotal(totalShippingCost);

    }, [items]);


    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Order Details',
      });
      




    return (

        <div className="col-xl-12" ref={componentRef}>

            <div className="row">
                <div className="col-12">
                    <div className='order-details-header'>
                        <ArrowBackIcon onClick={() => history.push('/purchase-history')} /> <p>Order Details</p>
                    </div>
                </div>
            </div>

            <div className="row"   >
                <div className="col-12">
                    <div className='order-deatils'>
                        <div className='print-and-chat mb-20'>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-btn'>
                                    Print
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        // href="#/action-1" 
                                        onClick={handlePrint}
                                        
                                        >Print all document</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div
                                style={{ cursor: 'pointer' }}
                                className='chat-with-seller'

                                onClick={() => {
                                    history.push("/compose-message")
                                }
                                }
                            >
                                <img src={ChatIcon} alt="" />
                                <p
                                >Chat with Seller</p>
                            </div>

                        </div>

                   

                        <div className='package-delivered'>
                            <p><span>17 sep 2023 - 20: 41</span>  Your Package has been delivered . Thank you for shopping at Zourie</p>
                            <button>View More</button>
                        </div>



                        {
                            orderDeatilsData?.order_item?.map((item, i) => (
                                <div className='order-deatil-product' key={i}>
                                    <div className='row'>
                                        <div className="col-xl-6 col-lg-12 product-deatils-2">
                                            <img src={item.product?.product_image[0]?.upload_image} alt="" className='product-image' />
                                            <div>
                                                <p>{item.product.product_title}</p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 product-deatils">
                                            <span>${item.sub_total}</span>
                                            <div>
                                                <p>Return</p>
                                                <p>Until 24 Sep 2023</p>
                                                <p
                                                    onClick={() => handleFeedbackProduct(item)}
                                                    style={{ cursor: 'pointer' }}
                                                >Write a Review</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="msf_header-2">
                                        <ul>
                                            <li className={`${msfCount > 1 && "msf_act"}`}>
                                                <p>Payment Panding</p>
                                            </li>
                                            <li className={`${msfCount > 2 && "msf_act"}`}>
                                                <p>Processing</p>
                                            </li>
                                            <li className={`${msfCount > 3 && "msf_act"}`}>
                                                <p>Shipped</p>
                                            </li>
                                            <li className={`${msfCount > 4 && "msf_act"}`}>
                                                <p>Delivered</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6 col-lg-12">
                    <div className='shipping-address'>
                        <h1>Shipping Address</h1>
                        <p>Name</p>
                        <div className='shipping-address-home'>
                            <span>Home</span>
                            <p>Franklin Hernandez 7487 Harvey Point Suite 060, NY</p>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-12">
                    <div className='total-summary'>
                        <h1>Total Summary</h1>

                        <ul>
                            <li>
                                <p>Subtotal</p>
                                <span>${orderDeatilsData?.order_total}</span>
                            </li>
                            <li>
                                <p>Shipping Fee</p>
                                <span>${shippingPriceTotal}</span>
                            </li>
                            <li>
                                <p>Total</p>
                                <span>${orderDeatilsData?.order_total + shippingPriceTotal}</span>
                            </li>
                        </ul>

                    </div>
                </div>

                <LeaveFeedbackHistory
                    open2={open2}
                    inFeedback={inFeedback}
                    handleClose2={handleClose2}
                />

            </div>

        </div>

    )
}

export default OrderDetailsSeller