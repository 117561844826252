import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import AllInboxMessages from "./AllInboxMessages";
import MessagesSideBar from "./MessagesSideBar";
import { connect, useDispatch } from 'react-redux';
import { fetchMessagesData } from "../../redux/actions/getMessagesActions";
import Sidebar from "../vendor/Sidebar";

const AllMessages = ({messagesData, user}) => {
  
  const dispatch = useDispatch();

  const [sbactive] = useState('AllMessages');

  
  useEffect(()=>{
    dispatch(fetchMessagesData(user.id));
  },[])

  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Messages</title>
        <meta
          name="description"
          content="Messages page of Zourie react minimalist eCommerce template."
        />
      </MetaTags>

      <LayoutOne headerTop="visible">
        <section className="cart-main-area messages-container">
          <div className="container">
       
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <MessagesSideBar />

              </div>
              <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <AllInboxMessages />
              </div>
            </div>
          </div>
        </section>
      </LayoutOne>
    </Fragment>
  );
};

AllMessages.propTypes = {
  messagesData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  messagesData: state.messageData?.messagesData,
  user: state.userData.user 
})

export default connect(mapStateToProps)(AllMessages);