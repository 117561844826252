import { PRODUCT_PAYMENT } from "../actions/productActions";

const initState = {
  productPayment: []
};

const productPaymentReducer = (state = initState, action) => {
  if (action.type === PRODUCT_PAYMENT) {
    return {
      ...state,
      productPayment: action.payload
    };
  }

  return state;
};

export default productPaymentReducer;