
export const FETCH_PURCHASEHISTORY_SUCCESS = "FETCH_PURCHASEHISTORY_SUCCESS";
export const SET_LOADING = "SET_LOADING";

const initState = {
  purchaseHistory: []
};

const purchaseHistoryReducer = (state = initState, action) => {
  if (action.type === FETCH_PURCHASEHISTORY_SUCCESS) {
    return {
      ...state,
      purchaseHistory: action.payload
    };
  }
  else if (action.type === SET_LOADING) {
    return {
      ...state,
      loading: action.payload
    };
  }

  return state;
};

export default purchaseHistoryReducer;