import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import topPickProductReducer from "./topPickProductReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import {blogsReducer} from "./blogReducer";
import testimonialReducer from "./testimonialReducer";
import bannerReducer from "./bannerReducer";
import auctionReducer from "./auctionReducer";
import {reportVendorReducer} from "./reportVendorReducer";
import userReducer from "./Login-Register";
import categoryReducer from "./categoryReducer";
import newArrivalsReducer from "./newArrivalsReducer";
import productPaymentReducer from "./productPaymentReducer";
import faqReducer from "./faqReducer";
import { saveProductReducer } from "./saveProductReducer";
import footerReducer from "./footerReducer";
import paginationReducer from "../reducers/PaginationReducer";

import purchaseHistoryReducer from "./purchaseHistoryReducer";
import followersReducer from "./followersReducer";
import reviewsReducer from "./reviewsReducer";
import jobReducer from "./jobReducer";
import getMessagesReducer from "./getMessagesReducer";

import subscriptionplanReducer from "./subscriptionplanReducer";
import dashboardReducer from "./dashboardReducer";
import transactionReducer from "./transactionReducer";
import orderReducer from "./orderReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  blogsData: blogsReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  testimonialData: testimonialReducer,
  bannerData: bannerReducer,
  auctionData: auctionReducer,
  userData: userReducer,
  todayTopPickProductsData: topPickProductReducer,
  reportVendorData: reportVendorReducer,
  categoriesData: categoryReducer,
  newArrivalsData: newArrivalsReducer,
  paymentData: productPaymentReducer,
  faqData: faqReducer,
  saveProductData: saveProductReducer,
  footerData: footerReducer,
  purchaseHistoryData: purchaseHistoryReducer,
  followersListData: followersReducer,
  reviewsListData: reviewsReducer,
  jobListData: jobReducer,
  messageData: getMessagesReducer,
  subscriptionplans: subscriptionplanReducer,
  dashboard: dashboardReducer,
  transactionData: transactionReducer,
  ordersData: orderReducer,
  pagination: paginationReducer,
});



export default rootReducer;
