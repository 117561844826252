import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";

const IconGroup = ({
  currency,
  cartData,

  deleteFromCart,
  iconWhiteClass
}) => {
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  
  return (
    <>








      <div className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`} style={{marginTop: '0'}}>

        <div className="same-style cart-wrap d-none d-lg-block">
          <button   onClick={e => handleClick(e)}>
       
            <div className="header2_cart_btn"><img src={process.env.PUBLIC_URL + '/assets/images/cart.svg'} alt="Cart" /></div>
            <span className="count-style">
              {cartData && cartData.length ? cartData.length : 0}
            </span>
          </button>
          <MenuCart
            cartData={cartData}
            currency={currency}
            deleteFromCart={deleteFromCart}
          />
        </div>

        <div className="same-style cart-wrap d-block d-lg-none">
          <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
            <div className="header2_cart_btn"><img src={process.env.PUBLIC_URL + '/assets/images/cart.svg'} alt="Cart" /></div>
            <span className="count-style">
              {cartData && cartData.length ? cartData.length : 0}
            </span>
          </Link>
        </div>

     

      </div>



















    </>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
