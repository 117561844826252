import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';

import { useDispatch } from "react-redux";
import { checkVerifiedAccount } from "../../../redux/actions/Login-Register";


const Widgets = ({ dashboardData, messageData }) => {
  const subscription = useSelector(state => state?.subscriptionplans?.buyplan)
  const subscriptioncondition = useSelector(state => state?.subscriptionplans?.buyplan?.is_sub_really_active)
  const dispatch = useDispatch();

  console.log(subscription, "jhjhjhjhjhjhjhjhjhjhjhjhjhjhjh")






  const [totalSale, setTotalSale] = useState(dashboardData?.totalSales)

  useEffect(() => {
    setTotalSale(dashboardData?.totalSales)
  }, [dashboardData])


  const newTotalSale = dashboardData?.orders?.order_details?.reduce((acc, order) => {
    if (order.item_status === "Cancelled" || order.item_status === "Returned") {
      return acc - order.unit_price;
    }
    return acc;
  }, totalSale);

  function formatLargeNumber(number) {

    if (typeof number === 'number' && !isNaN(number)) {
      const numberString = number.toString();
      if (numberString.length > 8) {
        return numberString.substring(0, 7) + '..';
      }
      return numberString;
    }
    return 'N/A';
  }

  useEffect(() => {
    dispatch(checkVerifiedAccount());
  }, [])

  return (
    <>
      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <div className="das_box2">
          <p>Create New Listing</p>


          <Link
            to={{
              pathname: subscriptioncondition === true ? "/add-listing" : "/vendor-account",
              state: {
                type: subscriptioncondition === true ? "Fixed" : "Inactive"
              }
            }}

          >Fixed Price</Link>



          <Link
            to={{
              pathname: subscriptioncondition === true ? "/add-listing" : "/vendor-account",
              state: {
                type: subscriptioncondition === true ? "Auction" : "Inactive"
              }
            }}
          >Auction</Link>



          {subscription?.pro_listing === true &&
            <Link to="/add-listing-pro">Pro Listing</Link>
          }
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <Link to="/vendor-orders" className="das_box1">
          <div className="das_box1_count">
            <h3 style={{ color: '#388087' }}>{dashboardData?.dashbaordInfo?.orders}</h3>

            <img src={process.env.PUBLIC_URL + '/assets/images/manage_orders.svg'} alt="Manage Orders" />
          </div>
          <p>Vendor Orders</p>
        </Link>
      </div>

      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <Link to="/manage-listings" className="das_box1">
          <div className="das_box1_count">

            <h3 style={{ color: '#C1701F' }}>{dashboardData?.dashbaordInfo?.products}</h3>

            <img src={process.env.PUBLIC_URL + '/assets/images/manage_listings.svg'} alt="Manage Listings" />
          </div>
          <p>Manage Listings</p>
        </Link>
      </div>

      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <Link to={{
          pathname: "/return-and-cancellations",
          state:
            "Cancelled-Returned"
        }} className="das_box1">
          <div className="das_box1_count">
            <h3 style={{ color: '#F21C1C' }}>{dashboardData?.dashbaordInfo?.returns_cancelled}</h3>

            <img src={process.env.PUBLIC_URL + '/assets/images/return_cancelations.svg'} alt="Return & Cancellations" />
          </div>
          <p>Return & Cancellations</p>
        </Link>
      </div>

      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <div className="das_box3">
          <p>Total Sales</p>
          <img src={process.env.PUBLIC_URL + '/assets/images/total_sales.svg'} alt="Total Sales" />
          <Tooltip title={dashboardData?.dashbaordInfo?.total_sales?.toFixed(2)}>
            <span>${formatLargeNumber(dashboardData?.dashbaordInfo?.total_sales)}</span>
          </Tooltip>
        </div>
      </div>

      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <Link to="/all-messages" className="das_box1">
          <div className="das_box1_count">
            <h3 style={{ color: '#2C9FD9' }}>{messageData?.length}</h3>
            <img src={process.env.PUBLIC_URL + '/assets/images/messages.svg'} alt="Messages" />
          </div>
          <p>Messages</p>
        </Link>
      </div>

      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <Link to={{ pathname: "/marketing", state: "marketing_option_per__gte" }} className="das_box1">
          <div className="das_box1_count">
            <h3 style={{ color: '#A22CD9' }}>{dashboardData?.dashbaordInfo?.marketing_products}</h3>

            <img src={process.env.PUBLIC_URL + '/assets/images/marketing.svg'} alt="Marketing" />
          </div>
          <p>Marketing</p>
        </Link>
      </div>

      <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12 das_box_dv">
        <div className="das_box1">
          <div className="das_box1_count">
            <Tooltip title={dashboardData?.dashbaordInfo?.total_sales?.toFixed(2)}>
              <h3 style={{ color: '#29CC5E' }}>{formatLargeNumber(dashboardData?.dashbaordInfo?.total_sales)}</h3>
            </Tooltip>

            <img src={process.env.PUBLIC_URL + '/assets/images/your_balance.svg'} alt="Your Balance" />
          </div>
          <p>Your Balance</p>
        </div>
      </div>
    </>
  )
}

Widgets.propTypes = {
  dashboardData: PropTypes.object,
};

export default Widgets;