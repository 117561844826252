import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import axios from "axios";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import NewOrders from "../vendor/components/NewOrders";
import { GET_ORDERS } from "../../consts";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../components/header/Logo";
import { Col } from "react-bootstrap";
import noProfileImage from '../../../src/assets/assets/images/dummy_user3.jpg';
import Rating from "../../../src/components/product/sub-components/ProductRating";
const ComposeMessageSideBar = (props) => {

  return (
    <>
      <div className="cart-main-area">

        <div className="sidebar_detail">
          <div className='cb_dv'>
            <div className='cb_dv1'>
            {props.data && <img className='user_img' src={props.data[0].added_by.profile_image ? props.data[0].added_by.profile_image : noProfileImage} alt="User" />}
            </div>
            <div className='cb_dv2'>
              <p>{props.data && props.data[0].added_by.first_name} {props.data && props.data[0].added_by.last_name}</p>
              { props.Reviews && props.Total_Products ?
      
                <Rating ratingValue={props.Reviews/props.Total_Products} />
         
              :(<Rating ratingValue={0} />)}
            
            </div>
          </div>
        </div>

        <div className="sidebar_detail money">
          <div className="money_guarantee">
            <div className="header2_logo">
              <Logo imageUrl="" logoClass="logo" />
            </div>
            <p>Money back guarantee</p>
          </div>

          <div className='content_money'>
            <p><span className="warning_msg_span">Zourie</span> protects buyers and sellers from fraud. Please report any suspicious activity or scams. Buyer and Seller confidence is our priority, our money back guarantee offers peace of mind when scam or malicious situations impact your account.</p>
            </div>
        </div>

        <div className="sidebar_detail helpful">
          <div className="helpful_tip">
            <p>Helpful Tips</p>
          </div>

          <div className='content_helpful'>
            <p>When communication on <span className="warning_msg_span"> Zourie</span></p>
          </div>
          <div className="tips_helpful">
            <ul>
              <li>Zourie offers an easy messaging solution to allow buyers and sellers to communicate in a safe and reliable manner. </li>
              <li>Communicate only through Zourie's messaging platform. Be kind and courteous.</li>
              <li>Refrain from explicit messages that could lead to suspension. Avoid suspicious communication that could belead to scams.</li>
            </ul>
          </div>

        </div>


          
      </div>
    </>
  );
};

ComposeMessageSideBar.propTypes = {
  location: PropTypes.object,
};

export default ComposeMessageSideBar;
