import axios from "axios";
import {
    FEEDBACK_PRODUCT, ORDER_CANCEL_ITEM, RETURN_ORDER_ITEM,
    UPDATE_TRACKING_NUMBER
    ,
    ORDER_DETAIL


} from "../../consts";
import { fetchPurchaseHistory } from "./purchaseHistoryActions";

export const CANCEL_ORDER_START = "CANCEL_ORDER_START";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";
export const FEEDBACK_PRODUCT_START = "FEEDBACK_PRODUCT_START";
export const FEEDBACK_PRODUCT_SUCCESS = "FEEDBACK_PRODUCT_SUCCESS";
export const FEEDBACK_PRODUCT_FAILURE = "FEEDBACK_PRODUCT_FAILURE";
export const SET_LOADING = "SET_LOADING";
export const ORDER_DEATILS_SUCCESS = "ORDER_DEATILS_SUCCESS";


const cancelOrderItems = orderItems => ({
    type: CANCEL_ORDER_SUCCESS,
    payload: orderItems
});

const returnOrderItems = returnOrderItem => ({
    type: CANCEL_ORDER_SUCCESS,
    payload: returnOrderItem
});

const feedbackProduct = feedback => ({
    type: FEEDBACK_PRODUCT_SUCCESS,
    payload: feedback
});

const fetchOrderDetails = orderDetail => ({
    type: ORDER_DEATILS_SUCCESS,
    payload: orderDetail
});

export const orderItemCancel = (orderItemData, addToast) => {
    const token = localStorage.getItem("Token");
    return dispatch => {
        axios.put(ORDER_CANCEL_ITEM, orderItemData, {
            headers: {
                Authorization: "Token " + token
            },
        })

            .then(response => {
                dispatch(cancelOrderItems(response.data));
                if (response.status === 200) {
                    dispatch(fetchPurchaseHistory());
                    addToast && addToast("Order item cancelled successfully", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true,
                    });
                }
            })
            .catch(error => {
                console.log(error);
                addToast && addToast("Order item cancelled failure", {
                    appearance: "error",
                    autoDismiss: true,
                    pauseOnHover: true,
                });
            })
    };

};

export const orderItemReturn = (orderItemData, addToast) => {
    const token = localStorage.getItem("Token");
    return dispatch => {
        axios.post(RETURN_ORDER_ITEM, orderItemData, {
            headers: {
                Authorization: "Token " + token
            },
        })

            .then(response => {
                dispatch(returnOrderItems(response.data));
                if (response.status === 200) {
                    dispatch(fetchPurchaseHistory());
                    addToast && addToast("Order item returned successfully", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true,
                    });
                }
            })
            .catch(error => {
                if (error.response.status === 404) {
                    addToast && addToast(error.response.data.message, {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true,
                    });
                }
              
            })
    };

}

export const orderItemFeedback = (feedback, setFeedback, addToast) => {
    const token = localStorage.getItem("Token");
    return dispatch => {
        axios.post(FEEDBACK_PRODUCT + feedback.product + "/CreateReview/", feedback, {
            headers: {
                Authorization: "Token " + token
            },
        })

            .then(response => {
                dispatch(feedbackProduct(response.data));
                if (response.status === 201) {

                    

                    setFeedback({
                        user: null,
                        product: null,
                        review: null,
                        rating: null
                    })

                    addToast && addToast("Your feedback has been submitted successfully", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true,
                    });
                }
            })
            .catch(error => {
                console.log(error);
                addToast && addToast("Order item returned failure", {
                    appearance: "error",
                    autoDismiss: true,
                    pauseOnHover: true,
                });
            })
    };

}

export const updateTrackingNumber = (id, orderId, trackingNumber, setLoading, addToast) => {
    const token = localStorage.getItem("Token");
    return dispatch => {
        setLoading(true)
        axios.put(UPDATE_TRACKING_NUMBER, {
            order_item: id,
            tracking_number: trackingNumber,
            order_id: orderId
        }, {
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then((response) => {

                if (response.status == 200) {
                    addToast("Tracking Number Updated Successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    setLoading(false)
                    dispatch(
                        orderDetails(
                            orderId,
                            setLoading
                        )
                    )
                }
            })
            .catch((error) => {
                console.log(error, 'error')
                addToast("Something went wrong", {
                    appearance: "error",
                    autoDismiss: true,
                });
                setLoading(false)
            })
    }
}


export const orderDetails = (id, setLoading) => {
    const token = localStorage.getItem("Token");

    return dispatch => {
        setLoading(true)
        axios.get(ORDER_DETAIL + id + '/', {
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then((response) => {
                dispatch(fetchOrderDetails(response.data))
                setLoading(false)
            

            })
            .catch((error) => {
                console.log(error, 'error')
            
                setLoading(false)
            })
    }
}

