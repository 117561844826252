import {
    FETCH_MY_PRODUCTS,
    FETCH_MY_ORDERS,
    FETCH_MY_STATS,
    FETCH_SALES_HISTORY_SUCCESS,
    FETCH_TOTAL_SALES,
    FETCH_MARKETING_LISTINGS,
    FETCH_CANCEL_RETURNS_ORDERS
} from "../actions/dashboardActions";
import { SET_LOADING } from "../actions/subscriptionplanActions";

const initState = {
    orders: [],
    products: [],
    dashbaordInfo: null,
    saleshistory: null,
    totalSales: null,
    loading: false,
    marketingListings: null,
    cancelReturnsOrders: null
};

const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_MY_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };
            break;
        case FETCH_MY_ORDERS:
            return {
                ...state,
                orders: action.payload
            };
            break;
        case FETCH_MY_STATS:
            return {
                ...state,
                dashbaordInfo: action.payload
            };
            break;
        case FETCH_SALES_HISTORY_SUCCESS:
            return {
                ...state,
                saleshistory: action.payload
            };
            break;
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
            break;
        case FETCH_TOTAL_SALES:
            return {
                ...state,
                totalSales: action.payload
            };
            break;
        case FETCH_MARKETING_LISTINGS:
            return {
                ...state,
                marketingListings: action.payload
            };
            break;
        case FETCH_CANCEL_RETURNS_ORDERS:
            return {
                ...state,
                cancelReturnsOrders: action.payload
            };
            break;
        default:
            return state
    }
};

export default dashboardReducer;