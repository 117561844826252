
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_PROFILE_FAILURE = "EDIT_USER_FAILURE";
export const USER_ADDRESS = "USER_ADDRESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const SAVE_ADDRESS_SUCCESS = "SAVE_ADDRESS_SUCCESS";
export const SAVE_ADDRESS_FAILURE = "SAVE_ADDRESS_FAILURE";
export const SAVE_BANK_DETAILS_SUCCESS = "SAVE_BANK_DETAILS_SUCCESS";
export const SAVE_BANK_DETAILS_FAILURE = "SAVE_BANK_DETAILS_FAILURE";
export const UPDATE_BANK_DETAILS_SUCCESS = "UPDATE_BANK_DETAILS_SUCCESS";
export const UPDATE_BANK_DETAILS_FAILURE = "UPDATE_BANK_DETAILS_FAILURE";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";


export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const RESET_REGISTER_USER = "RESET_REGISTER_USER";

export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_FAILURE = "NEW_PASSWORD_FAILURE";

export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAILURE = "CONFIRM_PASSWORD_FAILURE";

export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_FAILURE = "CHANGE_EMAIL_FAILURE";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const VERIFY_STRIPE_ACCOUNT_SUCCESS = "VERIFY_STRIPE_ACCOUNT_SUCCESS";
export const VERIFY_STRIPE_ACCOUNT_FAILURE = "VERIFY_STRIPE_ACCOUNT_FAILURE";

