import React from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Sidebar from "../vendor/Sidebar";
import OrderDetails from "./OrderDetails";


const OrderDetailsPage = () => {
  return (
    <>
      <MetaTags>
        <title>Zourie | Manage Orders</title>
        <meta
          name="description"
          content="Compare page of Zourie react minimalist eCommerce template."
        />
      </MetaTags>
      <LayoutOne headerTop="visible" header3="hide">
        <section className="information_panels" style={{background:"#FAFAFA"}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12 info_pnl_body">
                <div className="info_pnl_body_dv no_info_pnl_body_dv">
                  <div className="row">
                       <OrderDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutOne>
    </>
  );
};

OrderDetailsPage.propTypes = {};

export default OrderDetailsPage;

