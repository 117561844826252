import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { VERIFY_EMAIL_TOKEN } from "../../consts";


import Emailicon from '../../assets/assets/images/thanks.png'
import Loader from "./Loader";






const VerifyEmail = (user) => {


  const [res, setRes] = useState(null);
  const [loader, setLoader] = useState(false);

  const getVerifyToken1 = window.location.pathname.split('/');
  const getVerifyToken2 = getVerifyToken1[2];





  useEffect(() => {
    verify(getVerifyToken2);
  }, [getVerifyToken2])


  const verify = (getVerifyToken2) => {
    setLoader(true);
    const data = {
      token: getVerifyToken2
    }
    axios.post(VERIFY_EMAIL_TOKEN + getVerifyToken2 + "/", data)
      .then(response => {
        if (response.status === 200) {
          setRes(response.status);
          setLoader(false);

        }
      })
      .catch(err => {
        setLoader(false);
      }
      )
  }



  return (
    <Fragment>
      <>
        <MetaTags>
          <title>Zourie | Verify Email</title>
          <meta
            name="description" />
        </MetaTags>
        {loader &&
          <Loader />
        }
        <LayoutOne headerTop="visible">
          {/* breadcrumb */}
          {res ? 
          <div class="thankyoucontent">
            <div class="wrapper-1">
              <div class="wrapper-2">
                <img src={Emailicon} alt='' style={{display:"inline-block"}}/>
                <h1>
                  Your Email has been Verified
                </h1>
            
                </div>
              </div>
            </div>
            : (
              <div style={{ minHeight: '300px' }}></div>
            )
          }
        </LayoutOne>
      </>
    </Fragment>
  );
};


export default VerifyEmail;
