import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { sendMessage } from "../../redux/apicalls";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux'
import './../../../src/Styles.css'
import { useHistory } from 'react-router-dom'


const MessageDetail = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { addToast } = useToasts();
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const id = props.data && props.data[0].added_by.id;
        const data = {"seller_id": id,"message" : message}
        dispatch(sendMessage(data, addToast, history));
     
    }

    const handleCancel = () => {
        setMessage("");
    }
   
  return (
    <>
        <div className="message-main-area">
            <div className="content">
             
                <form onSubmit={handleSubmit}> 
                <div className="col-12 msg_dv_content">
                    <div className="msg_content">
                        <InputGroup>
                            <Form.Control as="textarea" 
                            placeholder="Write something"
                            aria-label="With textarea"
                            value={message}
                            onChange={(e)=>setMessage(e.target.value)}
                            maxLength={2000}
                            rows="10"
                            required
                            />
                        </InputGroup>
                        <div className="d-flex content_div">
                            <p className="head_content">In order to maintain a safe and credible account on Zourie, we ask that you do not exchanging contact information. Exchanging contact information is prohibited and could lead to suspension or removal from the Zourie Marketplace.
                            </p>
                        <span className="content_limit">{message?.length}/2000</span>
                        
                        </div>
                    </div>
                </div> 
                <div className="buttons_div">  
                    <Button className="send_msg" variant="primary" type="submit">Send Message</Button>
                    <Button className="cancel_msg" variant="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </div> 
                </form>
             
            </div> 
        </div>
    </>
  );
};

MessageDetail.propTypes = {
  location: PropTypes.object,
};



export default MessageDetail;
