import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { UserRegister  } from "../../redux/actions/Login-Register";
import { connect, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import Loader from "./Loader";



// import setActiveKey




const mapStateToProps = (state) => {
  return {
    products: state.productData.products,
    user: state.user
  };
};



const Register = (user) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirm_pass, setConfirmPass] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [isVendor, setIsVendor] = useState("");


  const { addToast } = useToasts();


  const loader = useSelector((state) => state.userData.isLoading)



  const handleSubmitRegister = async (e) => {
    dispatch(UserRegister(email, password, confirm_pass, first_name, last_name, isVendor, user.history, addToast));
    setEmail("");
    setPassword("");
    setConfirmPass("");
    setLastName("");
    setFirstName("");


  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    
    handleSubmitRegister();
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Register</title>
        <meta
          name="description"
        />
      </MetaTags>

      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
        <BreadcrumbsItem to={process.env.PUBLIC_URL + "/register"}>
          Register
        </BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {loader && <Loader />}

      

        <div className="login-register-area pt-40 pb-40 bg_gray2">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ml-auto mr-auto ">
              <h1 className="login-register-heading">Register</h1>
                <div className="login-register-wrapper login-register-bg">
               


                        <div className="login-form-container">
                          <div className="login-register-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="valid_error_dv">
                                <div className="myform_dv">
                                  <label>Email</label>
                                    <div className="myform_icon">
                                  <input
                                    name="user-email"
                                    placeholder="Email"
                                    type="email"
                                    value={email}
                            
                                    maxLength="50"
                                    {...register("email", {
                                      required: true,
                                      value: email,
                                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                      onChange: (e) => {
                                        const lowercaseEmail = e.target.value ? e.target.value.toLowerCase() : '';
                                        setEmail(lowercaseEmail)},
                                    })}
                                    className={errors.email ? 'myform_field valid_error_inpt' : 'myform_field'}
                                  />
                                    <img className="icon" alt="" src={process.env.PUBLIC_URL + '/assets/images/icon_email.svg'}/>
                                  </div>
                                </div>
                                {errors.email && <p className="valid_error_txt">Email is required</p>}
                              </div>
                              <div className="valid_error_dv">
                                <div className="myform_dv">
                                  <label>Password</label>
                                  <div className="myform_icon">
                                    <input
                                      type="password"
                                      name="password"
                                      placeholder="Password"
                                      value={password}                                   
                                      maxLength="255"
                                      {...register("password", {
                                        required: true,
                                        value: password,
                                        onChange: (e) => setPassword((e.target.value).replace(/\s+/g, '')),
                                      })}
                                      className={errors.password ? 'myform_field valid_error_inpt' : 'myform_field'}
                                    />
                                    <img className="icon" alt="" src={process.env.PUBLIC_URL + '/assets/images/password.svg'}/>
                                  </div>
                                </div>
                                {errors.password && <p className="valid_error_txt">Password is required</p>}
                              </div>
                              <div className="valid_error_dv">
                                <div className="myform_dv">
                                  <label>Confirm Password</label>
                                  <div className="myform_icon">
                                    <input
                                      type="password"
                                      name="confirm_pass"
                                      placeholder="Confirm Password"
                                      value={confirm_pass}
                                      maxLength="255"
                                      {...register("confirm_pass", {
                                        required: true,
                                        value: confirm_pass,
                                        onChange: (e) => setConfirmPass((e.target.value).replace(/\s+/g, '')  ),
                                        validate: (value) => value === password || "The passwords do not match",
                                      })}
                                      className={errors.confirm_pass ? 'myform_field valid_error_inpt' : 'myform_field'}
                                    />
                                    <img className="icon" alt="" src={process.env.PUBLIC_URL + '/assets/images/password.svg'}/>
                                  </div>
                                </div>
                                {errors.confirm_pass && <p className="valid_error_txt">
                                  {errors.confirm_pass.message}
                                  </p>}
                              </div>
                              <div className="valid_error_dv">
                                <div className="myform_dv">
                                  <label>First Name</label>
                                  <div className="myform_icon">
                                    <input
                                      type="text"
                                      name="first-name"
                                      placeholder="First Name"
                                      value={first_name}
                                      onChange={e => setFirstName(e.target.value)}
                                      maxLength="255"
                                      {...register("first_name", {
                                        required: true,
                                        value: first_name,
                                        onChange: (e) => setFirstName((e.target.value).replace(/^\s+/, '')   ),
                                      })}
                                      className={errors.first_name ? 'myform_field valid_error_inpt' : 'myform_field'}
                                    />
                                    <img className="icon" alt="" src={process.env.PUBLIC_URL + '/assets/images/icon_name.svg'}/>
                                  </div>
                                </div>
                                {errors.first_name && <p className="valid_error_txt">First Name is required</p>}
                              </div>
                              <div className="valid_error_dv">
                                <div className="myform_dv">
                                  <label>Last Name</label>
                                  <div className="myform_icon">
                                    <input
                                      type="text"
                                      name="last-name"
                                      placeholder="Last Name"
                                      value={last_name}
                                      onChange={e => setLastName(e.target.value)}
                                      maxLength="255"
                                      {...register("last_name", {
                                        required: true,
                                        value: last_name,
                                        onChange: (e) => setLastName((e.target.value).replace(/^\s+/, '')),
                                      })}
                                      className={errors.last_name ? 'myform_field valid_error_inpt' : 'myform_field'}
                                    />
                                    <img className="icon" alt="" src={process.env.PUBLIC_URL + '/assets/images/icon_name.svg'}/>
                                  </div>
                                </div>
                                {errors.last_name && <p className="valid_error_txt">last Name is required</p>}
                              </div>
                              <div className="button-box">
                                
                                <div className="mBttn">
                                  <button type="Submit" onClick={handleSubmit}>
                                    <span>Register</span>
                                  </button>
                                </div>
                              </div>

                            </form>
                          </div>
                        </div>
                   
                </div>
              </div>
            </div>
          </div>
        </div>

      </LayoutOne>
    </Fragment>
  );
};

Register.propTypes = {
  location: PropTypes.object
};


export default connect(mapStateToProps, null)(Register);
