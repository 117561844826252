import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import {fetchBanners} from "./redux/actions/bannerActions";
import {fetchAuctions} from "./redux/actions/auctionActions";
import {fetchTodayTopPick} from "./redux/actions/topPickProductActions";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { createTheme } from '@mui/material/styles';
import { composeWithDevTools } from "redux-devtools-extension";
import { fetchTestimonials } from "./redux/actions/testimonialActions";
import { fetchCategories } from "./redux/actions/categoryActions";
import { fetchBestSellersProducts, fetchNewArrivals } from "./redux/actions/newArrivalsActions";
import { fetchFooterData, fetchVendors } from "./redux/actions/footerActions";
import { ThemeProvider } from "@mui/material";

export const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);


const theme = createTheme({
  // Define your color palette
  palette: {
    primary: {
      main: '#388087', // Replace with your primary color
    },
    secondary: {
      main: '#F50057', // Replace with your secondary color
    },
  },
  
  // Define your typography
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
  },

  // Define spacing and other global styles
  spacing: 8,
});


// fetch products from json file
store.dispatch(fetchBestSellersProducts());
store.dispatch(fetchTodayTopPick());
// store.dispatch(fetchBlogs())
store.dispatch(fetchTestimonials());
store.dispatch(fetchBanners());
store.dispatch(fetchAuctions());
store.dispatch(fetchCategories());
store.dispatch(fetchNewArrivals());
// store.dispatch(fetchfaqs());
store.dispatch(fetchFooterData());
store.dispatch(fetchVendors());




ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Provider store={store}>
    <App />
  </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);


serviceWorker.unregister();
