import axios from "axios";
import { REPORT_VENDOR } from "../../consts";

export const REPORT_VENDOR_START = "REPORT_VENDOR_START";
export const REPORT_VENDOR_FAILURE = "REPORT_VENDOR_FAILURE";
export const REPORT_VENDOR_SUCCESS = "REPORT_VENDOR_SUCCESS";


const reportVendorSuccess = vendor => ({
    type: REPORT_VENDOR_SUCCESS,
    payload: vendor
});

    
export const reportVendors = vendor => {
    return dispatch => {
        axios.post(REPORT_VENDOR)
        .then(response => {
            dispatch(reportVendorSuccess(response.data));
        })
        .catch(error => {
            console.log(error);
        })
    };

};