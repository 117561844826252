import { FETCH_ALL_MESSAGSES_SUCCESS } from "../actions/getMessagesActions";


const initState = {
  messagesData: []
};

const messagesReducer = (state = initState, action) => {
  if (action.type === FETCH_ALL_MESSAGSES_SUCCESS) {
    return {
      ...state,
      messagesData: action.payload
    };
  }

  return state;
};

export default messagesReducer;