import axios from "axios";
import { GET_ALL_MESSAGES } from "../../consts";

export const FETCH_ALL_MESSAGSES_SUCCESS = "FETCH_ALL_MESSAGSES_SUCCESS";


const fetchAllMessagesSuccess = data => ({
    type: FETCH_ALL_MESSAGSES_SUCCESS,
    payload: data
});

    
export const fetchMessagesData = () => {
    return dispatch => {
      const token = localStorage.getItem("Token");
        axios.get(GET_ALL_MESSAGES , { headers: { Authorization: "Token " + token } })
        .then(response => {
            dispatch(fetchAllMessagesSuccess(response.data));
        })
        .catch(error => {
            console.log(error);
        })
    };

};