import React from 'react';
const SalesHistoryBtn = [
  {
    "labelKey": "Last 30 days",
    "value": "Last 30 days",
  },
  {
    "labelKey": "Last 90 days",
    "value": "Last 90 days",
  },
  {
    "labelKey": "1 year",
    "value": "1 year",
    "isSelected": 'true'
  }
]
function SellerPerformance() {
  const SellerPerformanceData = [
    {
      month: "Jan",
      Transaction_Defect_Rate: 150,
      Late_Shipping_Rate: 10,
      Upload_Time_And_Validated: 70,
    },
    {
      month: "Feb",
      Transaction_Defect_Rate: 170,
      Late_Shipping_Rate: 90,
      Upload_Time_And_Validated: 50,
    },
    {
      month: "Mar",
      Transaction_Defect_Rate: 10,
      Late_Shipping_Rate: 50,
      Upload_Time_And_Validated: 70,
    },
    {
      month: "Apr",
      Transaction_Defect_Rate: 120,
      Late_Shipping_Rate: 60,
      Upload_Time_And_Validated: 100,
    },
    {
      month: "May",
      Transaction_Defect_Rate: 50,
      Late_Shipping_Rate: 95,
      Upload_Time_And_Validated: 125,
    },
    {
      month: "Jun",
      Transaction_Defect_Rate: 50,
      Late_Shipping_Rate: 110,
      Upload_Time_And_Validated: 130,
    },
    {
      month: "Jul",
      Transaction_Defect_Rate: 140,
      Late_Shipping_Rate: 90,
      Upload_Time_And_Validated: 80,
    },
    {
      month: "Aug",
      Transaction_Defect_Rate: 110,
      Late_Shipping_Rate: 50,
      Upload_Time_And_Validated: 70,
    },
    {
      month: "Sep",
      Transaction_Defect_Rate: 35,
      Late_Shipping_Rate: 85,
      Upload_Time_And_Validated: 35,
    },
    {
      month: "Oct",
      Transaction_Defect_Rate: 60,
      Late_Shipping_Rate: 40,
      Upload_Time_And_Validated: 30,
    },
    {
      month: "Nov",
      Transaction_Defect_Rate: 40,
      Late_Shipping_Rate: 70,
      Transaction_Defect_Rate: 10,
    },
    {
      month: "Dec",
      Transaction_Defect_Rate: 95,
      Late_Shipping_Rate: 85,
      Upload_Time_And_Validated: 75,
    },
  ];

  return (
    <>
    
    </>
  )
}

export default SellerPerformance