import axios from 'axios';
import { GET_PRODUCTS, PAYMENT_GATEWAY, SAVE_PRODUCT_IMAGE, PRODUCT_PAYMENT_GATEWAY, UPLOAD_TO_GCP } from "../../consts";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const NEW_ARRIVALS = "NEW_ARRIVALS";
export const BEST_SELLERS = "BEST_SELLERS";
export const SAVED_PRODUCT_SUCCESS = "SAVED_PRODUCT_SUCCESS";
export const PRODUCT_PAYMENT = "PRODUCT_PAYMENT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const SUCCESS_PRODUCT_IMAGE = "SUCCESS_PRODUCT_IMAGE";

export const SET_PRODUCT_FILTER = "SET_PRODUCT_FILTER";
export const PRODUCT_SEARCH_RESULTS = "PRODUCT_SEARCH_RESULTS";
export const UPLOAD_RESOURCES = "UPLOAD_RESOURCES";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

const saveProductsSuccess = product => ({
  type: SAVED_PRODUCT_SUCCESS,
  payload: product
});

const saveProductImageSuccess = productImage => ({
  type: SUCCESS_PRODUCT_IMAGE,
  payload: productImage
});

const updateProductSuccess = product => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product
});

const saveProductPaymentSuccess = productPayment => ({
  type: PRODUCT_PAYMENT,
  payload: productPayment
});

const savePaymentSuccess = payment => ({
  type: PAYMENT_GATEWAY,
  payload: payment
});

const proListingResourceUploaderSuccess = proListingResourceUploader => ({
  type: UPLOAD_RESOURCES,
  payload: proListingResourceUploader
});


var productImageId = [];

export const fetchProducts = () => {
  return dispatch => {
    axios.get(GET_PRODUCTS, {
      params: {
        limit: 10
      }
    }).then(response => {
      dispatch(fetchProductsSuccess(response.data));
    })
      .catch(error => {
        console.log(error);
      });
  };
};



export const saveProducts = (requestData, published, addToast, history) => {
  const token = localStorage.getItem("Token");
  requestData.product_image = productImageId;
  requestData.published = published;

  if (requestData.product_type == "Auction") {
    requestData.stock = 1;
  }


  return dispatch => {
    axios.post(GET_PRODUCTS, requestData, { headers: { Authorization: "Token " + token } })
      .then(response => {
        dispatch(saveProductsSuccess(response.data));
        if (response.status === 201) {
          history.push('/vendor')
          addToast && addToast("Product added successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          productImageId = [];
        }
      })
      .catch(error => {
        if (error?.response?.data) {
          const errorData = error.response.data;

          Object.keys(errorData).forEach(field => {
            const errorMessages = errorData[field];

            if (Array.isArray(errorMessages)) {
              const formattedErrorMessage = errorMessages.join(", ");
              const fieldName = field.replaceAll("_", " ");

              addToast && addToast(`${fieldName}: ${formattedErrorMessage}`, {
                appearance: "error",
                autoDismiss: true,
                pauseOnHover: true,
              });
            } else {
              addToast && addToast(error?.response?.data[0], {
                appearance: "error",
                autoDismiss: true,
                pauseOnHover: true,
              });
            }
          });
        }
      });
  };
}


export const saveProductImage = (productImage, setCheckImage, setLoading, addToast) => {

  var imagesId;

  const token = localStorage.getItem("Token");


  let formData = new FormData();

  formData.append("upload_image", productImage);

  return dispatch => {

    setLoading(true);
    axios.post(SAVE_PRODUCT_IMAGE, formData, {
      headers: {
        Authorization: "Token " + token,
      }
    })
      .then(response => {
        dispatch(saveProductImageSuccess(response.data),
        );
        if (response.status === 201) {
          imagesId = response.data;
          for (let i = 0; i < imagesId.length; i++) {
            productImageId.push(imagesId[i].id);
          }
          setCheckImage(true);

          setLoading(false)
          addToast && addToast("Product image added successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });


          return true;
        }
        dispatch({
          type: SUCCESS_PRODUCT_IMAGE,
          payload: null,
        });

      })

      .catch(error => {
        setLoading(false)
        const responseErrors = error.response.data;
        if (Array.isArray(responseErrors) && responseErrors.length > 0) {
          responseErrors.forEach(errorMessage => {
            if (errorMessage && typeof errorMessage === "object") {
              const errorValue = Object.values(errorMessage)[0];
              if (errorValue) {
                addToast && addToast(errorValue + " Please upload a valid image extension with .jpg, .png, .jpeg and delete the current image.", {
                  appearance: "error",
                  autoDismiss: true,
                  pauseOnHover: true,
                });
              }
            }
          });
        }

      });
  };

}


export const saveProductPayment = ({ ...data }, payment_method, addToast) => {
  const token = localStorage.getItem("Token");


  return dispatch => {

    axios.post(PRODUCT_PAYMENT_GATEWAY, { ...data }, payment_method, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(response => {
        dispatch(saveProductPaymentSuccess(response.data));
        if (response.status === 201) {
          return true;
        }
      })

      .catch(error => {
        console.log(error);
      }
      );
  };
}


export const PaymentSave = (cartItems, addToast) => {
  const token = localStorage.getItem("Token");

  return dispatch => {
    axios.post(PAYMENT_GATEWAY, cartItems, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(response => {
        dispatch(savePaymentSuccess(response.data));
        if (response.status === 201) {
          addToast && addToast("Payment added successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
        }
      }
      )
      .catch(error => {
        console.log(error);
        addToast && addToast("Payment added failure", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: true,
        });
      }
      );
  };
}


export const updateProductProListing = (
  requestData,
  productImage,
  addToast,
  history,
  parsed
) => {

  const data = {
    "product_title": requestData.productTitle,
    "sub_title": requestData.productSubtitle,
    "description": requestData.productDescription,
    "category": requestData.productCategory,
    "upc": requestData.upc,
    "condition": requestData.condition,
    "condition_description": requestData.conditionDescription,
    "product_image": productImage.map((item) => item.uid),
    "duration": requestData.duration,
    "price": requestData.titlePrice,
    "auction_start_date": requestData.auctionStartDate,
    "auction_end_date": requestData.auctionEndDate,
    "actual_price": requestData.actualPrice,
    "stock": requestData.stock,
    "domestic_shipping": requestData.domesticShipping,
    "services": requestData.services,
    "shipping_cost": requestData.serviceCost,
    "handling_time": requestData.handlingTime,
    "marketing_option": requestData.marketingOption,
    "marketing_option_per": requestData.marketing_option_per,
    "coupon": requestData.createACoupon,
    "promote_listing": requestData.promotedlisting,
    "product_type": requestData.productType,
    "returnable": requestData.returnable,
    "keywords": requestData.keywords,
    "bullet_points": requestData.bullet_points,
    "manufacturer": requestData.manufacturer,
    "product_dimensions": requestData.product_dimensions,
    "item_weight": requestData.item_weight,
    "country_of_origin": requestData.country_of_origin,
    "item_model_number": requestData.item_model_number,
    "additional_specifications": requestData.additional_specifications,
    "variation": requestData.variations,
    "assembly_required": requestData.assembly_required,
    "warranty_description": requestData.warranty_description,
    "resourcess": requestData.resources,
    "offer_discount": requestData.offer_discount,
    "subtitle_checked": requestData.subtitle_checked,
    "listing_start_opt": requestData.listing_start_opt,
    "listing_start_time": requestData.listing_start_time,
    "fixed_buy_price": requestData.fixed_buy_price,
    "auction_buy_price": requestData.auction_buy_price,
    "shipping_calculation_type": requestData.shipping_calculation_type,
    "free_shipping": requestData.isFreeShipping,
    "fees": requestData.fees,
    "published": requestData.published,
    "publish_status": requestData.publish_status,
    "proimage": requestData.proimage,
  }



  const token = localStorage.getItem("Token");

  return dispatch => {
    axios.put(GET_PRODUCTS + parsed + "/", data, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(response => {
        dispatch(updateProductSuccess(response.data));
        if (response.status === 200) {
          addToast && addToast("Product updated successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          history.push('/vendor')
        }
      }
      )
      .catch(error => {
        if (error?.response?.data) {
          const errorData = error.response.data;

          Object.keys(errorData).forEach(field => {
            const errorMessages = errorData[field];

            if (Array.isArray(errorMessages)) {
              const formattedErrorMessage = errorMessages.join(", ");
              const fieldName = field.replaceAll("_", " ");

              addToast && addToast(`${fieldName}: ${formattedErrorMessage}`, {
                appearance: "error",
                autoDismiss: true,
                pauseOnHover: true,
              });
            } else {
              addToast && addToast(error?.response?.data[0], {
                appearance: "error",
                autoDismiss: true,
                pauseOnHover: true,
              });
            }
          });
        }
      }
      );
  };
}



export const updateProduct = (productTitle,
  productSubtitle,
  productDescription, productCategory, upc, condition, conditionDescription, productImage,
  duration, price, auctionStartDate, auctionEndDate, date, actualPrice,
  quantity, domesticShipping, services, serviceCost, handlingTime, marketingOption, marketingOptionCost, createACoupon,
  promoteListing, productType, returnable, keywords, addToast, history,
  parsed) => {
  const data = {
    "product_title": productTitle,
    "sub_title": productSubtitle,
    "description": productDescription,
    "category": productCategory,
    "upc": upc,
    "condition": condition,
    "condition_description": conditionDescription,
    "product_image": productImage.map((item) => item.uid),
    "duration": duration,
    "auction_start_price": price,
    "auction_start_date": auctionStartDate,
    "auction_end_date": auctionEndDate,
    "date": date,
    "actual_price": actualPrice,
    "stock": quantity,
    "domestic_shipping": domesticShipping,
    "services": services,
    "shipping_cost": serviceCost,
    "handling_time": handlingTime,
    "marketing_option": marketingOption,
    "marketing_option_per": marketingOptionCost,
    "coupon": createACoupon,
    "promote_listing": promoteListing,
    "product_type": productType,
    "returnable": returnable,
    "keywords": keywords
  }

  const token = localStorage.getItem("Token");

  return dispatch => {
    axios.put(GET_PRODUCTS + parsed + "/", data, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(response => {
        dispatch(updateProductSuccess(response.data));
        if (response.status === 200) {
          addToast && addToast("Product updated successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
          history.push('/vendor')
        }
      }
      )
      .catch(error => {
        if (error?.response?.data) {
          const errorData = error.response.data;

          Object.keys(errorData).forEach(field => {
            const errorMessages = errorData[field];

            if (Array.isArray(errorMessages)) {
              const formattedErrorMessage = errorMessages.join(", ");
              const fieldName = field.replaceAll("_", " ");

              addToast && addToast(`${fieldName}: ${formattedErrorMessage}`, {
                appearance: "error",
                autoDismiss: true,
                pauseOnHover: true,
              });
            } else {
              addToast && addToast(error?.response?.data[0], {
                appearance: "error",
                autoDismiss: true,
                pauseOnHover: true,
              });
            }
          });
        }
      }
      );
  };
}

export const filterProducts = (data) => {
  return dispatch => {
    dispatch({
      type: SET_PRODUCT_FILTER,
      payload: data
    });
  } 
}


export const searchProducts = (searchfilter) => {

  return dispatch => {
    var url = GET_PRODUCTS;
    var query = []
    if (!searchfilter) searchfilter = {}

    if (searchfilter.category) {
      query.push("category=" + searchfilter.category)
    }
    if (searchfilter.product_type) {
      if (searchfilter.product_type !== "All Listing") {
        query.push("product_type=" + searchfilter.product_type)
      }
    }
    if (searchfilter.product_title__icontains !== undefined && searchfilter.product_title__icontains !== null) {
      query.push("product_title__icontains=" + searchfilter.product_title__icontains)
    } else {
      query.push("product_title__icontains=")
    }
    if (searchfilter.service_cost) {
      query.push("service_cost=" + searchfilter.service_cost)
    }
    if (searchfilter.condition) {
      query.push("condition=" + searchfilter.condition)
    }
    if (searchfilter.actual_price__gte) {
      query.push("actual_price__gte=" + searchfilter.actual_price__gte)
    }
    if (searchfilter.actual_price__lte) {
      query.push("actual_price__lte=" + searchfilter.actual_price__lte)
    }
    if (searchfilter.num_pages) {
      query.push("limit=" + searchfilter.num_pages)
    }
    if (searchfilter.page) {
      query.push("page=" + searchfilter.page)
    }
    if (query.length > 0) {
      url = url + "?" + query.join("&")
    }

    axios.get(url)
      .then(response => {
        dispatch({
          type: PRODUCT_SEARCH_RESULTS,
          payload: {
            data: response.data.results,
            total: response.data.count,
            num_pages: response.data.num_pages,
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};



export const proListingResourceUploader = (file, addToast) => {
  const token = localStorage.getItem("Token");
  const formData = new FormData();
  formData.append("file", file);
  return dispatch => {
    axios.post(UPLOAD_TO_GCP, file, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(response => {
        dispatch(proListingResourceUploaderSuccess(response.data));
        if (response.status === 201) {
          addToast && addToast("File uploaded successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
        }
      })
      .catch(error => {
        console.log(error);
        addToast && addToast("File uploaded failure", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: true,
        });
      });
  };
}