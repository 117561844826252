import React, { useState, useEffect } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatIcon from '../../assets/assets/images/chat.svg';
import productDetails from '../../assets/assets/images/product-order-deatils.png'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LeaveFeedbackHistory from "../vendor/components/LeaveFeedbackHistory";
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ProductImage from '../../assets/assets/images/shoe.png'
import { updateTrackingNumber } from '../../redux/actions/orderActions';
import { useToasts } from "react-toast-notifications";
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { UPDATE_ORDER_STATUS_BY_VENDOR } from '../../consts';
import { orderDetails } from "../../redux/actions/orderActions";
import Loader from "../../pages/other/Loader";



const OrderDetailsSeller = () => {
  const msfCount = 4;
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const orderDeatilsData = useSelector((state) => state.ordersData.orderDetailsData)
  const [open2, setOpen2] = useState(false);
  const [inFeedback, setInFeedback] = useState(null);
  const [shippingPriceTotal, setShippingPriceTotal] = useState(0);
  const [textToCopy, setTextToCopy] = useState(orderDeatilsData?.shipping_address?.address,
    orderDeatilsData?.shipping_address?.city,
    orderDeatilsData?.shipping_address?.state,
    orderDeatilsData?.shipping_address?.zip_code
  );
  const [copySuccess, setCopySuccess] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [orderTotalData, setOrderTotalData] = useState({
    itemSubtotal: 0,
    shippingTotal: 0,
    vendorCommission: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedItemStatus, setSelectedItemStatus] = useState('');
  const [trackingNumberEdit, setTrackingNumberEdit] = useState(true);


  let items = orderDeatilsData?.order_item?.map((item, i) => {
    return item
  })





  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Order Details',
  });

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  useEffect(() => {
    const inputDateStr = orderDeatilsData.created_at;

    const inputDate = new Date(inputDateStr);

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const outputDateStr = inputDate.toLocaleDateString('en-US', options);

    setOrderTotalData({
      ...orderTotalData,
      itemSubtotal: items?.reduce((acc, item) => acc + item.unit_price, 0),
      shippingTotal: items?.reduce((acc, item) => acc + item.shipping_price, 0),
      vendorCommission: items?.reduce((acc, item) => acc + item.vendor_commission, 0),
      orderDate: outputDateStr,
    });

  }, [orderDeatilsData]);

  const handleUpdateTrackingNumber = (id, orderId) => {
    if (!trackingNumber) {
      addToast("Please enter tracking number", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      dispatch(updateTrackingNumber(
        id,
        orderId,
        trackingNumber,
        setLoading,
        addToast
      ))
    }
  }




  const orderStatusUpdate = async (data) => {
    const token = localStorage.getItem("Token");
    await axios.put(UPDATE_ORDER_STATUS_BY_VENDOR, data, {
      headers: {
        Authorization: "Token " + token
      },
    }).then((response) => {


      if (response.status == 200) {

        addToast(response.data.message, {
          appearance: "success",
          autoDismiss: true,
        })

        dispatch(
          orderDetails(
            data.order_id,
            setLoading
          )
        )
      }


    }).catch((error) => {
      console.log(error, 'error')
    })
  };



  return (

    <div className="row" ref={componentRef}>
      {loading && <Loader />}

      <div className="col-xl-12">

        <div className="row">
          <div className="col-12">
            <div className='order-details-header'>
              <ArrowBackIcon onClick={() => history.push('/vendor-orders')} /> <p>Order Details</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className='order-deatils'>
            
              <div className='shipping-address-deatils'>

                <div className='shipping-address-heading'>
                  <h2>Shipping</h2>
                </div>


                <div className='row'>

                  <div className="col-xl-4 shipping-address-seller">
                    <p>Ship to

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCopyClick()}
                      ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g clip-path="url(#clip0_176_3238)">
                            <path d="M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z" stroke="#388087" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8" stroke="#388087" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_176_3238">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </p>

                    <p>{textToCopy != undefined || textToCopy != null ? textToCopy : "-"}</p>
                    <p>Phone</p>
                    <p>+ {orderDeatilsData?.phone_number}</p>

                  </div>

                  <div className="col-xl-4 tracking-id">
                  
                  </div>

                  <div className="col-xl-4">
                    <div className='buyer-details'>
                      <ul>
                        <li>
                          <p>Order No:</p>
                          <span>{orderDeatilsData.id}</span>
                        </li>
                        <li>
                          <p>Date Sold:</p>
                          <span>{orderTotalData.orderDate}</span>
                        </li>
                        <li>
                          <p>Date buyer paid:</p>
                          <span>{orderTotalData.orderDate}</span>
                        </li>
                      </ul>
                      <button>Contact Buyer</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">

          <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12">



            {
              items?.map((item, i) => {

                const handleStatusClick = (newStatus) => {
                  orderStatusUpdate({
                    orderitem_id: item?.id,
                    item_status: newStatus,
                    order_item: item?.id,
                    order_id: orderDeatilsData?.id,
                  });
                  setSelectedItemStatus(newStatus);
                };

                return (
                  <div key={i} className='order-item-details'>
                    <div className='d-flex justify-content-between'>
                      <h1>Item:{i + 1}</h1>
                      <Dropdown className='order-status'>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-btn'>
                          {item?.item_status ? item?.item_status : "Order Item Status"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {['InOrder', 'Received', 'Delivered', 'Shipped', 'Cancelled', 'Returned'].map((status) => (
                            <Dropdown.Item
                              key={status}
                              onClick={() => handleStatusClick(status)}
                              style={{
                                backgroundColor: selectedItemStatus === status ? '#388087' : '' ||
                                  item?.item_status === status ? '#388087' : '',
                                color: selectedItemStatus === status ? '#fff' : ''
                                  || item?.item_status === status ? '#fff' : '',
                              }}
                            >
                              {status}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className='order-deatils-seller-product'>
                      <div className='order-deatils-seller-product-image'>
                        <img src={item?.product?.product_image[0]?.upload_image} alt="" />
                      </div>
                      <p>
                        <span>{item.product.product_title}</span>
                        <span>Item ID :{item.id}</span>
                        <span className='d-flex align-items-center'>Tracking :
                          <input
                            type="text"
                            placeholder="Enter Tracking Number"
                            className='tracking-input'
                            defaultValue={item?.tracking_number}
                            maxLength={15}
                            onChange={(e) => {
                              const sanitizedValue =  e.target.value.replace(/[^0-9]/g, '')
                              setTrackingNumber(sanitizedValue)
                            }}
                          />
                          <Tooltip title="Update">
                            <DoneIcon
                              onClick={() => handleUpdateTrackingNumber(item?.id, item.order_id)}
                              className='done-icon'
                            />
                          </Tooltip>
                        </span>
                      </p>
                      <p>
                        <span>Price</span>
                        <span>${item.unit_price}</span>
                      </p>
                    </div>
                  </div>
                );
              })
            }

          </div>

          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">

            <div className='buyer-paid'>
              <h1>What your buyer paid</h1>
              <ul>
                <li>
                  <p>Item subtotal</p>
                  <span>${orderTotalData.itemSubtotal}</span>
                </li>
                <li>
                  <p>Shipping</p>
                  <span>${orderTotalData.shippingTotal}</span>
                </li>
                <li>
                  <p>Order total</p>
                  <span>${orderTotalData.itemSubtotal + orderTotalData.shippingTotal}</span>
                </li>
              </ul>


            </div>

            <div className='seller-earned'>

              <h1>What you earned</h1>

              <ul>
                <li>
                  <p>Order total</p>
                  <span>${orderTotalData?.itemSubtotal + orderTotalData?.shippingTotal}</span>
                </li>

                <li style={{ display: "block" }}>
                  <p>Selling cost</p>

                  <ul className='earned-list'>
                    <li>
                      <p>Commission fee</p>
                      <span>Fixed fee 9.9%</span>
                    </li>
                    <li>
                      <p>Listing Fees</p>
                      <span>Fixed fee $0.19</span>
                    </li>
                  </ul>

                </li>



                <li>
                  <p>Order Earnings</p>
                  <span>${orderTotalData?.vendorCommission?.toFixed(2)}</span>
                </li>
              </ul>


            </div>



          </div>

        </div>

      </div>

    </div >

  )
}

export default OrderDetailsSeller