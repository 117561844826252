import {FETCH_BLOGS_SUCCESS, FETCH_BLOG_SUCCESS} from "../actions/blogActions";

const initState = {
  blogs: [],
};

const initialState = {
  blog : {},
};

export const blogsReducer = (state = initState, action) => {
  if (action.type === FETCH_BLOGS_SUCCESS) {
    return {
      ...state,
      blogs: action.payload
    };
  }

  if (action.type === FETCH_BLOG_SUCCESS) {
    return {
      ...state,
      blog: action.payload
    };
  }

  return state;
};

