import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@mui/icons-material/Delete';

class MessagesDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mssgData: this.prepareMessagesData(props.messagesData),
    };
  }

  prepareMessagesData = (data) => {
    return data.map((message) => [
      message.seller_id.first_name,
      "-",
      this.convertDateFormat(message.created_at),
      message
    ]);
  };

  convertDateFormat = (inputDate) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const [year, month, day] = inputDate.split('-').map(part => parseInt(part, 10));
    const formattedDate = `${months[month - 1]} ${day < 10 ? '0' : ''}${day}`;
    return formattedDate;
  };

  handleRowClick = (rowData, rowState) => {
    const mssgData = this.state.mssgData[rowState.rowIndex];
    this.props.history.push({ pathname: "/message", state: { data: mssgData[3] } });
  };

  render() {
    const columns = [
      { name: "From", label: "From", options: { filter: true, sort: true } },
      { name: "Items", label: "Items", options: { filter: true } },
      { name: "Received", label: "Received", options: { filter: true } },
    ];
    

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'vertical',
      onColumnSortChange: (changedColumn, direction) => console.log('changedColumn: ', changedColumn, 'direction: ', direction),
      page: 1,
      onChangeRowsPerPage: numberOfRows => console.log('numberOfRows: ', numberOfRows),
      onChangePage: currentPage => console.log('currentPage: ', currentPage),
      onRowClick: this.handleRowClick,
    };

    return (
      <>
        <MUIDataTable
          className="datatable_msgs"
          data={this.state.mssgData}
          columns={columns}
          options={options}
        />
      </>
    );
  }
}

MessagesDataTable.propTypes = {
  messagesData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  messagesData: state.messageData?.messagesData || [],
});

export default connect(mapStateToProps)(MessagesDataTable);
