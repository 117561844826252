import axios from "axios";
import { FILTER_PAYOUTS_API, FINANCIAL_STATEMENTS_API, PAYOUTS_API, TRANSACTION_DETAILS } from "../../consts";
import { SET_LOADING } from "./subscriptionplanActions";

export const FETCH_TRANSACTION_START = "FETCH_TRANSACTION_START";
export const FETCH_TRANSACTION_SUCCESS = "FETCH_TRANSACTION_SUCCESS";
export const FETCH_TRANSACTION_FAILURE = "FETCH_TRANSACTION_FAILURE";

export const FETCH_FINANCIAL_STATEMENT_START = "FETCH_FINANCIAL_STATEMENT_START";
export const FETCH_FINANCIAL_STATEMENT_SUCCESS = "FETCH_FINANCIAL_STATEMENT_SUCCESS";
export const FETCH_FINANCIAL_STATEMENT_FAILURE = "FETCH_FINANCIAL_STATEMENT_FAILURE";

export const FETCH_PAYOUTS_START = "FETCH_PAYOUTS_START";
export const FETCH_PAYOUTS_SUCCESS = "FETCH_PAYOUTS_SUCCESS";
export const FETCH_PAYOUTS_FAILURE = "FETCH_PAYOUTS_FAILURE";




const fetchTransactionSuccess = transactionDetails => ({
    type: FETCH_TRANSACTION_SUCCESS,
    payload: transactionDetails
});

const fetchFinancialStatementSuccess = financialStatements => ({
    type: FETCH_FINANCIAL_STATEMENT_SUCCESS,
    payload: financialStatements
});

const fetchPayoutsSuccess = payouts => ({
    type: FETCH_PAYOUTS_SUCCESS,
    payload: payouts
});




export const fetchPayoutTransactions = (id) => {
    const token = localStorage.getItem("Token");

    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        axios.get(TRANSACTION_DETAILS + id + '/',  { headers: { Authorization: "Token " + token } })
            .then(response => {
                dispatch(fetchTransactionSuccess(response.data));
                dispatch({
                    type: SET_LOADING,
                    payload: false
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SET_LOADING,
                    payload: false
                });
            })
    };

};


export const fetchFinancialStatements = (page) => {
    const token = localStorage.getItem("Token");

    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        dispatch({
            type: FETCH_FINANCIAL_STATEMENT_START
        });

        
        let params = {
            limit: 10,
            page: page,
        }
        


        axios.get(FINANCIAL_STATEMENTS_API, {
            params,
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then((response) => {
                dispatch(fetchFinancialStatementSuccess(response.data));
                dispatch({
                    type: SET_LOADING,
                    payload: false
                });

            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SET_LOADING,
                    payload: false
                });

                dispatch({
                    type: FETCH_FINANCIAL_STATEMENT_FAILURE
                });
            }
            )
    };
};

export const fetchPayouts = (month, year) => {
    const token = localStorage.getItem("Token");

    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });

        dispatch({
            type: FETCH_PAYOUTS_START
        });

    

        axios.get(PAYOUTS_API, {
      
            headers: {
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            dispatch(fetchPayoutsSuccess(response.data));
            dispatch({
                type: SET_LOADING,
                payload: false
            });
        })
        .catch(error => {
            console.log(error);
            dispatch({
                type: SET_LOADING,
                payload: false
            });
            dispatch({
                type: FETCH_PAYOUTS_FAILURE
            });
        })

    }


}


export const fetchPayoutsByMonth = (data) => {
    const token = localStorage.getItem("Token");
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });

        dispatch({
            type: FETCH_PAYOUTS_START
        });

        let params = {
            time_range : data.month,
            year : data.year,
        }

        axios.get(FILTER_PAYOUTS_API, {
            params,
            headers: {
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            dispatch(fetchPayoutsSuccess(response.data));
            dispatch({
                type: SET_LOADING,
                payload: false
            });
        })
        .catch(error => {
            console.log(error);
            dispatch({
                type: SET_LOADING,
                payload: false
            });
            dispatch({
                type: FETCH_PAYOUTS_FAILURE
            });
        })

    }


}

