import {FETCH_FAQS_SUCCESS} from "../actions/faqActions";

const initState = {
  faqs: []
};

const faqReducer = (state = initState, action) => {
  if (action.type === FETCH_FAQS_SUCCESS) {
    return {
      ...state,
      faqs: action.payload
    };
  }

  return state;
};

export default faqReducer;