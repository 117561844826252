import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { UserLogout } from "../../../redux/reducers/Login-Register";

const mapStateToProps = state => ({
  categories: state.categoriesData.categories,
  user: state.userData.user
});

const MobileNavMenu = ({ strings, categories, user }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(UserLogout(history));
  }

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>

        <li className="dividdr"><p>My Menu</p></li>
        {user != null ?
          <>
            <li><Link to="/vendor"><img src={process.env.PUBLIC_URL + '/assets/images/dashboard_icon.svg'} alt='Dashboard' />Dashboard</Link></li>
            <li><Link to="/vendor-account"><i className="fa fa-user"></i> My Account</Link></li>
            <li><Link to="/purchase-history"><i className="fa fa-gift"></i> Purchases History</Link></li>
          </>
          : <li><Link to="/login"><i class="fa fa-user"></i>Login</Link></li>}
        <li><Link to="/wishlist"><i className="fa fa-heart"></i> Favorites List</Link></li>
        <li><Link to="/cart"><i className="fa fa-shopping-cart"></i> My Cart</Link></li>
        <li><Link to="/blogs"><i className="fa fa-picture-o"></i> Zourie Blogs</Link></li>
        <li><Link to="/help-center"><i className="fa fa-info-circle"></i> Help Center</Link></li>
        <li><Link to="/page/our-mission"><i className="fa fa-bullseye"></i> Our Mission</Link></li>
        <li><Link to="/"><i className="fa fa-globe"></i> Zourie Club</Link></li>
        {user &&
          <li className="logout_dv" onClick={onSubmit}><img src={process.env.PUBLIC_URL + '/assets/images/log_out_icon.svg'} alt='Log Out' /><span>Logout</span></li>
        }



        <li className="dividdr"><p>Categories</p></li>

        <li>
          <Link to="/search-result?product_type=Auction"><i className="fa fa-chevron-right small"></i>Zourie Auctions</Link>
        </li>
        {categories && categories.map((category, index) => {
         
          return (
            <li key={index}><Link to={`/search-result?category=${category.id}`}><i className="fa fa-chevron-right small"></i> {category.category_name}</Link></li>
          )
        })}






      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
  categories: PropTypes.array,
};


export default connect(mapStateToProps)(MobileNavMenu);