import axios from 'axios';
import { GET_JOBS } from '../../consts';

export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";


const fetchJobsSuccess = jobsList => ({
  type: FETCH_JOBS_SUCCESS,
  payload: jobsList
});


export const fetchJobs = () => {
  return dispatch => {
    const token = localStorage.getItem("Token");
    axios.get(GET_JOBS, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(response => {
      dispatch(fetchJobsSuccess(response.data),
      );
    })
    .catch(error => {
      console.log(error);
    });
  };
};