import React, {  useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { orderItemCancel, orderItemReturn } from '../../../redux/actions/orderActions';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '920px',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
};

const CancelOrderHistory = ({
  open3,

  handleClose3,
  cancelProduct
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [message, SetMessage] = useState('')

  const handleCancelOrderItem = (product) => {
    const orderItemData = {
      order_item: product.id,
      item_status: "Cancelled",
    }
    dispatch(orderItemCancel(orderItemData, addToast));
    handleClose3();
  };


  const handleReturnOrderItem = (product) => {
    const orderItemData = {
      order_item: product.id,
      return_message: message,
    }
    dispatch(orderItemReturn(orderItemData, addToast));
    handleClose3();
  }

  const handleReturnMessage = (product) => {
    SetMessage(product.target.value)
  }


  return (
    <>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='cancel_order_modal_close'>
        <FontAwesomeIcon onClick={()=> handleClose3()}  icon={faXmark} />
        </div>
          <div className="cancel_order_modal_wrrpr">
            <h6>Order Item:</h6>
            <div className='cancel_order_modal_data'>
              <img src={cancelProduct && cancelProduct.product.product_image[0].upload_image} alt="" />
              <div className='cancel_order_modal_info'>
                <h1>{cancelProduct && cancelProduct.product.product_title}</h1>
                <p dangerouslySetInnerHTML={{ __html: cancelProduct && cancelProduct.product.description }}></p>
              </div>
            </div>
            <div className='condition_sold'>
              <div className=' product_condition'>
                <h6>Condition: <span>{cancelProduct && cancelProduct.product.condition}</span></h6>
              </div>
     
            </div>
            { cancelProduct && cancelProduct.item_status === 'Delivered' ?
              <div className='returning_policy'>
                <h4>Reason for cancelled or returned item:</h4>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel onClick={(e)=> handleReturnMessage(e)} value="Ordered wrong product" control={<Radio />} label="Ordered wrong product" />
                    <FormControlLabel onClick={(e)=> handleReturnMessage(e)} value="Item is damaged" control={<Radio />} label="Item is damaged" />
                    <FormControlLabel onClick={(e)=> handleReturnMessage(e)} value="Item is not as described" control={<Radio />} label="Item is not as described" />
                    <FormControlLabel onClick={(e)=> handleReturnMessage(e)} value="Item is missing pieces or parts" control={<Radio />} label="Item is missing pieces or parts" />
                    <FormControlLabel onClick={(e)=> handleReturnMessage(e)} value="Item does not match photos" control={<Radio />} label="Item does not match photos" />
                  </RadioGroup>
                </FormControl>
              </div>
              : 
              <div className='cancel_order'>
                <h4>
                  You want to cancel this order item. Are you sure?
                </h4>
              </div>
            }
            <div className='myform_dv feedback_modal-btn'>
                { cancelProduct && cancelProduct.item_status === 'Delivered' ?
                  <button className="myform_btn" onClick={()=> handleReturnOrderItem(cancelProduct && cancelProduct)}>Submit</button>
                  :
                  <button className="myform_btn" onClick={()=> handleCancelOrderItem(cancelProduct && cancelProduct)}>Submit</button>
                }
                <button type='button' className='cancel_Btn' onClick={()=> handleClose3()}>Cancel</button>
              </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default CancelOrderHistory;