import PropTypes from "prop-types";
import React, { Suspense, lazy, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route, Redirect, Outlet } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage } from "redux-multilanguage";
import { connect, useDispatch, useSelector } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import './App.css';
import './Responsive.css';

import ComingSoon from "./pages/ComingSoon";

import Dashboard from "./pages/vendor/Dashboard";
import Register from "./pages/other/Register";
import ForgetPassword from "./pages/other/ForgetPassword";
import NewPassword from "./pages/other/NewPassword";
import PaymentSuccess from "./pages/other/PaymentSuccess";
import PaymentCancelled from "./pages/other/PaymentCancelled";
import OrderDeatilsPage from "./pages/other/OrderDeatilsPage";
import OrderDeatilsSellerPage from "./pages/vendor/OrderDeatilsSellerPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import AllMessages from "./pages/other/AllMessages";
import ComposeMessage from "./pages/other/ComposeMessage";
import MessageDetailPage from "./pages/other/MessageDetailPage";
import { getSubsStatus } from "./redux/actions/subscriptionplanActions";
import VerifyEmail from "./pages/other/VerifyEmail";
import moment from "moment";
import ExpireSubscription from "./pages/other/ExpireSubscription";
import Payouts from "./pages/other/Payout";
import Financial from "./pages/other/Financial";
import SubscriptionDetail from "./pages/other/SubscriptionDetail";
import TransactionDetail from "./pages/other/TransactionDetail";
import ConditionalRouteRenderer from "./pages/vendor/components/ConditionalRouteRenderer";
import PrivateRoute from "./Routes/PrivateRoute";
import 'dotenv/config'

const Home = lazy(() => import("./pages/home/Home"));
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));
const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
const HomeFashionSeven = lazy(() => import("./pages/home/HomeFashionSeven"));
const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));
const HomeKidsFashion = lazy(() => import("./pages/home/HomeKidsFashion"));
const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));
const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));


const HomeFurnitureThree = lazy(() =>
  import("./pages/home/HomeFurnitureThree")
);
const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
const HomeFurnitureSeven = lazy(() =>
  import("./pages/home/HomeFurnitureSeven")
);
const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));

const HomeElectronicsThree = lazy(() =>
  import("./pages/home/HomeElectronicsThree")
);
const HomeFlowerShopTwo = lazy(() => import("./pages/home/HomeFlowerShopTwo"));
const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));

const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
const HomeMedicalEquipment = lazy(() =>
  import("./pages/home/HomeMedicalEquipment")
);
const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));
const HomeBlackFriday = lazy(() => import("./pages/home/HomeBlackFriday"));
const HomeBlackFridayTwo = lazy(() =>
  import("./pages/home/HomeBlackFridayTwo")
);
const HomeValentinesDay = lazy(() => import("./pages/home/HomeValentinesDay"));
const Career = lazy(() => import("./pages/other/Career"));
const PageManagement = lazy(() => import("./pages/other/PageManagement"));
const HelpCenter = lazy(() => import("./pages/other/HelpCenter"));
const SellerChargesAndFees = lazy(() => import("./pages/other/SellerChargesAndFees"));
const ViewJobs = lazy(() => import("./pages/other/ViewJobs"));
const OpenJobs = lazy(() => import("./pages/other/OpenJobs"));
const Review = lazy(() => import("./pages/other/Review"));
const Followers = lazy(() => import("./pages/other/Followers"));
const SellerCommunity = lazy(() => import("./pages/other/SellerCommunity"));




const GiftCardPage = lazy(() => import("./pages/other/GiftCardPage"));




const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const SearchResult = lazy(() => import("./pages/shop/SearchResult"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// Vendor Pages
const VendorAccount = lazy(() => import("./pages/vendor/MyAccount"));
const VendorAddListing = lazy(() => import("./pages/vendor/AddListing"));
const VendorAddListingPro = lazy(() => import("./pages/vendor/AddListingPro"));

const VendorEditListingPro = lazy(() => import('./pages/vendor/EditListingPro'));
const VendorEditListing = lazy(() => import("./pages/vendor/EditListing"));
const ManageListings = lazy(() => import("./pages/vendor/pages/ManageListings"));
const VendorOrdersPage = lazy(() => import("./pages/vendor/VendorOrdersPage"));


// other pages
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const PurchaseHistory = lazy(() => import("./pages/other/PurchaseHistory"));
const Marketing = lazy(() => import("./pages/vendor/pages/Marketing"));

const NotFound = lazy(() => import("./pages/other/NotFound"));



const stripePromise = loadStripe(
  // indian account stripe key
 process.env.REACT_APP_STRIPE_LIVE_PUB_KEY,
// process.env.REACT_APP_STRIPE_TEST_PUB_KEY
);

// 'pk_test_51KvfQxSHenI5vmcNaZy2ZocJlNxK9bVKa2XZ2DZuUwcMWknUa0x9caPMrbPT20ksKNgzJm1oONPmQOPLFlzJhXWD00QYx7tD3R'

// us account stripe key 
// 'pk_test_51NLfjsIXudOQkVZr8l9d9770HXgDq93OozTpXDsMtONCKs2mKDdS9k0ZJNffPlq39E4dU0g2HFAmyPNJml481InB00c011LmuJ'
// 'pk_test_51LoYFOIfQVt0TUAY7rkLZa59gyRXoQLK5c0WRft7YBKNSyIR3LTJcRf0i71R0v5pE4zwXpwdFt1ypsJXiJZYVgN400fmExHV8g'


const App = ({ props, user }) => {


  const subscriptionplans = useSelector((state) => state?.subscriptionplans)
  const userLoginStatus = useSelector((state) => state?.userData?.user)



  const redirectToHome = () => {
    if (user) {
      return <Redirect to="/" />;
    }
    else {
      return <LoginRegister />;
    }

  }

  const redirectOfSubscriptions = () => {


    const subTime = subscriptionplans?.buyplan ? moment(subscriptionplans?.buyplan?.end_date).format('YYYY-MM-DD HH:mm:ss') :
      null;

    if (moment().format('YYYY-MM-DD HH:mm:ss') > subTime) {
      return <Redirect to="/subscription-expire" />
    } else {
      if (subTime) {
        return <Dashboard />
      } else {
        return <Redirect to="subscription-expire" />
      }
    }
  }







  const redirectOfSubscriptionWithUser = () => {
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const endDateTime = subscriptionplans?.buyplan?.end_date ? moment(subscriptionplans?.buyplan?.end_date).format('YYYY-MM-DD HH:mm:ss') : null;

    if (user) {
      return <SubscriptionDetail />;
    } else {
      return <Redirect to="/" />;
    }
  };

  const redirectOfSellerFees = () => {
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const endDateTime = subscriptionplans?.buyplan ? moment(subscriptionplans?.buyplan?.end_date).format('YYYY-MM-DD HH:mm:ss') : null;


    if (currentDateTime > endDateTime) {
      return <SellerChargesAndFees />;
    }
    else {
      if (!endDateTime) {
        return <SellerChargesAndFees />;
      } else {
        return <Redirect to="/subscription-detail" />
      }
    }
  };




  return (
    <Elements stripe={stripePromise}>
      <ToastProvider placement="bottom-left">
        <BreadcrumbsProvider>
          <Router>
            <ScrollToTop>
              <Suspense
                fallback={
                  <div className="Zourie-preloader-wrapper">
                    <div className="Zourie-preloader">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                }
              >
                <Switch>

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={Home}
                  />


                  <Route
                    path={process.env.PUBLIC_URL + "/career"}
                    component={Career}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/page/:slug"}
                    component={PageManagement}
                  />

                

                  <Route
                    path={process.env.PUBLIC_URL + "/help-center"}
                    component={HelpCenter}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/jobs/:type"}
                    component={ViewJobs}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/open-jobs"}
                    component={OpenJobs}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/review"}
                    component={Review}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/followers"}
                    component={Followers}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/seller-community"}
                    component={SellerCommunity}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/gift-card"}
                    component={GiftCardPage}
                  />



                  {/* Homepages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion"}
                    component={HomeFashion}
                  />


                  <Route
                    path={process.env.PUBLIC_URL + "/comingsoon"}
                    component={ComingSoon}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion"}
                    component={HomeFashion}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion-two"}
                    component={HomeFashionTwo}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion-three"}
                    component={HomeFashionThree}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion-four"}
                    component={HomeFashionFour}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion-five"}
                    component={HomeFashionFive}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion-six"}
                    component={HomeFashionSix}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion-seven"}
                    component={HomeFashionSeven}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-fashion-eight"}
                    component={HomeFashionEight}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-kids-fashion"}
                    component={HomeKidsFashion}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-cosmetics"}
                    component={HomeCosmetics}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-furniture"}
                    component={HomeFurniture}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-furniture-two"}
                    component={HomeFurnitureTwo}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-furniture-three"}
                    component={HomeFurnitureThree}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-furniture-four"}
                    component={HomeFurnitureFour}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-furniture-seven"}
                    component={HomeFurnitureSeven}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-electronics"}
                    component={HomeElectronics}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/home-electronics-three"}
                    component={HomeElectronicsThree}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-flower-shop-two"}
                    component={HomeFlowerShopTwo}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-organic-food"}
                    component={HomeOrganicFood}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-grid-banner"}
                    component={HomeGridBanner}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/home-handmade"}
                    component={HomeHandmade}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-medical-equipment"}
                    component={HomeMedicalEquipment}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-christmas"}
                    component={HomeChristmas}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-black-friday"}
                    component={HomeBlackFriday}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-black-friday-two"}
                    component={HomeBlackFridayTwo}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/home-valentines-day"}
                    component={HomeValentinesDay}
                  />


                  {/* Shop pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/vendor/:id"}
                    component={ShopGridStandard}
                  />
                  {/* <Route
                    path={process.env.PUBLIC_URL + "/vendor/:id"}
                    component={ShopGridStandard}
                  /> */}
                  <Route
                    path={process.env.PUBLIC_URL + "/search-result"}
                    component={SearchResult}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                    component={ShopGridFilter}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                    component={ShopGridTwoColumn}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                    component={ShopGridNoSidebar}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                    component={ShopGridFullWidth}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                    component={ShopGridRightSidebar}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-list-standard"}
                    component={ShopListStandard}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                    component={ShopListFullWidth}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                    component={ShopListTwoColumn}
                  />

                  {/* Shop product pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/product/:id"}
                    render={(routeProps) => (
                      <Product {...routeProps} key={routeProps.match.params.id} />
                    )}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/vendor/:id"}
                    render={(routeProps) => (
                      <ShopGridStandard {...routeProps} key={routeProps.match.params.id} />
                    )}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/blog/:id"}
                    render={(routeProps) => (
                      <BlogDetailsStandard {...routeProps} key={routeProps.match.params.id} />
                    )}
                  />



                  <Route
                    path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                    component={ProductTabLeft}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                    component={ProductTabRight}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                    component={ProductSticky}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/product-slider/:id"}
                    component={ProductSlider}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                    component={ProductFixedImage}
                  />

                  {/* Blog pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/blogs"}
                    component={BlogStandard}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                    component={BlogNoSidebar}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                    component={BlogRightSidebar}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/blog-details"}
                    component={BlogDetailsStandard}
                  />















                  {/* Other pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/contact"}
                    component={Contact}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/my-account"}
                    component={MyAccount}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/login"}
                    render={redirectToHome}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/register"}
                    component={Register}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/forgot-password"}
                    component={ForgetPassword}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/new-password"}
                    component={NewPassword}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/verify-email"}
                    component={VerifyEmail}
                  />


                  <Route
                    path={process.env.PUBLIC_URL + "/cart"}
                    component={Cart}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/wishlist"}
                    component={Wishlist}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/compare"}
                    component={Compare}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/not-found"}
                    component={NotFound}
                  />























































                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/vendor"}
                    render={redirectOfSubscriptions}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/vendor-account"}
                    component={VendorAccount}
                  />

                 

                  {
                    <PrivateRoute isAuthenticated={userLoginStatus}
                      path={process.env.PUBLIC_URL + "/add-listing"}
                      component={VendorAddListing} />
                  }




                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/add-listing-pro"}
                    render={() => <ConditionalRouteRenderer subscriptionplans={subscriptionplans} add={'add'} />}
                  />

                  {moment().format('YYYY-MM-DD') < subscriptionplans?.buyplan?.expired_at
                    ?
                    <PrivateRoute isAuthenticated={userLoginStatus}
                      path={process.env.PUBLIC_URL + "/edit-listing-pro/:id"}
                      render={() => <ConditionalRouteRenderer subscriptionplans={subscriptionplans} />}
                      component={VendorEditListingPro}
                    />
                    :
                    <Route
                      path={process.env.PUBLIC_URL + "/not-found"}
                      component={NotFound}
                    />

                  }
                
              

                  {moment().format('YYYY-MM-DD') < subscriptionplans?.buyplan?.expired_at
                    ?

                    <PrivateRoute isAuthenticated={userLoginStatus}
                      path={process.env.PUBLIC_URL + "/edit-listing/:id"}
                      component={VendorEditListing}

                    />
                    :
                    <Route
                      path={process.env.PUBLIC_URL + "/not-found"}
                      component={NotFound}
                    />
                  }





                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/manage-listings"}
                    component={ManageListings}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/return-and-cancellations"}
                    component={VendorOrdersPage}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/payment-success"}
                    component={PaymentSuccess}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/payment-cancelled"}
                    component={PaymentCancelled}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/purchase-history"}
                    component={PurchaseHistory}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/marketing"}
                    component={Marketing}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/vendor-orders"}
                    component={VendorOrdersPage}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/all-messages"}
                    component={AllMessages}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/compose-message"}
                    component={ComposeMessage}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/message"}
                    component={MessageDetailPage}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/payouts"}
                    component={Payouts}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/financials"}
                    component={Financial}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/transaction-detail"}
                    component={TransactionDetail}
                  />





                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/seller-charges-and-fees"}
                    render={redirectOfSellerFees}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/buy-subscription"}
                    component={SellerChargesAndFees}
                  />
                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/subscription-expire"}
                    component={ExpireSubscription}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/subscription-detail"}
                    component={
                      SubscriptionDetail
                    }
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/checkout"}
                    component={Checkout}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/order-details"}
                    component={OrderDeatilsPage}
                  />

                  <PrivateRoute isAuthenticated={userLoginStatus}
                    path={process.env.PUBLIC_URL + "/order-details-seller"}
                    component={OrderDeatilsSellerPage}
                  />




                  <Route exact component={NotFound} />






































































                </Switch>
              </Suspense>
            </ScrollToTop>
          </Router>
        </BreadcrumbsProvider>
      </ToastProvider>
    </Elements>
  );
};




























App.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object

};

const mapStateToProps = state => ({
  user: state.userData.user,

});

export default connect(mapStateToProps)(multilanguage(App));
