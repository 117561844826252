import React, { Fragment, useState } from "react";

import MetaTags from "react-meta-tags";

import LayoutOne from "../../layouts/LayoutOne";
import BankImg from "../../assets/assets/images/bank.svg";
import PayoutWork from "../../assets/assets/images/payoutwork.svg";
import StatementImg from "../../assets/assets/images/statement.svg";
import BootstrapSelect from "react-bootstrap-select-dropdown";
import Modal from '@mui/material/Modal';
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchPayoutTransactions, fetchPayouts, fetchPayoutsByMonth } from "../../redux/actions/transactionActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import moment from "moment";





const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "920px",
  height: "775px",
  bgcolor: 'background.paper',
  borderRadius: "16px",
  boxShadow: 24,
  padding: "36px",
  Height: "100vh",
  Width: "100%",
  overflowY: "scroll",
};
const Payouts = () => {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const Monthly = [
    {
      "labelKey": "1 month",
      "value": "1 month",
      "selected": true
    },
    {
      "labelKey": "3 months",
      "value": "3 months",
    },
    {
      "labelKey": "6 months",
      "value": "6 months",
    },
    {
      "labelKey": "12 months",
      "value": "12 months",
    }
  ]
  const Yearly = [
    {
      "labelKey": "2023",
      "value": "2023",
      "selected": true
    },
    {
      "labelKey": "2024",
      "value": "2024"
    }
  ]

  const history = useHistory();
  const dispatch = useDispatch();
  const loader = useSelector(store => store.transactionData?.loading);
  const payouts = useSelector(store => store.transactionData?.payouts);

  const [currentItems, setCurrentItems] = useState(null);
  const itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(5);
    setPageCount(5);

  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    setItemOffset(5);
  };

  const handlePayoutDetail = (index, startDate, endDate, id) => {

    history.push({
      pathname: '/transaction-detail',
      state: {
        startDate: startDate,
        endDate: endDate,
        id: id
      }
    })

  }
  const handlePage = () => {
    history.push('/financials')
  }

  useEffect(() => {
    dispatch(fetchPayouts());
  }, [])


  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const handleMonthChange = (selectedOptions) => {
    setSelectedMonth(selectedOptions?.selectedKey[0]?.split(' ')[0]);
  };

  const handleYearChange = (selectedOptions) => {
    setSelectedYear(selectedOptions?.selectedKey[0]);
  };

  useEffect(() => {
    if (selectedMonth || selectedYear) {
      dispatch(fetchPayoutsByMonth(
        {
          month: selectedMonth,
          year: selectedYear
        }
      ))
    }
  }, [selectedMonth, selectedYear])


  const sortedPayouts = payouts?.categorized_payouts?.length > 0
    ? payouts.categorized_payouts.slice().sort((a, b) => {
      const dateA = moment(a.start_date, 'DD/MM/YYYY').toDate();
      const dateB = moment(b.start_date, 'DD/MM/YYYY').toDate();
      return dateB - dateA;
    })
    : [];



  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Payouts</title>
        <meta
          name="description"
          content="seller subscription"
        />
      </MetaTags>
      <LayoutOne headerTop="visible">
        {loader && <Loader />}

        <section className="bg_gray2">
          <div className="container">
            <h1 className="payout_title">Payout Schedule and Terms</h1>
            <div className="work_statement_wrrpr">
              <div onClick={handleOpen} className="payout_work_and_statement">
                <img src={PayoutWork} alt="payout work" />
                <h6>How Payouts Works:</h6>
              </div>
              <div onClick={() => handlePage()} className="payout_work_and_statement">
                <img src={StatementImg} alt="statement" />
                <div>
                  <h6>Financial Statement</h6>
                  <h5>Statement summary.pdf</h5>
                </div>
                <button type="button">View All</button>
              </div>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className='payout_modal_close'>
                  <FontAwesomeIcon onClick={() => handleClose()} icon={faXmark} />
                </div>

                <div className="payout_work_modal_wrrpr">
                  <div>
                    <h3>How Payouts Works:</h3>
                    <p>Payouts generally refer to the process of transferring funds from the payment gateway to the seller's account. Here are the basic steps involved in the payout process:</p>
                    <ol>
                      <li>
                        <span>Customer makes a purchase:</span> When a customer buys a product from Zourie, the payment is processed through the Stripe payment gateway.
                      </li>
                      <li>
                        <span>Payment gateway holds the funds:</span> The payment gateway holds the funds until the payment has been authorized and processed.
                      </li>
                      <li>
                        <span>Payment gateway processes payout:</span> The payment gateway processes the payout request and transfers the funds to the seller's bank account.
                      </li>
                      <li>
                        <span>Payout fees:</span> The payment gateway will charge a fee for the payout.
                      </li>
                      <li>
                        <span>Payout timeframes:</span> The time it takes for the funds to reach the seller's account can vary depending on the payment gateway and the seller's bank. Some  payment gateways offer instant payouts, while others may take several business days.
                      </li>


                      <li>
                        <span>Payout fees:</span> The payment gateway will charge a fee for the payout.
                      </li>
                      <li>
                        <span>Payout timeframes:</span> The time it takes for the funds to reach the seller's account can vary depending on the payment gateway and the seller's bank. Some  payment gateways offer instant payouts, while others may take several business days.
                      </li> <li>
                        <span>Payout fees:</span> The payment gateway will charge a fee for the payout.
                      </li>
                      <li>
                        <span>Payout timeframes:</span> The time it takes for the funds to reach the seller's account can vary depending on the payment gateway and the seller's bank. Some  payment gateways offer instant payouts, while others may take several business days.
                      </li> <li>
                        <span>Payout fees:</span> The payment gateway will charge a fee for the payout.
                      </li>
                      <li>
                        <span>Payout timeframes:</span> The time it takes for the funds to reach the seller's account can vary depending on the payment gateway and the seller's bank. Some  payment gateways offer instant payouts, while others may take several business days.
                      </li>

                    </ol>
                    <h3>Payout Terms:</h3>
                    <p>Payout occurs the 1st and the 15th of every month Payout is based on item delivery, if the tracking number confirms delivery payment can be requested and approved after 3 days. Please review the examples below to understand when and how funds will be processed.</p>
                    <div className="payout_terms_wrrpr">
                      <ol>
                        <li><span>Example 1:</span> If an item is delivered on the 10th of the month + 3 days is the 13th, this means that the payout will be allowed for the 15th of the month. </li>
                        <li><span>Example 2:</span> If an item is delivered on the 14th + 3 days is the 17th, this means that the payout will not occur until the 1st of the following month.</li>
                        <li><span>Example 3:</span> If an items is delivered on the 1st of the month +3 days is the 4th, this means that the payout will not occur until the 15th of the month.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
            <div className="funds_payout_months_wrrpr">
              <div className="funds_dv">
                <h5>Total Funds</h5>
                {payouts?.total_payout ? (
                  <h3>${payouts.total_payout}</h3>
                ) : (
                  <h3>${payouts?.payout_amount_according_to_circle?.toFixed(2)}</h3>
                )}

                {payouts?.total_months ? (
                  <h4>Payouts: 0-{payouts?.total_months} months</h4>
                ) : (
                  <h4>Payouts: 0-1 months</h4>
                )}
              </div>
              <div className="payout_months sortsearch_wrrpr3 dropdown_style1">
                <BootstrapSelect options={Monthly} placeholder='Monthly'
                  onChange={(selectedOptions) =>
                    handleMonthChange(selectedOptions)
                  }
                />
                <BootstrapSelect options={Yearly} placeholder='Yearly'
                  onChange={(selectedOptions) =>
                    handleYearChange(selectedOptions, 'Yearly')
                  }
                />
              </div>
            </div>
            <div className="row">
              <table className="payout_table">
                <tr>
                  <th>Date</th>
                  <th>Descriptions</th>
            
                  <th>Details</th>
                </tr>
                {sortedPayouts?.length > 0 ? sortedPayouts?.map((payout, index) => (
                  <tr key={index}>
                    <td>{moment(payout.start_date, 'DD/MM/YYYY').format('MMM D, YYYY')} - {moment(payout.end_date, 'DD/MM/YYYY').format('MMM D, YYYY')}</td>
                    <td>
                      <div className="description_wrrpr">
                        <div className="description_img">
                          <img src={BankImg} alt="bank" />
                        </div>
                        <div className="description_data">
                     
                          <h5 dangerouslySetInnerHTML={{ __html: payout.total_payouts }}></h5>
                         
                        </div>
                      </div>
                    </td>
                
                    <td>
                      <button className="view_btn" type="button" onClick={
                        () => {
                          handlePayoutDetail(
                            index, payout.start_date, payout.end_date, payout.id
                          )
                        }
                      }>View</button>
                    </td>
                  </tr>
                )) :
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>No Payouts Found</td>
                  </tr>
                }

               
              </table>
            </div>
           
          </div>
        </section>
      </LayoutOne>
    </Fragment>
  );
};


export default Payouts;