import axios from "axios";
import { GET_BANNERS } from "../../consts";

export const FETCH_BANNER_START = "FETCH_BANNER_START";
export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";
export const FETCH_BANNER_FAILURE = "FETCH_BANNER_FAILURE";


const fetchBannerSuccess = banners => ({
    type: FETCH_BANNER_SUCCESS,
    payload: banners
});

    
export const fetchBanners = banners => {
    return dispatch => {
        axios.get(GET_BANNERS)
        .then(response => {
            dispatch(fetchBannerSuccess(response.data));
        })
        .catch(error => {
            console.log(error);
        })
    };
};