import axios from "axios";
import { GET_MY_PRODUCTS, GET_MY_ORDERS, GET_MY_STATS, SALES_HISTORY, GET_TOTAL_SALES, GET_CANCEL_RETURNS_ORDERS, GET_MARKETING_LISTINGS } from "../../consts";
import { SET_LOADING } from "./subscriptionplanActions";

export const FETCH_MY_PRODUCTS = "FETCH_MY_PRODUCTS";
export const FETCH_MY_ORDERS = "FETCH_MY_ORDERS";
export const FETCH_MY_STATS = "FETCH_MY_STATS";
export const FETCH_SALES_HISTORY_SUCCESS = "FETCH_SALES_HISTORY_SUCCESS";
export const FETCH_TOTAL_SALES = "FETCH_TOTAL_SALES";
export const FETCH_MARKETING_LISTINGS = "FETCH_MARKETING_LISTINGS";
export const FETCH_CANCEL_RETURNS_ORDERS = "FETCH_CANCEL_RETURNS_ORDERS";


export const fetchMyProducts = (page) => {
    const token = localStorage.getItem("Token");
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });

        let params = {
            limit: 10,
            page: page
        };
        axios.get(GET_MY_PRODUCTS, {
            params,
            headers: { Authorization: "Token " + token }
        })
            .then(response => {
                dispatch({
                    type: FETCH_MY_PRODUCTS,
                    payload: response.data
                })
                dispatch({
                    type: SET_LOADING,
                    payload: false
                })
            })
            .catch(error => {
                dispatch({
                    type: SET_LOADING,
                    payload: false
                })
                console.log(error);
            })
    };
};


export const fetchMyOrders = (setLoading, pageNumber) => {
    return dispatch => {
        setLoading(true);

        const token = localStorage.getItem("Token");
        axios.get(GET_MY_ORDERS, { headers: { Authorization: "Token " + token }, params: { limit: 10, page: pageNumber } })
            .then(response => {
                dispatch({
                    type: FETCH_MY_ORDERS,
                    payload: response.data
                })
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    };
};


export const fetchMyStats = (setLoader) => {
    return dispatch => {
        setLoader(true);
        const token = localStorage.getItem("Token");
        axios.get(GET_MY_STATS, { headers: { Authorization: "Token " + token } })
            .then(response => {
                dispatch({
                    type: FETCH_MY_STATS,
                    payload: response.data
                })
                setLoader(false);
            })
            .catch(error => {
                console.log(error);
                setLoader(false);
            })
    };
};


export const fetchSalesHistory = () => {
    const token = localStorage.getItem("Token");
    return dispatch => {
        axios.get(SALES_HISTORY, { headers: { Authorization: "Token " + token } })
            .then(response => {
                dispatch({
                    type: FETCH_SALES_HISTORY_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                console.log(error);
            })
    };
};

export const fetchVendorTotalSales = () => {
    return dispatch => {
        const token = localStorage.getItem("Token");
        axios.get(GET_TOTAL_SALES, { headers: { Authorization: "Token " + token } })
            .then(response => {
                dispatch({
                    type: FETCH_TOTAL_SALES,
                    payload: response.data.total_sale
                })
            })
            .catch(error => {
                console.log(error);
            })
    };
};

export const fetchMarketingListingProducts = (pageNumber) => {
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        const token = localStorage.getItem("Token");
        axios.get(GET_MARKETING_LISTINGS, { headers: { Authorization: "Token " + token }, params: { limit: 10, page: pageNumber } })
            .then(response => {
                dispatch({
                    type: FETCH_MARKETING_LISTINGS,
                    payload: response.data
                })
                dispatch({
                    type: SET_LOADING,
                    payload: false
                })
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SET_LOADING,
                    payload: false
                })
            })
    
    }
}


export const fetchCancelAndReturnOrders = (setLoading, pageNumber) => {
    return dispatch => {
        setLoading(true);
        const token = localStorage.getItem("Token");
        axios.get(GET_CANCEL_RETURNS_ORDERS, { headers: { Authorization: "Token " + token }, params: { limit: 10, page: 1 }})
            .then(response => {
                dispatch({
                    type: FETCH_CANCEL_RETURNS_ORDERS,
                    payload: response.data
                })
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    };
};