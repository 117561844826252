import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import Logo from "../../components/header/Logo";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import MobileMenuSearch from "../../components/header/sub-components/MobileSearch";
import {
  Navbar,
  Container,
  FormControl,
  Row,
  Col,
  Form,
} from "react-bootstrap";


const mapStateToProps = state => ({
  categories: state.categoriesData.categories,
  user: state.userData.user,
});


const HeaderOne = ({
  
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
  categories,
  header3,
  vendor,
  user
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);


 




  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };



  const [formState, setFormState] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();
  }


  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };




  const clickOnEnter = useRef();
  function handleClickOnEnter(event) {
    if (event.keyCode === 13) {
      clickOnEnter.current.click();
    }
  }


  const [getCategoryIdFromUrl, setgetCategoryIdFromUrl] = useState(null)

  const [getAuction, setAuction] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const productData = useSelector(store => store.productData)

const exmp=[
  {
    "discount":"30% off on all subscription"
  }
]
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const selectedCategory = params.get('category');
    setSelectedCategory(selectedCategory)
  }, [window.location.search, productData.searchfilter.category])


  useEffect(() => {
    const fetchGetCategoryIdFromUrl = window.location.search;

    const finishedGetCategoryIdFromUrl = fetchGetCategoryIdFromUrl ? (
      (fetchGetCategoryIdFromUrl.split('?')) ? (
        (fetchGetCategoryIdFromUrl.split('?')[1].slice(0, 8) === 'category') ? (
          fetchGetCategoryIdFromUrl.split('category')[1].split('=')[1]
        ) : null
      ) : null
    ) : null
    if (performance.navigation.type === 1) {
      const id = localStorage.getItem('category')
      setgetCategoryIdFromUrl(Number(id))
    }
    setgetCategoryIdFromUrl(Number(finishedGetCategoryIdFromUrl && finishedGetCategoryIdFromUrl))
    setAuction(null)


    const finishedGetTypeFromUrl = fetchGetCategoryIdFromUrl ? (
      (fetchGetCategoryIdFromUrl.split('?')) ? (
        (fetchGetCategoryIdFromUrl.split('?')[1].slice(0, 12) === 'product_type') ? (
          true
        ) : false
      ) : false
    ) : false



    if (finishedGetTypeFromUrl) {
      setAuction(true)
    }

  }, [window.location.search])



  const handleActiveOnType = () => {
    setAuction(true)
    setgetCategoryIdFromUrl(null);
    localStorage.setItem('auction', true)
  }

  const subscriptionplan = useSelector((state) => state.subscriptionplans)





  return (


    <header
      className={`header-area clearfix ${headerBgClass ? headerBgClass : ""} ${headerPositionClass ? headerPositionClass : ""
        } header_wrrpr`}
    >

      <Navbar bg="light" expand="lg" className={`${scroll > headerTop ? "stick" : ""}`}>
        <Container fluid>
          <div className="mobile_menu_wrrpr">
            <Container>

              <div className="logo" >
                <Logo imageUrl="" />
              </div>

              <div className="mobile_menu_btn">
                <button onClick={() => triggerMobileMenu()} >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>



            </Container>
            <Container>
              <MobileMenuSearch />
            </Container>



          </div>
          <div>
      {/* {exmp.map((item, index) => (
        item.discount && (
          <div className="mobile_menu_wrrpr_discount row" key={index}>
            <Container>
              <span className="discount-text">{item.discount}</span>
            </Container>
          </div>
        )
      ))} */}
    </div>



          <Row className="header1">
            <Container>

              <HeaderTop borderStyle={borderStyle} />

            </Container>

          </Row>



          <Row className="header2">
            <Container className="header-block">
              <Row>

                <Col className="header2_main_dv" md={12}>
                  <Row>

                    <Col md={2} className="header2_logo_dv">
                      <div className="header2_logo">
                        <Logo imageUrl="" logoClass="logo" />
                      </div >
                    </Col>
                    <Col md={10}>
                      <div>

                        <Col md={12} className="header2_menu">
                          <ul className="header2_menu2">

                            {user ?
                              <>
                                <li><Link to={`${subscriptionplan?.buyplan ? "/vendor" : "/vendor-account"}`}>My Account </Link></li>
                                <li><Link to="/wishlist"><img src={process.env.PUBLIC_URL + '/assets/images/heart.png'} alt="Favorites Icon" /> Favorites List</Link></li>
                                <li><Link to="/purchase-history">Purchases History</Link></li>
                              </>
                              :
                              <li>
                                <Link to="/login">Login</Link>/<Link to="/register">Register</Link>
                              </li>
                            }


                            <li><Link to="/blogs">Blog</Link></li>
                            <li><Link to="/help-center">Help Center</Link></li>
                            <li><Link to="#">Ship To <img src={process.env.PUBLIC_URL + '/assets/images/usa_flag.png'} alt="USA Flag" /> USD</Link></li>
                          </ul>
                        </Col>

                        <Col md={12} className="header2_search">
                          <div className="header2_search_dv">
                            <Navbar.Toggle aria-controls="navbarScroll3" />
                            <Navbar.Collapse id="navbarScroll3">
                              <Form className="d-flex" onSubmit={handleSubmit}>
                                <FormControl type="search"
                                  placeholder="Enter your keyword...."
                                  aria-label="Enter your keyword...."
                                  name="query"
                                  onChange={e => {
                                    setFormState({ ...formState, name: e.target.value });
                                  }}
                                  onKeyUp={handleClickOnEnter}
                                />
                                <Link ref={clickOnEnter} to={'/search-result?query=' + formState['name']}>
                                  <img src={process.env.PUBLIC_URL + '/assets/images/search.svg'} alt="Search" />
                                </Link>
                              </Form>

                              <div className="header2_cart">

                                <IconGroup />
                              </div>

                            </Navbar.Collapse>
                          </div>
                        </Col>

                      </div>

                    </Col>

                  </Row>

                </Col>
              </Row>
            </Container>
          </Row>


          {header3 === 'hide' ? <></> :
            <Row className="header3">



              <div className="container">


                <ul className="header3_menu2">
                  <li onClick={
                    () => {
                      handleActiveOnType();
                    }
                  }>

                    <Link to="/search-result?product_type=Auction" className={`${getAuction ? "active" : ""
                      }`}>
                      Zourie Auctions
                    </Link>
                  </li>

                  {categories && categories.map((category, index) => {
                    return (
                      <li
                        key={index}
                        onClick={
                          () => {
                            setgetCategoryIdFromUrl(category.id);
                            setAuction(null);
                            localStorage.setItem('category', category.id)
                          }
                        }
                      >
                        <Link
                          to={`/search-result?category=${category.id}&page=1`}
                          className={`${category.id == selectedCategory ? "active" : ""}`}
                        >
                          {category.category_name}
                        </Link>
                      </li>
                    )
                  })}

                </ul>


              </div>




            </Row>
          }


        </Container>
      </Navbar>








      <div className={` ${headerPaddingClass ? headerPaddingClass : ""} sticky-bar header-res-padding clearfix ${scroll > headerTop ? "not_stick" : ""}`} > </div>






      <MobileMenu />









    


    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
  categories: PropTypes.array,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(HeaderOne);
