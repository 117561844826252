import { NEW_ARRIVALS, BEST_SELLERS } from "../actions/newArrivalsActions";


const initialState = {
  newArrivals: []
}


const newArrivalsReducer = (state = initialState, action) => {
    if (action.type === NEW_ARRIVALS) {
      return {
        ...state,
        newArrivals: action.payload      
      };
    } else if (action.type === BEST_SELLERS) {
      return {
        ...state,
        bestSellers: action.payload
      };
    }

    return state;
};

export default newArrivalsReducer;