import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import axios from "axios";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import NewOrders from "../vendor/components/NewOrders";
import { GET_ORDERS } from "../../consts";
import { connect } from "react-redux";



import { DataGrid } from '@mui/x-data-grid';
import { Dropdown } from "react-bootstrap";
import { IconButton, TableCell } from "@mui/material";
import Sstest from "./MessagesDataTable";
import MessagesDataTable from "./MessagesDataTable";
import { useToasts } from "react-toast-notifications";
import { useHistory  } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', width: 117 },
  { field: 'From', headerName: 'From', width: 74 },
  { field: 'Subject', headerName: 'Subject', width: 122 },
  { field: 'Items', headerName: 'Items', width: 52 },
  { field: 'Received', headerName: 'Received', width: 130 },

  {
    name: "Delete",
    width: 100,
    options: {
      filter: false,
      sort: false,
   
      customBodyRenderLite: (dataIndex) => {
        return (
          <TableCell>
            <IconButton
            
            >
              <img src={process.env.PUBLIC_URL + "/assets/images/Star.svg"} alt="Delete" />
            </IconButton>
          </TableCell>
        );
      }
    }
  },
];

const rows = [
  { id: <button>123</button>, Subject: 'Snow', From: 'Jon', age: 35  },
  { id: 2, Subject: 'Lannister', From: 'Cersei', age: 42 },
  { id: 3, Subject: 'Lannister', From: 'Jaime', age: 45 },
  { id: 4, Subject: 'Stark', From: 'Arya', age: 16 },
  { id: 5, Subject: 'Targaryen', From: 'Daenerys', age: null },
  { id: 6, Subject: 'Melisandre', From: null, age: 150 },
  { id: 7, Subject: 'Clifford', From: 'Ferrara', age: 44 },
  { id: 8, Subject: 'Frances', From: 'Rossini', age: 36 },
  { id: 9, Subject: 'Roxie', From: 'Harvey', age: 65 },
];




const InboxMessages = (location) => {
  const { pathname } = location;
  const { addToast } = useToasts();
  const history = useHistory();

  return (
    <>

     <div className="head_msg">
     <p >Inbox: All Messages</p>
     </div>
      <div className="cart-main-area">
        <div className="row">
          <div className="col-12 ">
            <div className="header_table">
              <ul className="header_table2">
                <li>
                <Dropdown>
                  <Dropdown.Toggle className="btn_inbox" id="dropdown-basic">
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Read</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Unread</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Starred</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Unstarred</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>   
                </li>    
            
              </ul>

              <div>
                <MessagesDataTable addToast={addToast} history={history}/>
                
             
              </div> 
            </div>
         </div>          
        </div>
      </div>
    </>
  );
};

InboxMessages.propTypes = {
  location: PropTypes.object,
};

export default InboxMessages;
