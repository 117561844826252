import React, { useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { getSubsStatus } from '../../../redux/actions/subscriptionplanActions';


function SalesHistory(props) {
  
  const { salesHistory } = props

useEffect(()=>{
    getSubsStatus()
},[])
  return (
    <>
      <div className="das_box4">

        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <div className="das_box4_hd2">
              <h3>Sales History</h3>
            </div>
          </div>
          <div className='col-md-6 col-xs-12 text-right'>
        
          </div>
        </div>

        <BarChart className='bar_cart_degn1' width={750} height={250} data={
          salesHistory && salesHistory.map(item => {
            return {
              month: item.month,
              Sales: item.amount/100,
            }
          })
        } barSize={25} >
          <XAxis dataKey="month" scale="point" padding={{ left: 30, right: 15 }} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Sales" fill="#388087" />
        </BarChart> 

      </div>
    </>
  )
}

export default SalesHistory