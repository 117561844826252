import React, { Fragment, useState, useEffect, useReducer ,useRef} from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Autocomplete,

} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BootstrapSelect from "react-bootstrap-select-dropdown";
import moment from "moment";
import { Modal, Upload ,Button } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";


import LayoutOne from "../../layouts/LayoutOne";
import {
  saveProductImage,
  saveProducts,
} from "../../redux/actions/productActions";
import { PlusOutlined } from '@ant-design/icons';


import heic2any from "heic2any";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { UPLOAD_TO_GCP } from "../../consts";
import Loader from "../other/Loader";

import { IMAGEEDIT } from "../../consts";



import CropIcon from "@mui/icons-material/Crop";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FlipIcon from '@mui/icons-material/Flip';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  return current && current < moment().endOf("day");
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

function convertToJpg(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const fileBuffer = new Uint8Array(event.target.result);
        const convertedBlob = new Blob([fileBuffer]); // Convert Uint8Array to Blob

        const convertedBuffer = await heic2any({ blob: convertedBlob, toType: 'image/jpeg', quality: 0.8 });

        const originalFileName = file.name;
        const convertedFileName = originalFileName.replace(/\.[^.]+$/, '.jpg');

        const convertedFileSize = convertedBuffer.size;

        const convertedFile = new File([convertedBuffer], convertedFileName, { type: 'image/jpeg' });
        resolve(convertedFile);
      } catch (error) {
        console.error(error); 
        reject(new Error('Failed to convert HEIC to JPEG.'));
      }
    };
    reader.onerror = () => {
      reject(new Error('Failed to read file.'));
    };
    reader.readAsArrayBuffer(file);
  });
}




const AddListingPro = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subscriptionplans = useSelector((state) => state.subscriptionplans)

  const { addToast } = useToasts();

  const [sbactive] = useState("AddListing");
  const [msfMaxTab] = useState(3);
  const [msfCount, setMsfCount] = useState(1);



  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [, forceUpdate] = useReducer(x => x + 1, 0);














  const [fileList, setFileList] = useState([]);



  const beforeUpload = () => false;
  const handleCancel = () => setPreviewOpen(false);




  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });



    const handlePreview1 = async (file) => {
      setImageid(file.uid);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
  
    
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    }
  

  const [checkImage, setCheckImage] = useState(false)
  const [loading, setLoading] = useState(false)



  const handleChange = async (e) => {

    let lastFile = e.fileList[e.fileList.length - 1];

    const isLt2M = lastFile.size < 10000000;
    if (!isLt2M) {
      addToast("Image must be less than 10MB", {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }



    let extension = lastFile.name.split('.').pop().toLowerCase();
    let isValid = extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "heif" || extension === "heic";


    if (isValid) {
      if (lastFile.type === "image/heif" || lastFile.type === "image/heic") {
        let newFileList = e.fileList;
        let heif_heic_list = e.fileList.filter((item) => item.type === "image/heif" || item.type === "image/heic")

        let convertedFile = await convertToJpg(heif_heic_list[heif_heic_list.length - 1].originFileObj);

        setFileList(newFileList)
        requestData.product_image = convertedFile;
      } else {
        setFileList(e.fileList)
        requestData.product_image = e.fileList[e.fileList.length - 1]?.originFileObj;
      }

      dispatch(saveProductImage(
        requestData.product_image,
        setCheckImage,
        setLoading,
        addToast
      ))
      requestData.product_image = null;
    } else {

      addToast("Please upload only png, jpg, or jpeg image", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };






  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );











  const [listingItem, setListingItem] = useState(false)

  const [requestData, setrequestData] = useState({
    product_type: 'Pro',
    product_title: "",
    subtitle_checked: false,
    sub_title: "",
    description: "",
    upc: "",
    condition: "",
    condition_description: "",
    product_image: null,
   
    listing_start_opt: "",
    listing_start_time: null,
    fixed_buy_price: null,
    auction_start_date: null,
    auction_end_date: null,
    auction_start_price: null,
    auction_buy_price: null,
    shipping_calculation_type: 1,
    services: null,
    isFreeShipping: false,
    shipping_cost: 0,
    handling_time: "",
    marketing_option: null,
    marketing_option_per: null,
    fees: subscriptionplans && subscriptionplans.buyplan.plan.price_for_listing,
    published: false,
    brand: [],
    category: [],
    stock: null,
    returnable: 15,
    actual_price: null,
    free_shipping: false,
    domestic_shipping: null,
    coupon: null,
    promotedlisting: "",
    publish_status: "Draft",
    titlePrice: 0,
    photoSearchPrice: 0,
    keywords: "",
    bullet_points: [],
    manufacturer: "",
    product_dimensions: "",
    item_weight: "",
    country_of_origin: "",
    item_model_number: "",
    resourcess: [],
    assembly_required: "",
    warranty_description: "",
    additional_specifications: [],
    variations: [],
    proimage: [],
    offer_discount: 0,

  });



  const [descriptioninputField, setdescriptioninputField] = useState([{
    description: '',
    label: ""

  }]);
  const addInputField = () => {

    setdescriptioninputField([...descriptioninputField, {
      description: '',
      label: ""
    }])

  }
  const removedescriptioninputField = (index) => {
    const rows = [...descriptioninputField];
    rows.splice(index, 1);
    setdescriptioninputField(rows);
  }
  const handleChangedesc = (index, evnt) => {

    const { name, value } = evnt.target;
    const list = [...descriptioninputField];
    list[index][name] = value;
    setdescriptioninputField(list);
   
    setrequestData({
      ...requestData,
      additional_specifications: list
    });


  }




  const [varietinputField, setvarietinputField] = useState([{
    name: '',
    price: "",

  }]);

  const addvarietinputField = () => {
    setvarietinputField([...varietinputField, { name: '', price: '' }]);
  };

  const removevarietinputField = (index) => {
    const rows = [...varietinputField];
    rows.splice(index, 1);
    setvarietinputField(rows);
  }


  const handleChangevarietinputField = (index, event) => {
    const { name, value } = event.target;
    const list = [...varietinputField];
    list[index][name] = value;
    setvarietinputField(list);
    const updatedList = list.forEach((item, index) => {
      const indexValue = item.name.split('/');
      return item.related_product = indexValue[4];
    })

    setrequestData({
      ...requestData,
      variations: list
    });

  };

  const [bulletinputField, setbulletinputField] = useState([{
    name: ""

  }]);
  const addbulletinputField = () => {

    setbulletinputField([...bulletinputField, {
      name: ""
    }])

  }
  const removebulletinputField = (index) => {
    const rows = [...bulletinputField];
    rows.splice(index, 1);
    setbulletinputField(rows);
  }
  const handleChangebullet = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...bulletinputField];
    list[index][name] = value;
    setbulletinputField(list);
  
    setrequestData({
      ...requestData,
      bullet_points: list
    });
  }


  const [fileUpload, setfileUpload] = useState([{
    resource: null

  }]);
  const [disabledResourceUpload, setdisabledResourceUpload] = useState(false)

  const addInputFieldUpload = () => {

    setfileUpload([...fileUpload, {
      resource: null
    }])

  }


  const removeUploadInputField = (data) => {
    fileUpload.splice(data, 1);
    setfileUpload(fileUpload);
    forceUpdate();
  };



  const handleChangeUpload = async (index, evnt) => {
    const selectedFile = evnt.target.files[0];
  
    const fileType = selectedFile.type;
    const validFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!validFileTypes.includes(fileType)) {
      addToast("This file format is not supported", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (selectedFile.size > 20000000) {
      addToast("File size should be less than 20mb", {
        appearance: "error",
        autoDismiss: true,
      })
      return;
    }

    const newList = [...fileUpload];

    newList[index] = { ...newList[index], resource: selectedFile };

    setfileUpload(newList);

    setdisabledResourceUpload(true)

  };

  useEffect(() => {
    if (subscriptionplans.buyplan === null) {
      history.push("/seller-charges-and-fees");
    }
  }, []);



  useEffect(() => {
    setrequestData({
      ...requestData,
      product_image: fileList.length > 0 ? fileList : null,
    });


  }, [fileList])




  const handle_Msf_Next = () => {
    if (msfCount === 1) {
      const isVariationsValid = requestData.variations.every(item =>
        item.name && item.name.match(/^(ftp|http|https):\/\/[^ "]+$/)
      );

      if (!isVariationsValid) {
        addToast("Please enter valid URLs for products in the variations", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })

    if (msfCount === 2) {
      if (requestData.product_image === null) {
        addToast("Please upload a product image and click on the upload button", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }
    }

    setMsfCount(msfCount + 1);
  };

  const handle_Msf_Prev = (e) => {
    e.preventDefault();
    setMsfCount(msfCount - 1);
  };
  const handleClickSaveProduct = async (e) => {
    dispatch(
      saveProducts(
        {
          ...requestData,
          published: true,
          publish_status: "Published"
        },
        true,
        addToast,
        history
      )
    );
  };

  const handleClickSaveAsDraft = () => {

    dispatch(
      saveProducts(
        {
          ...requestData,
          published: false,
          publish_status: "Draft"
        },
        false,
        addToast,
        history
      )
    );
  };


  const [showDiv1, setShowDiv1] = useState(false);

  var boldTitlePrice = subscriptionplans && subscriptionplans.buyplan.plan.price_for_subtitle;

  const handleClick2 = (e) => {
    const checked = e.target.checked;
    setShowDiv1(!showDiv1);

    if (checked) {
      setrequestData({
        ...requestData,
        titlePrice: e.target.value,
        subtitle_checked: true,
        fees: requestData.fees + boldTitlePrice,
      });
    } else {
      setrequestData({
        ...requestData,
        titlePrice: 0,
        subtitle_checked: false,
        sub_title: '',
        fees: requestData.fees - boldTitlePrice,
      });
    }
  };


  var photoPrice = 0.35;

  const handleClick3 = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setrequestData({
        ...requestData,
        photoSearchPrice: e.target.value,
        fees: requestData.fees + photoPrice,
      });
    } else {
      setrequestData({
        ...requestData,
        photoSearchPrice: "",
        fees: requestData.fees - photoPrice
      });
    }
  };

  const [showCostDiv, setShowCostDiv] = useState(false);
  const [marketingCost, setMarketingCost] = useState(false);

  const handleCostClick = (e) => {
    const selected = e.selectedValue[0];

    setMarketingCost(e.selectedKey[0] === "0" ? true : false);

    if (selected !== "Select   ") {
      setrequestData({
        ...requestData,
        marketingOption: selected,
        marketingOptionCost: e.target,
      });
    } else {
      setrequestData({
        ...requestData,
        marketingOption: "",
        marketingOptionCost: "",
      });
    }
  };

  const stripe = useStripe();
  const elements = useElements();

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "rgb(51 51 51)",
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };


  const marketingOptionSelect = [
    {
      labelKey: "0",
      value: "Select Marketing Option",
    },
    {
      labelKey: "2",
      value: "Promoted Listing",
    },
  ];

  const handleData = (e) => {
    const checked = e.target.checked;

    setShowCostDiv(!showCostDiv);

    if (checked) {
      setrequestData({
        ...requestData,
        shipping_cost: 0,
      });
    } else {
      setrequestData({
        ...requestData,
        shipping_cost: 0,
      });
    }
  };

  const handleListingDate = (e) => {


    setListingItem(true);
    setrequestData({
      ...requestData,
      listing_start_time: e.target.value,
    });


  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const onSubmit1 = (data) => {
    console.log(data);
  };

  const onSubmit2 = (data) => {
    console.log(data);
    handleClickSaveProduct();
  };

  function PercentagePerSale(e) {
    if (e.target.value <= 100) {
      setrequestData({
        ...requestData,
        marketing_option_per: e.target.value,
      })
    }
  }

  const [value, setValue] = useState("15 Days");
  const [value1, setValue1] = useState("standard");
  const handleReturnProduct = (event) => {
    const text = event.target.value;
    let newStr = text.replace(" Days", "");


    setValue(event.target.value);
    setrequestData({
      ...requestData,
      returnable: newStr,
    });
  };
  const handleitemdesctype = (event) => {
    const text = event.target.value;


    setValue1(event.target.value);
    setrequestData({
      ...requestData,
      itemdesctype: text,
    });
  };





  const handleResourcesUpload = async () => {
    const invalidExtensions = fileUpload.filter(
      (file) =>
        file.resource.type !== "application/pdf" &&
        file.resource.type !== "application/msword"
    );

    const exceededSizeFiles = fileUpload.filter(
      (file) => file.resource.size > 10000000
    );

    let toastMessage = "";

    if (invalidExtensions.length > 0) {
      const invalidFiles = invalidExtensions.map((file) => file.resource.name);
      toastMessage += `Please upload only pdf or doc files:\n${invalidFiles.join("\n")}\n`;
    }

    if (exceededSizeFiles.length > 0) {
      const exceededFiles = exceededSizeFiles.map((file) => file.resource.name);
      toastMessage += `The following files should be less than 10MB:\n${exceededFiles.join("\n")}\n`;
    }

    if (toastMessage) {
      addToast(toastMessage.trim(), {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: true,
      });
      return;
    }

    const formData = new FormData();
    fileUpload.forEach((file) => formData.append("file", file.resource));


    const token = localStorage.getItem("Token");
    setLoading(true)
    axios.post(UPLOAD_TO_GCP, formData, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(response => {
        if (response.status === 201) {
       

          response.data.public_urls.forEach((url, index) => {
            requestData.resourcess.push({
              resource: url
            });
          });
          setLoading(false)
          addToast && addToast("File uploaded successfully", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true,
          });
        }
      })
      .catch(error => {
        console.log(error);
        addToast && addToast("File uploaded failure", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: true,
        });
        setLoading(false)
      });


  };






  const [showPercentageDiv, setShowPercentageDiv] = useState(false);

  const handleMarketingOption = (e) => {

    if (e.target.value == 'Promoted Listing') {
      setShowPercentageDiv(true);
      setrequestData({
        ...requestData,
        marketing_option: e.target.value,
      })
    } else {
      setShowPercentageDiv(false);
      setrequestData({
        ...requestData,
        marketing_option: e.target.value,
      })
    }
  }



  const [imageContent, setImageContent] = useState([]);
  const [isProImageUploading, setIsProImageUploading] = useState(false);

  const handleChangeProimages = (e) => {
    const files = e.target.files;
    const proimagesArray = Array.from(files);

    setImageContent(proimagesArray)
    setIsProImageUploading(true)
  };


  const handleProImageUpload = () => {
    const token = localStorage.getItem("Token");
    setLoading(true);
    const convertedImagesPromises = imageContent.map(async (file) => {
      if (file.name.endsWith(".heic") || file.name.endsWith(".heif")) {
        return await convertToJpg(file);
      }
      return file;
    });

    Promise.all(convertedImagesPromises)
      .then(async (convertedImages) => {
        const formData = new FormData();
        convertedImages.forEach((file) => formData.append("file", file));

        try {
          const response = await axios.post(UPLOAD_TO_GCP, formData, {
            headers: {
              Authorization: "Token " + token,
            },
          });

          if (response.status === 201) {
            const newPublicUrls = response.data.public_urls;
            newPublicUrls.forEach((url, index) => {
              requestData.proimage.push({
                proimage: url,
              });
            });
            addToast && addToast("File uploaded successfully", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: true,
            });
          }
        } catch (error) {
          console.log(error);
          addToast && addToast("File uploaded failure", {
            appearance: "error",
            autoDismiss: true,
            pauseOnHover: true,
          });
        } finally {
          setLoading(false);
        }
      })
      .catch((error) => {
       
        setLoading(false);
      });
  };


  const SPECIFICATIONS_MAX_DESCRIPTION_LENGTH = 250;


  const handleChangeStock = (e) => {
    const value = e.target.value;

    if (value.trim() === '') {
      setrequestData({
        ...requestData,
        stock: null
      });
      return;
    }

    const numericValue = parseInt(value, 10);

    if (!isNaN(numericValue) && numericValue <= 100) {
      setrequestData({
        ...requestData,
        stock: numericValue,
      });
    }
  };


    const [fixedPrice , setFixedPrice] = useState(null);
    const [marketingOptionPercentage , setMarketingOptionPercentage] = useState(null);
    const [discounOptionPercentage ,setDiscounOptionPercentage] = useState(null);
    const [percentageAmount , setPercentageAmount] = useState(null);

   
  
    useEffect(() => { 
      const calculatePercentageAmount = (variable, percentage) => {
       
        return (variable * percentage) / 100;
      };
  
      const calculatedPercentageAmount = calculatePercentageAmount(fixedPrice, marketingOptionPercentage);

      setPercentageAmount(calculatedPercentageAmount);


  
    }, [fixedPrice, marketingOptionPercentage,discounOptionPercentage]);
    
  
    
  const imageIdNew = useSelector((state) => state.productData?.productImage);
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [imageid, setImageid] = useState(null);

  const showModalHandler = () => {
    if (file) {
      setShowModal(true);
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };
  const changeImageFileName = (file) => {
    function generateRandomString(length) {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let randomString = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
      }
      return randomString;
    }

    const fileName = file.name; // Get the file name from the file object
    const randomWords = generateRandomString(5); // Change the number to specify the length of random words
    const fileExtension = fileName.split('.').pop(); // Get the file extension
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.'); // Get file name without extension

    const newFileName = `${fileNameWithoutExtension}_${randomWords}.${fileExtension}`;

    const updatedFile = new File([file], newFileName, { type: file.type });

    return updatedFile;
  }

  const handleSaveImage = async (editedFile) => {
    const imageFile = await changeImageFileName(editedFile);
    const compressedFile = await compressImage(imageFile);

    const token = localStorage.getItem("Token");
    let formData = new FormData();

    formData.append("upload_image", compressedFile);
    axios.put(IMAGEEDIT + imageIdNew[0].id + '/', formData, {
      headers: {
        Authorization: "Token " + token,
      }
    }).then(async (response) => {
   
      addToast("Image Edited Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      editedFile.uid = imageid;
      const base64Image = await getBase64(editedFile);

      const updatedFileList = fileList.map(item => {
        if (item.uid === imageid) {
          return {
            ...item,
            originFileObj: editedFile,
            thumbUrl: base64Image,
          };
        }
        return item;
      });

      setFileList(updatedFileList);
      setShowModal(false);
    }).catch((error) => {
      console.log(error, 'error');
      addToast("Image Edited Failed", {
        appearance: "error",
        autoDismiss: true,
      });
    })

  };


  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const maxWidth = 1024; // Adjust this value as needed
          const maxHeight = 1024; // Adjust this value as needed
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            'image/jpeg', 
            0.7 
          );
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const cropperRef = useRef();
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [cropperInstance, setCropperInstance] = useState(null);
  const [cropMode, setCropMode] = useState(false);
  const [cropper, setCropper] = useState(null);

  const flipImage = (type) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (type === "h") {
      cropper.scaleX(scaleX === 1 ? -1 : 1);
      setScaleX(scaleX === 1 ? -1 : 1);
    } else {
      cropper.scaleY(scaleY === 1 ? -1 : 1);
      setScaleY(scaleY === 1 ? -1 : 1);
    }

    setEditMode(true);

  };

  const rotate = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(90);
    if (cropperInstance) {
      cropperInstance.disable(); 

      cropperInstance.rotate();

      const imageData = cropperInstance.getImageData();
      const newAspectRatio = imageData.width / imageData.height;
      cropperInstance.setAspectRatio(newAspectRatio);


      setTimeout(() => {
        cropperInstance.enable();
      }, 500);
    }
    const imageData = cropper.getImageData();
    const newAspectRatio = imageData.width / imageData.height;
    cropper.setAspectRatio(newAspectRatio);
    setEditMode(true);
  };

  const startCrop = () => {
    setCropMode(true);
    setEditMode(true);
  };

  const handleSaveImage3 = async (editedFile) => {
  

    const imageFile = await changeImageFileName(editedFile);

    const token = localStorage.getItem("Token");
    let formData = new FormData();

    formData.append("upload_image", imageFile);
    axios
      .put(IMAGEEDIT + imageIdNew[0].id + "/", formData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then(async (response) => {

        addToast("Image Edited Successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        editedFile.uid = imageid;
        const base64Image = await getBase64(imageFile);

        const updatedFileList = fileList.map((item) => {
          if (item.uid === imageid) {
            return {
              ...item,
              originFileObj: Base64ToImageFile({ base64Image: previewImage }),
              thumbUrl: base64Image,
              preview: base64Image,
            };
          }
          return item;
        });
        setFileList(updatedFileList);

        setShowModal(false);
      })
      .catch((error) => {
        console.log(error, "error");
        addToast("Image Edited Failed", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleCrop = () => {
  
    const imageElement = cropperRef?.current;
    const cropperdata = imageElement?.cropper;
    if (cropperdata) {
      const croppedDataUrl = cropperdata.getCroppedCanvas().toDataURL();
    

     
      const base64String = croppedDataUrl.replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );

     
      const byteCharacters = new Uint8Array(
        atob(base64String)
          .split("")
          .map((char) => char.charCodeAt(0))
      );

      const file = new File([byteCharacters], previewTitle, {
        type: "image/png",
      });
      handleSaveImage3(file);

      handleCancel();
    }
  };

  const onCropperInitialized = (cropper) => {
    setCropper(cropper);
  };

  
  const Base64ToImageFile = ({ base64Image }) => {
    const base64ToBlob = (base64) => {
      const byteString = atob(base64);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: "image/png" }); 
    };

    const handleImageConversion = () => {
      try {
        const blob = base64ToBlob(base64Image);
        const file = new File([blob], "image.png", { type: "image/png" });

      
      } catch (error) {
        console.error("Error converting base64 to file:", error);
      }
    };

    handleImageConversion();
  };





  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Add Listing</title>
        <meta
          name="description"
          content="Compare page of Zourie react minimalist eCommerce template."
        />
      </MetaTags>
      <LayoutOne headerTop="visible" header3="hide">
        {loading && <Loader />}
        <section className="information_panels">
          <div className="container">
            <div className="row" style={{
              background: '#FAFAFA',

              /* Shadow/02 */
              boxShadow: '0px 8px 30px 2px rgba(19, 80, 124, 0.05)'
            }}>
              

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 info_pnl_body">
                <div className="info_pnl_body_dv">
                  <div className="msf_header">
                    <ul>
                      <li className={`${msfCount > 0 && "msf_act"}`}>
                        <p>Description</p>
                      </li>
                      <li className={`${msfCount > 1 && "msf_act"}`}>
                        <p>Photos or Images</p>
                      </li>
                      <li className={`${msfCount > 2 && "msf_act"}`}>
                        <p>Selling & Shipping</p>
                      </li>
                    </ul>
                  </div>

                  <div className="msf_body">
                    <form
                      className="multi_step_form_wrrpr"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div
                        className={`msf_tab ${msfCount === 1 && "msf_tab_act"}`}
                      >
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="myform_dv">
                              <label>
                                Category <span>*</span>
                              </label>
                              <Controller
                                name="category"
                                control={control}
                                rules={{ required: "Product Category is required" }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={props.category}
                                    getOptionLabel={(option) => option.category_name}
                                    filterSelectedOptions
                                    value={value}
                                    onChange={(event, value) => {
                                      onChange(value);
                                      setrequestData({
                                        ...requestData,
                                        category: value,
                                      });
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Product Category"
                                        className="myform_field"
                                        error={Boolean(errors.category)}
                                        helperText={errors.category?.message}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="myform_dv">
                              <label>
                                Title <span>*</span>
                              </label>
                              <input
                                type="text"
                                name="product_title"
                                maxLength="70"
                                value={requestData.product_title}
                                placeholder="Ex: Zourie’s Special Handmade Product"
                                {...register("product_title", {
                                  required: true,
                                  value: requestData.product_title,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      product_title: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.product_title && "valid_error_inpt"
                                  }`}
                              />
                              {errors.product_title && (
                                <p className="valid_error_txt">
                                  Product Title is required
                                </p>
                              )}
                              <code>Only 70 character(s)</code>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-6 col-xs-12">
                            <div
                              className="myform_dv"
                              style={{ display: !showDiv1 && "none" }}
                            >
                              <label>Subtitle</label>
                              <input
                                type="text"
                                className="myform_field"
                                onChange={(e) =>
                                  setrequestData({
                                    ...requestData,
                                    sub_title: e.target.value,
                                  })
                                }
                                maxLength="220"
                                value={requestData.sub_title}
                                placeholder="Ex: Zourie’s Special Handmade Product"
                              />
                              <code>Only 220 character(s)</code>
                            </div>
                            <div
                              className="myform_dv_chk"
                              style={{ marginTop: "-5px" }}
                            >
                              <input
                                className="styled-checkbox"
                                id="pr_over_100"
                                type="checkbox"
                                value={boldTitlePrice}
                                onClick={handleClick2}
                              />
                              <label htmlFor="pr_over_100">
                                Stand out with a bold title in search results
                                (${subscriptionplans.buyplan.plan && subscriptionplans.buyplan.plan.price_for_subtitle})
                              </label>
                            </div>
                          </div>



                          <div className="col-md-12">
                            <div className="myform_dv listing-keyword-col">
                              <label>
                                Keywords <span>*</span>
                              </label>
                              <input
                                type="text"
                                name="keywords"
                                maxLength="200"
                                placeholder="Ex: vintage, antique, handmade"
                                {...register("keywords", {
                                  required: true,
                                  value: requestData.keywords,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      keywords: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.keywords && "valid_error_inpt"
                                  }`}
                              />

                              <code>Extend your customer reach by adding key words associated with your product.</code>
                              {errors.keywords && (
                                <p className="valid_error_txt">
                                  Keywords is required
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="myform_dv">
                              <label>
                                UPC
                              </label>

                              <TextField
                                label="Choose Upc"
                                inputProps={{
                                  maxLength: 30,
                                }}
                                onChange={(e) => {
                                  setrequestData({
                                    ...requestData,
                                    upc: e.target.value,
                                  });
                                }}
                                value={requestData.upc}
                                variant="outlined"
                                className="myform_field mf_styl2"
                              >
                              </TextField>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="myform_dv listing-keyword-col">
                              <label>
                                Condition <span>*</span>
                              </label>

                              <TextField
                                select
                                label="Choose Condition"
                                {...register("condition", {
                                  required: true,
                                  value: requestData.condition,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      condition: e.target.value,
                                    });
                                  },
                                })}
                                value={requestData.condition}
                                variant="outlined"
                                error={errors.condition}
                                helperText={
                                  errors.condition && "Condition is required"
                                }
                                className="myform_field mf_styl2"
                              >
                                <MenuItem value={"New"}>New</MenuItem>
                                <MenuItem value={"Used"}>Used</MenuItem>
                              </TextField>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="myform_dv listing-keyword-col">
                              <label>
                                Condition Description <span>*</span>
                              </label>
                              <textarea
                                id=""
                                value={requestData.condition_description}
                                maxLength="1200"
                                {...register("condition_description", {
                                  required: true,
                                  value: requestData.condition_description,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      condition_description: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.condition_description &&
                                  "valid_error_inpt"
                                  }`}
                                rows="5"
                              ></textarea>
                              {errors.condition_description && (
                                <p className="valid_error_txt">
                                  Condition Description is required
                                </p>
                              )}
                              <code>Only 1200 character(s)</code>
                            </div>
                          </div>



                          <div className="col-12">
                            <div className="mytext_dv row">
                              <label className="col-lg-3 col-md-3 col-12">
                                Item Description
                              </label>
                            </div>
                            <div className="myform_dv_edtr">
                              <CKEditor
                                editor={ClassicEditor}
                                info="<p></p>"
                                config={{
                                  toolbar: ['bold', 'numberedList',
                                    'bulletedList', 'source'],

                                }}
                                value={requestData.description}
                                onChange={(event, editor) => {
                                  const info = editor.getData();
                                  setrequestData({
                                    ...requestData,
                                    description: info,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">

                          </div>

                          {
                            bulletinputField.map((data, index) => {
                              const { name } = data;
                              return (

                                <div className="col-md-12 col-sm-12 col-xs-12" key={index}>


                                  <div className="myform_dv listing-keyword-col add-bullets-col row">
                                    <div className="col-11">
                                      <label>
                                        Add Bullet Points
                                      </label>
                                      <input
                                        type="text"
                                        name="name"
                                        maxLength="200"
                                        value={name}
                                        onChange={(e) => {
                                          handleChangebullet(index, e)
                                        }}

                                        className={`myform_field ${errors.bullet_points && "valid_error_inpt"
                                          }`}
                                      />
                                    </div>
                                    <div className="col-1">
                                      {(bulletinputField.length !== 1) ? <span className="removebtn btn btn-outline-danger" onClick={removebulletinputField}>x</span> : ''}
                                    </div>


                                  </div>




                                </div>
                              )
                            })
                          }
                          <div className="col-12" style={{ marginBottom: '20px' }}>
                            <span className=" myform_field btncss" onClick={addbulletinputField}>Add New</span>
                          </div>


                          <div className="col-12">
                            <div className="myform_dv">
                              <label>
                                Product Specifications <span>*</span>

                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-6">
                            <div className="mysub myform_dv">
                              <label>
                                Manufacturer
                              </label>
                              <input
                                type="text"
                                name="manufacturer"
                                maxLength="70"
                                placeholder="Ex: W Appliance Company"
                                {...register("manufacturer", {
                                  required: true,
                                  value: requestData.manufacturer,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      manufacturer: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.manufacturer && "valid_error_inpt"
                                  }`}
                              />
                              {errors.manufacturer && (
                                <p className="valid_error_txt">
                                  Product Manufacturer is required
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-6">
                            <div className="mysub myform_dv">
                              <label>
                                Product Dimensions
                              </label>
                              <input
                                type="text"
                                name="product_dimensions"
                                maxLength="70"
                                placeholder="Ex: 123 x 131 x 133 inches"
                                {...register("product_dimensions", {
                                  required: true,
                                  value: requestData.product_dimensions,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      product_dimensions: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.product_dimensions && "valid_error_inpt"
                                  }`}
                              />
                              {errors.product_dimensions && (
                                <p className="valid_error_txt">
                                  Product Dimensions is required
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-6">
                            <div className="mysub myform_dv">
                              <label>
                                Item Weight
                              </label>
                              <input
                                type="text"
                                name="item_weight"
                                maxLength="70"
                                placeholder="Ex: 46.5 pounds"
                                {...register("item_weight", {
                                  required: true,
                                  value: requestData.item_weight,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      item_weight: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.item_weight && "valid_error_inpt"
                                  }`}
                              />
                              {errors.item_weight && (
                                <p className="valid_error_txt">
                                  Item Weight is required
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-6">
                            <div className="mysub myform_dv">
                              <label>
                                Country of Origin
                              </label>
                              <input
                                type="text"
                                name="country_of_origin"
                                maxLength="70"
                                placeholder="Ex: China"
                                {...register("country_of_origin", {
                                  required: true,
                                  value: requestData.country_of_origin,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      country_of_origin: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.country_of_origin && "valid_error_inpt"
                                  }`}
                              />
                              {errors.country_of_origin && (
                                <p className="valid_error_txt">
                                  Country of Origin is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-6 col-xs-6">
                            <div className="mysub myform_dv">
                              <label>
                                Item Model Number
                              </label>
                              <input
                                type="text"
                                name="item_model_number"
                                maxLength="70"
                                placeholder="Ex: BPACTI3HWT"
                                {...register("item_model_number", {
                                  required: true,
                                  value: requestData.item_model_number,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      item_model_number: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.item_model_number && "valid_error_inpt"
                                  }`}
                              />
                              {errors.item_model_number && (
                                <p className="valid_error_txt">
                                  Item Model Number is required
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-6">
                            <div className="mysub myform_dv">
                              <label>
                                Assembly required
                              </label>
                              <input
                                type="text"
                                name="assembly_required"
                                maxLength="70"
                                placeholder="Ex: No"
                                {...register("assembly_required", {
                                  required: true,
                                  value: requestData.assembly_required,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      assembly_required: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.assembly_required && "valid_error_inpt"
                                  }`}
                              />
                              {errors.assembly_required && (
                                <p className="valid_error_txt">
                                  Assembly is required
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-6">
                            <div className="mysub myform_dv">
                              <label>
                                Warranty Description
                              </label>
                              <input
                                type="text"
                                name="warranty_description"
                                maxLength="70"
                                placeholder="Ex: 1 year"
                                {...register("warranty_description", {
                                  required: true,
                                  value: requestData.warranty_description,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      warranty_description: e.target.value,
                                    });
                                  },
                                })}
                                className={`myform_field ${errors.warranty_description && "valid_error_inpt"
                                  }`}
                              />
                              {errors.warranty_description && (
                                <p className="valid_error_txt">
                                  Warranty Description is required
                                </p>
                              )}
                            </div>
                          </div><br />
                          <div className="col-12"></div>
                      


                          <div className="col-12 appending_section_form appending_section_form_mrg">



                            {
                              descriptioninputField.map((data, index) => {
                                const { description, label } = data;
                                return (

                                  <div className="row additional-specification-row" key={index}>


                                    <div className="col-md-6">
                                      <div className="myform_dv">
                                        <label className="textCSS">
                                          Add Additional Specifications
                                        </label>
                                        <input
                                          type="text"
                                          name="label"
                                          maxLength="70"
                                          placeholder="Ex: Included Components"
                                          value={label}
                                          onChange={(e) => {
                                            handleChangedesc(index, e)
                                          }}

                                          className={`myform_field ${errors.label && "valid_error_inpt"
                                            }`}
                                        />
                                        {errors.label && (
                                          <p className="valid_error_txt">
                                            Label is required
                                          </p>
                                        )}
                                      </div>

                                    </div>
                                    <div className="col-md-6">
                                      {(descriptioninputField.length !== 1) ? <span className="removebtn btn btn-outline-danger" onClick={removedescriptioninputField}>x</span> : ''}
                                    </div>
                                    <div className="col-12">
                                      <div id="contt" className="myform_dv">

                                        <textarea
                                     
                                          inputProps={{
                                            maxLength: 250
                                          }}
                                          name="description"
                                          placeholder="Ex: Description"
                                          value={description}
                                          onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue.length <= SPECIFICATIONS_MAX_DESCRIPTION_LENGTH) {
                                              handleChangedesc(index, e);
                                            }
                                          }}
                                          className={`myform_field ${errors.description && "valid_error_inpt"}`}
                                          style={{ height: '100px' }}
                                        />
                                        {errors.description && (
                                          <p className="valid_error_txt">
                                            Description is required
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>

                          <div className="col-12" style={{ marginBottom: '20px' }}>
                            <span className=" myform_field btncss add-bupxtton" onClick={addInputField}>Add New</span>
                          </div>

                          <div className="col-12 appending_section_form">


                            {fileUpload.map((data, index) => {
                              return (
                                <div className="row align-items-center">
                                  <div className="col-md-4 col-sm-4 col-xs-4">
                                    <div className="myform_dv" key={index}>
                                      <label> Additional Recources </label>
                                      <div className="col-12"></div>
                                      <div className="myform_dv additional-resources-label" style={{ marginBottom: '25px' }}>
                                        <div className="fileuploadinput">
                                          {data.resource ? (
                                            <>
                                              Selected File: {data.resource.name}
                                            </>
                                          ) : (
                                            <input
                                              type="file"
                                              name="resource"
                                              onChange={(e) => handleChangeUpload(index, e)}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="col-md-4 col-sm-4 col-xs-4" style={{ paddingTop: '9px' }}>
                                    {(fileUpload.length !== 1) ?
                                      <label htmlFor="resource">
                                        <span className="removebtn btn btn-outline-danger" onClick={() => removeUploadInputField(index)}>x</span>
                                      </label>
                                      : ''}
                                  </div>
                                </div>
                              )
                            })}
                          </div>



                          <div className="col-12 resource-btn" style={{ marginBottom: '20px' }}>
                            <input type="button" className="add_new_btn myform_field btncss"
                              disabled={!fileUpload[0].resource}
                              onClick={addInputFieldUpload} value="Add New" />

                            <input type="button" className="upload-btn"
                              disabled={!disabledResourceUpload}
                              onClick={() => handleResourcesUpload()} value="Upload" />
                          </div>




                          <div className="col-12">
                            <div className="myform_dv">
                              <label>
                                Variate
                              </label>
                            </div>
                          </div>


                          <div className="col-12 appending_section_form">
                            {
                              varietinputField.map((data, index) => {
                                const { name, price } = data;
                                return (

                                  <div className="row align-items-center" key={index}>

                                    <div className="col-md-4 col-sm-4 col-xs-4">
                                      <div className="mysub myform_dv">
                                        <label>
                                          Variate Link
                                        </label>

                                        <input
                                          type="text"
                                          name="name"
                                          maxLength="100"
                                          placeholder="Ex: Classic Charm"
                                          value={name}
                                          onChange={(e) => {
                                            handleChangevarietinputField(index, e)
                                          }}

                                          className="myform_field"
                                        />

                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-4">
                                      <div className="mysub myform_dv">
                                        <label>
                                          Price
                                        </label>

                                        <input
                                          type="text"
                                          name="price"
                                          maxLength="10"
                                          value={price}
                                          onChange={(e) => {
                                            handleChangevarietinputField(index, e)
                                          }}
                                          pattern="[0-9]*"
                                          className="myform_field"

                                          placeholder="$"
                                        />
                                        {errors.varietinputField && errors.varietinputField[index] && errors.varietinputField[index].price && (
                                          <p className="valid_error_txt">{errors.varietinputField[index].price.message}</p>
                                        )}
                                      </div>
                                    </div>




                                    <div className="col removebtn">



                                      {(varietinputField.length !== 1) ? <span className="removebtn btn btn-outline-danger" onClick={removevarietinputField}>x</span> : ''}


                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>

                          <div className="col-12" style={{ marginBottom: '20px' }}>
                            <span className="myform_field btncss" onClick={addvarietinputField}>Add New</span>
                          </div>



                        </div>
                      </div>
                    </form>
                    <form onSubmit={handleSubmit1(onSubmit1)}>
                      <div
                        className={`msf_tab ${msfCount === 2 && "msf_tab_act"}`}
                      >
                        <div className="row">


                          <div className="col-12">
                            <div className="myform_dv">
                              <label>
                                Photos or Images + Media Clip <span>*</span>
                              </label>
                              <div className="myform_muploader custom-file-uploader">
                                <Upload
                                  listType="picture-card"
                                  fileList={fileList}
                                  onPreview={handlePreview1}
                                  onChange={(e) => handleChange(e)}
                                  beforeUpload={beforeUpload}
                                  name="images"
                                  type="file"
                                >
                                  {fileList.length >= 7 ? null : uploadButton}
                                </Upload>
                             
                                <Modal
                                  open={previewOpen}
                                  title={previewTitle}
                                  className="edit-image-modal"
                                  setFileDatafooter={null}
                                  onCancel={handleCancel}
                                 
                                  footer={[
                                    <span className="image-editing-buttons">
                                      <RotateLeftIcon key="rotateLeft" onClick={rotate} />
                                    </span>
                                    ,
                                    <span className="image-editing-buttons">
                                      <FlipIcon key="flipHorizontal"
                                        onClick={() => flipImage("h")} />
                                    </span>
                                    ,
                                    <span className="image-editing-buttons">
                                      <FlipIcon style={{ transform: "rotate(90deg)" }}
                                        key="flipVertical"
                                        onClick={() => flipImage("v")}
                                      />
                                    </span>,
                                    <span className="image-editing-buttons">
                                      <CropIcon key="startCrop"
                                        type="primary"
                                        onClick={startCrop} />
                                    </span>,
                                    <span >
                                      <Button
                                        key="crop"
                                        type="butto"
                                        onClick={() => handleCrop()}
                                      >
                                        Save
                                      </Button>
                                    </span>
                                  ]}
                                >

                                  <Cropper
                                    src={previewImage}
                                    initialAspectRatio={NaN}
                                    guides={false}
                                    zoomOnWheel={false}
                                    autoCropArea={1}
                                    ref={(cropper) => {
                                      setCropperInstance(cropper);
                                      cropperRef.current = cropper;
                                    }}
                                    className={cropMode ? "cropper" : "hidden-cropper cropper"}
                                  />

                              
                                </Modal>

                              </div>
                              <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <ul className="myform_dv_list" style={{ marginBottom: 20 }}>
                                    <li>
                                      * Add up to 7 photos. We don't allow photos with extra borders, text or artwork.
                                    </li>
                                    <li>
                                      **  Double click on the image to select featured.
                                    </li>
                                    <li>
                                      *** Change images order with Drag & Drop.
                                    </li>
                                    <li>
                                      **** Images might take longer to be processed.
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right checkbox-content">
                                  <div
                                    className="myform_dv_chk"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    <input
                                      className="styled-checkbox"
                                      id="large_photo"
                                      onClick={handleClick3}
                                      value={photoPrice}
                                      type="checkbox"
                                      name="large_photo"
                                      style={{ width: "auto" }}

                                    />
                                    <label htmlFor="large_photo">
                                      Display a large photo in search results
                                      with Gallery Plus($0.35)
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="myform_dv">
                                    <label>
                                      Pro Image Content
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <div className="myform_dv">
                                    <input
                                      type="file"
                                      name="proimage"
                                      placeholder="Drag and drop files or add links"
                                      multiple
                                      onChange={handleChangeProimages}
                                      className={`col-6 myform_field ${errors.proimage && "valid_error_inpt"
                                        }`}
                                    />

                                    <label htmlFor="contained-button-file2">
                                      <span variant="contained" className="msf_footer uploadbtn col-md-2 col-sm-1 col-xs-2" sx={{
                                        ml: 1, mb: -1.5, backgroundColor: 'white', boxShadow: 'none', '@media (max-width:600px)': {
                                          ml: -2, mb: 0
                                        }
                                      }} >
                                        <input style={{ margin: 0, padding: '12px 18px', width: 'auto', maxWidth: '100px' }}
                                          type="button"
                                          onClick={() => handleProImageUpload()}
                                          disabled={!isProImageUploading}
                                          className="button1" value="Upload" />
                                      </span>
                                    </label>

                                  </div>
                                  <ul className="myform_dv_list" style={{ marginBottom: 20, marginTop: -20 }}>
                                    <li sx={{ mt: -20 }}>
                                      These images will be displayed in the body the listing
                                    </li>
                                  </ul>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <form onSubmit={handleSubmit2(onSubmit2)}>
                      <div
                        className={`msf_tab ${msfCount === 3 && "msf_tab_act"}`}
                      >




                        <div className="">


                          {requestData.product_type === "Pro" &&
                            <>
                              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <div className="myform_dv">
                                  <label>Fixed Price Listing<span>*</span></label>
                                  <TextField
                                    label="Fixed Price Listing"
                                    name="actual_price"
                                    {...register2("actual_price", {
                                      required: 'Price is required',
                                      value: '', 
                                      pattern: {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message: "Price should be a number",
                                      },
                                      validate: {
                                        greaterThanZero: (value) => parseFloat(value) > 0 || "Price should be greater than 0",
                                        lessThanMaxValue: (value) => parseFloat(value) <= 10000 || "Price should be less than or equal to 10000",
                                      },
                                      onChange: (e) =>  {
                                        const inputValue = e.target.value;
                                        const validValue = inputValue
                                          .replace(/[^\d.]/g, '')
                                          .replace(/^0+(?=\d)/, '')       
                                          .replace(/^(\d*\.\d*)\..*$/, '$1')
                                        setrequestData({
                                          ...requestData,
                                          actual_price: validValue.slice(0, 100000)
                                        });
                                        setFixedPrice(validValue.slice(0, 100000));
                                      },

                                
                                    })}
                                    className={`myform_field ${errors2.actual_price && "valid_error_inpt"}`}
                                    value={requestData.actual_price}
                                    placeholder="$"
                                    variant="outlined"
                                    error={!!errors2.actual_price}
                                    helperText={errors2.actual_price?.message}
                                  />

                                </div>
                              </div>

                              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="myform_dv">
                                  <label>Quantity<span>*</span></label>
                                  <TextField
                                    label="Quantity"
                                    {...register2("stock", {
                                      required: true,
                                      value: requestData.stock,
                                      pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Only numbers are allowed",
                                      }
                                    })}
                                    onChange={handleChangeStock}
                                    value={requestData.stock}
                                    variant="outlined"
                                    error={errors2.stock}
                                    helperText={
                                      errors2.stock && "Quantity is required"
                                    }
                                    className="myform_field mf_styl2"
                                  >
                                  </TextField>

                                </div>
                              </div>
                            </>}
                        </div>
                        <div style={{ marginLeft: '1px' }} className="row">
                          {requestData.product_type === "Pro" &&
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                <div className="myform_dv myform_dv_radio">
                                  <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Return Option</FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      value={value}
                                      onChange={(event) => handleReturnProduct(event)}
                                      name="row-radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        className="label_radio"
                                        value="15 Days" control={<Radio />} label="15 Days" />
                                      <FormControlLabel
                                        className="label_radio"
                                        value="30 Days" control={<Radio />} label="30 Days" />
                                    </RadioGroup>
                                  </FormControl>
                                </div>

                              </div>
                           
                            </>
                          }

                          <div className="col-12">
                            <div className="myform_dv">
                              <p>
                                Shipping rate table <br />
                                You can set the shipping rates for services to
                                specific countries and region. Create
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="myform_dv">
                              <label>Services <span>*</span> <strong>(Calculate Shipping)</strong></label>
                              <TextField
                                select
                                label="Choose Services"
                                {...register2("services", {
                                  required: true,
                                  value: requestData.services,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      services: e.target.value,
                                    });
                                  },
                                })}
                                value={requestData.services}
                                variant="outlined"
                                error={errors2.services}
                                helperText={
                                  errors2.services && "Services is required"
                                }
                                className="myform_field mf_styl2"

                              >
                                <MenuItem value={"USPS Priority Mail (5 to 10 business days)"}>USPS Priority Mail (5 to 10 business days)</MenuItem>
                                <MenuItem value={"USPS Priority Mail (10 to 15 business days)"}>USPS Priority Mail (10 to 15 business days)</MenuItem>
                                <MenuItem value={"UPS Ground (5 to 10 business days)"}>UPS Ground (5 to 10 business days)</MenuItem>
                                <MenuItem value={"UPS Ground (10 to 15 business days)"}>UPS Ground (10 to 15 business days)</MenuItem>
                                <MenuItem value={"FedEx Ground (5 to 10 business days)"}>FedEx Ground (5 to 10 business days)</MenuItem>
                                <MenuItem value={"FedEx Ground (10 to 15 business days)"}>FedEx Ground (10 to 15 business days)</MenuItem>
                              </TextField>
                              <code className="offerservice" style={{ color: '#388087', left: "0" }}>
                                Offer additional service
                              </code>
                            </div>
                          </div>

                          <div
                            className="col-md-3 col-sm-6 col-xs-12"
                            style={{ display: showCostDiv && "none" }}
                          >
                            <div className="myform_dv">
                              <label>Cost</label>
                              <input
                                type="text"
                                value={requestData.shipping_cost}
                                min="0"
                                name="shipping_cost"
                                placeholder="$"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const numericValue = value.replace(/[^0-9.]/g, '');

                                  if (numericValue === '' || parseFloat(numericValue) <= 100) {
                                    setrequestData({
                                      ...requestData,
                                      shipping_cost: numericValue,
                                    });
                                  }



                            
                                }}
                                className="myform_field"
                              />
                            </div>
                          </div>

                          <div className="col-md-3 col-sm-6 col-xs-12">
                            <div
                              className="myform_dv_chk"
                              style={{ paddingTop: "41px" }}
                            >
                              <input
                                className="styled-checkbox"
                                onChange={(e) => handleData(e)}
                                value={requestData.isFreeShipping}
                                id="free_shipping"
                                type="checkbox"
                                name="free_shipping"
                              />

                              <label htmlFor="free_shipping">
                                Free shipping
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="myform_dv">
                              <label>Handling Time<span>*</span></label>
                              <TextField
                                select
                                label="Choose Handling Time"
                                {...register2("handling_time", {
                                  required: true,
                                  value: requestData.handling_time,
                                  onChange: (e) => {
                                    setrequestData({
                                      ...requestData,
                                      handling_time: e.target.value,
                                    });
                                  },
                                })}
                                value={requestData.handling_time}
                                variant="outlined"
                                error={errors2.handling_time}
                                helperText={
                                  errors2.handling_time && " Handling Time is required"
                                }
                                className="myform_field mf_styl2"

                              >
                                <MenuItem value={"1 business day"}>1 business day</MenuItem>
                                <MenuItem value={"2 business days"}>2 business days</MenuItem>
                                <MenuItem value={"3 business days"}>3 business days</MenuItem>
                                <MenuItem value={"4 business days"}>4 business days</MenuItem>
                              </TextField>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <div
                              className="myform_dv"
                              style={{ display: marketingCost && "none" }}
                            >
                              <label>Offer Discount</label>
                              <input
                                type="text"
                                value={requestData.offer_discount}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const numericValue = value.replace(/[^0-9.]/g, '');

                                  if (numericValue === '' || parseFloat(numericValue) <= 100) {
                                    setrequestData({
                                      ...requestData,
                                      offer_discount: numericValue,
                                    });
                                    setDiscounOptionPercentage(numericValue)
                                  }
                                }}
                                className="myform_field"
                                placeholder="0.00"
                              />
                              <ul className="myform_dv_list" style={{ marginBottom: 20, marginTop: -20 }}>
                                <li sx={{ mt: -20 }}>
                                  Charge $0.05 cents to offer a discount
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12 col-xs-6">
                            <div className="myform_dv">
                              <label>Marketing Option</label>
                              <TextField
                                select
                                label="Choose Marketing Option"
                                value={requestData.marketing_option}
                                onChange={(e) => handleMarketingOption(e)}
                                variant="outlined"
                                className="myform_field mf_styl2"
                              >
                                <MenuItem value="">Select Marketing Option</MenuItem>
                                <MenuItem value={"Promoted Listing"}>Promoted Listing</MenuItem>
                              </TextField>

                            </div>
                          </div>

                          <div className="col-md-4 col-sm-6 col-xs-12">

                            {showPercentageDiv &&
                              (
                                <div
                                  className="myform_dv"
                                  style={{ display: marketingCost && "none" }}
                                >
                                  <label>Percentage per sale</label>
                                  <input
                                    type="number"
                                    value={requestData.marketing_option_per}
                                    min="0" max="100"
                                    onChange={(e) => { 
                                      PercentagePerSale(e)
                                      setMarketingOptionPercentage(e.target.value);
                                     }}
                                    className="myform_field"
                                    placeholder="%"
                                  />
                                </div>
                              )
                            }
                          </div>
                          <div className="col-12"></div>
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="msf_price_fees">
                              <b>
                                Fees <span>*</span>
                              </b>
                              <p>${(parseFloat(requestData?.fees) + percentageAmount).toFixed(2)}</p>
                            </div>
                          </div>


                          <div style={{ marginBottom: '18px' }} className="col-12">
                            <ul className="myform_dv_list">
                              <li>
                                If your item sells, you will be charged a{" "}
                                <Link to="/">final value fee.</Link>
                              </li>
                              <li>
                                Funds from your sales may be unavailable and
                                show as pending for a period of time.{" "}
                                <Link to="/">Learn more</Link>
                              </li>
                              <li>
                                By selecting List item, you agree to pay the
                                above fees; accept the{" "}
                                <Link to="/">Zourie User Agreement</Link>,{" "}
                                <Link to="/">Payments Terms</Link> of Use and{" "}
                                <Link to="/">Marketing Terms of Service</Link>;
                                acknowledge reading the{" "}
                                <Link to="/">User Privacy Notice</Link>; and
                                assume full responsibility for the item offered
                                and the content of your listing.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="msf_footer">
                        {msfCount != 1 && (
                          <button className="button1" onClick={handle_Msf_Prev}>
                            Previous Step
                          </button>
                        )}
                        {msfCount !== msfMaxTab && (
                          <>
                            {msfCount === 1 && (
                              <button
                                className="button1"
                                onClick={handleSubmit(handle_Msf_Next)}
                              >
                                {" "}
                                Next Step{" "}
                              </button>
                            )}
                            {msfCount === 2 && (
                              <button
                                className="button1"
                                onClick={handle_Msf_Next}
                              >
                                {" "}
                                Next Step 2{" "}
                              </button>
                            )}
                            {msfCount === 3 && (
                              <button
                                className="button1"
                                onClick={handle_Msf_Next}
                              >
                                {" "}
                                Next Step 3{" "}
                              </button>
                            )}
                          </>
                        )}

                        {msfCount === msfMaxTab && (
                          <button
                            className="button1"
                          >
                            List Item
                          </button>
                        )}
                       
                        {msfCount === msfMaxTab && (
                          <button
                            className="button2"
                            onClick={(e) => {
                              e.preventDefault();
                              handleClickSaveAsDraft();
                            }}
                          >
                            Save as draft
                          </button>
                        )}
                        {msfCount === msfMaxTab && (
                          <Link to={"/vendor"} className="button2">
                            Cancel
                          </Link>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutOne>
    </Fragment>
  );
};

AddListingPro.propTypes = {
  category: PropTypes.string,
  saveProduct: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    category: state.categoriesData.categories,
    saveProduct: state.saveProductData.product,
  };
};

export default connect(mapStateToProps)(AddListingPro);