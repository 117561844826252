import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";

import LayoutOne from "../../layouts/LayoutOne";
import Sidebar from "./Sidebar";

import SalesHistory from "./components/SalesHistory";
import Widgets from "./components/Widgets";
import SellerPerformance from "./components/SellerPerformance";
import VendorOrders from "./components/VendorOrders";

import { fetchMessagesData } from "../../redux/actions/getMessagesActions";
import { fetchMyStats, fetchSalesHistory } from "../../redux/actions/dashboardActions";
import Loader from "../other/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector(store => store.dashboard)
  console.log(dashboardData,"dashboardData")
  const [sbactive] = useState('Dashboard');

  const messageData = useSelector(store => store.messageData)
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    dispatch(fetchSalesHistory());
    dispatch(fetchMyStats(setLoader));
    dispatch(fetchMessagesData());
  }, [dispatch]);

  return (
    <Fragment>
      <MetaTags>
        <title>Zourie | Vendor Dashboard</title>
        <meta
          name="description"
          content="Compare page of Zourie react minimalist eCommerce template."
        />
      </MetaTags>
      <LayoutOne headerTop="visible" header3="hide">
        {loader ? <Loader /> : null}
        <section className="information_panels">
          <div className="container">
            <div className="row">

              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 info_pnl_sidebar">
                <Sidebar sbactive={sbactive} />
              </div>

              <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12 info_pnl_body">
                <div className="info_pnl_body_dv no_info_pnl_body_dv">
                  <div className="row">

                    <Widgets dashboardData={dashboardData} messageData={messageData.messagesData} />


                    <div className="col-12 das_box_dv">
                      <SalesHistory salesHistory={dashboardData.saleshistory} />
                    </div>

                    <div className="col-12 das_box_dv">
                      <SellerPerformance />
                    </div>
                    
                  

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </LayoutOne>
    </Fragment>
  );
};

export default Dashboard;