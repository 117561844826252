import {FETCH_BANNER_SUCCESS} from "../actions/bannerActions";

const initState = {
  banners: []
};

const bannerReducer = (state = initState, action) => {
  if (action.type === FETCH_BANNER_SUCCESS) {
    return {
      ...state,
      banners: action.payload
    };
  }

  return state;
};

export default bannerReducer;