import axios from "axios";
import { CATEGORIES } from "../../consts";

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";




const fetchCategoriesSuccess = categories => ({
    type: FETCH_CATEGORIES_SUCCESS,
    payload: categories
});


    
export const fetchCategories = categories => {
    return dispatch => {
        axios.get(CATEGORIES)
        .then(response => {
            dispatch(fetchCategoriesSuccess(response.data));
        })

        .catch(error => {
            console.log(error);
        })
    };

};