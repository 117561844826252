import PropTypes from "prop-types";
import React from "react";
import { multilanguage } from "redux-multilanguage";
import { connect, useDispatch } from "react-redux";
import { setCurrency } from "../../redux/actions/currencyActions";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from "react-router-dom";
import { UserLogout } from "../../redux/reducers/Login-Register";

const HeaderTop = ({
  user
 
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = (e) => {
    e.preventDefault();
    
    dispatch(UserLogout(history));
    history.push('/login');
  }
  return (
    <>


      <ul className="header1_menu">
        <li style={{ marginRight: 'auto' }}><Link to="/"><img src={process.env.PUBLIC_URL + '/assets/images/shop_with_confidence.svg'} alt="Shop With Confidence" />Shop with confidence, every seller is verified through Zourie!</Link></li>
        <li><Link to="/page/our-mission"><img src={process.env.PUBLIC_URL + '/assets/images/bullseye.svg'} alt="Our Mission Icon" />Our Mission</Link></li>
        { user && 
        <li className="user_account_dropdown">
          <Dropdown>
            <Dropdown.Toggle> Welcome, { user && user.first_name} </Dropdown.Toggle>
            <Dropdown.Menu className="header1_dropdown">
              <ul className="header1_menu">
                <li><Link to="/vendor-account">Go To Account</Link></li>
                <li><form onClick={onSubmit}>Logout</form></li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        }
      </ul>




     




    </>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    user: state.userData.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrency: currencyName => {
      dispatch(setCurrency(currencyName));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(HeaderTop));
