export const FETCH_PAGINATED_DATA_SUCCESS = "FETCH_PAGINATED_DATA_SUCCESS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const fetchPaginatedDataSuccess = (data) => ({
  type: FETCH_PAGINATED_DATA_SUCCESS,
  payload: data,
});

export const setCurrentPage = (currentPage) => ({
  type: SET_CURRENT_PAGE,
  payload: currentPage,
});
