import { FETCH_PRODUCTS_SUCCESS, SET_PRODUCT_FILTER, PRODUCT_SEARCH_RESULTS, SUCCESS_PRODUCT_IMAGE } from "../actions/productActions";

const initState = {
  products: [],
  loading: true,
  searchedproducts: [],
  total_searchproduct: 0,
  searchfilter: {
    category: null,
    product_type: null,
    product_title__icontains: null,
    service_cost: null,
    condition: null,
    actual_price__gte: null,
    actual_price__lte: null,
    actual_price: "",
    limit: 10,
    page: 0
  }
};

const productReducer = (state=initState, action) => {
  if (action.type === FETCH_PRODUCTS_SUCCESS){
    return{
      ...state,
      products: action.payload
    };
  }else if (action.type === SET_PRODUCT_FILTER){
    var data = action.payload
    return{
      ...state,
      searchfilter: {...state.searchfilter, ...data}
    };
  }else if (action.type === PRODUCT_SEARCH_RESULTS){
    return{
      ...state,
      searchedproducts: action.payload.data,
      total_searchproduct: action.payload.total,
      totalNumberOfPages: action.payload.num_pages
    };
  }
  else if (action.type === SUCCESS_PRODUCT_IMAGE){
    return{
      ...state,
      productImage: action.payload
    };
  }
  return state;
}


export default productReducer;