import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Space } from "antd";
import "antd/dist/antd.css";
import { useLocation } from "react-router-dom";
import NewOrdersPopup from "./NewOrdersPopup";
import ReactPaginate from 'react-paginate';
import { useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ProductImage from '../../../assets/assets/images/dummy_zourie_auctions1.png';
import { orderItemReturn, orderDetails } from "../../../redux/actions/orderActions";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Rating from "../../../components/product/sub-components/ProductRating";


import Modal from '@mui/material/Modal';
import LeaveFeedbackHistory from "./LeaveFeedbackHistory";
import CancelOrderHistory from "./CancelOrderHistory";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

const NewOrders = ({ purchaseHistory }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  const handleOrderDetails = (id) => {
    dispatch(orderDetails(
      id,
      setLoading
    ))
  }

  const AccordionSummary = styled((MuiAccordionSummary))(({ theme }) => ({
    '& .MuiAccordionSummary-content': {
      display: "block",
    },
  }));



  const [todayDate, setTodayDate] = useState(moment())


  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '920px',
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    borderRadius: '16px',
  };
  const [open, setOpen] = React.useState(false);
  const [trackOrder, setTrackOrder] = useState(false);
  const handleOpen = (i) => {
    setTrackOrder(i);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);



  const [inFeedback, setInFeedback] = useState(null);
  const [cancelProduct, setCancelProduct] = useState(null);


  const [open2, setOpen2] = useState(false);


  const handleFeedbackProduct = (productData) => {
    setOpen2(true);
    setInFeedback(productData);
  }

  const handleClose2 = () => {
    setOpen2(false);
  }


  const [open3, setOpen3] = useState(false);
  const handleCancelProduct = (productData) => {
    setOpen3(true);
    setCancelProduct(productData);
  }
  const handleClose3 = () => {
    setOpen3(false);
  }


  const [slider, setSlider] = useState(false)

  let screenWidth = window.screen.width;

  useEffect(() => {
    if (screenWidth < 575) {
      setSlider(true)
    }
    else {
      setSlider(false)
    }
  }, [])




  return (
    <>
      <div className="nav_tab_wrrpr">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box >
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Orders" value="1" />
                <Tab label="Cancelled or Returned" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" className="purchase_history_wrapnation">
              <div className="purchase_history_wrrpr">


           
                {
                  purchaseHistory.filter((i) => i.order_items.filter((i) => i.item_status !== "Cancelled").length > 0).map((item, index) => (
                    <Accordion key={index}    >
                      <AccordionSummary className="purchase_hishtory_data_wrapnation">
                        <Typography className="purchase_history_data">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 purchase_data">
                              <h3>Order Date</h3>
                              <h2>{moment(item?.created_at).format('ll')}</h2>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-3 purchase_data">
                              <h3>Order Total</h3>
                              <h2>${item?.order_total}</h2>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 purchase_data">
                              <h6><span>Order Number:</span> # {item.id}</h6>
                            </div>


                          </div>
                        </Typography>
                      </AccordionSummary>

                      {
                        item?.order_items?.filter((i) => i.item_status !== "Cancelled").map((i) => (
                          <AccordionDetails
                           key={i.id} 
                          onClick={() => {
                            history.push('/order-details');
                            handleOrderDetails(i?.order_id?.id)
                          }
                          }>
                            <Typography className="purchase_history_toggle_data">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-6">
                                  <img src={i.product.product_image[0].upload_image} alt="" />
                                </div>
                                <div className="col-lg-6 col-md-5 col-6 purchase_history_title_data">
                                  <h1>{i.product.product_title}</h1>
                                  <p dangerouslySetInnerHTML={{ __html: i.product.description }}></p>
                                  <ul className="star_rating star_color">
                                    <Rating ratingValue={i.product.review_avg} />
                                  </ul>

                                </div>
                                <div className="col-lg-3 col-md-3 col-12 btns-dv">
                                  {i.item_status === "Shipped" &&
                                    <button className="btn_purchase" onClick={() => handleOpen(i)}>Track Order</button>
                                  }

                                  {i.item_status === "InOrder" &&
                                    <>
                                      <button className="btn_purchase" onClick={() => handleCancelProduct(i)}>Cancel</button>
                                    </>
                                  }
                                  {i.item_status === "Delivered" &&
                                  <>
                                    {
                                      moment(todayDate).add(i.return_days, 'days').format('YYYYMMDDhmmss') > moment(todayDate).format('YYYYMMDDhmmss') ? (
                                        <button className="btn_purchase" onClick={() => handleCancelProduct(i)}>Return Request</button>
                                      ) : (
                                        ''
                                      )
                                    }
                                    <button className="btn_purchase" onClick={() => handleFeedbackProduct(i)}>Leave a feedback</button>
                                  </>
                                  } 
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        ))}
                    </Accordion>
                  ))}
             
              </div>
      

            </TabPanel>
            <TabPanel value="2">

              <table className="table table-striped das_box_tbl">
                <thead>
                  <tr>
                    <th>Order Date</th>
                    <th>Order Id</th>
                    <th>Product</th>
                    <th>Cost</th>
                    <th>Order Status</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseHistory?.map((item) => {
                    return (
                      <>
                        {item.order_items.map((item) => {
                          return (
                            <>
                              {item.item_status === "Cancelled" || item.item_status === "Returned" ? (
                                <>
                                  <tr>
                                    <td>{moment(item?.created_at).format('ll')}</td>
                                    <td className="product_id">
                                      <h5>Order ID: <span>#{item.order_id.id}</span></h5>
                                    </td>
                                    <td className="product_detail">
                                      <img src={item.product.product_image[0].upload_image} alt="" />
                                      <div>
                                        <h4>{item.product.product_title}</h4>
                                        <h3>Price: ${item.unit_price}</h3>
                                        <h3>Qty: {item.quantity}</h3>
                                      </div>
                                    </td>
                                    <td className="product_cost">
                                      <h5>Shipping Cost: <span>${item.shipping_price}</span></h5>
                                      <h5>Total Cost: <span>${item.sub_total}</span></h5>
                                    </td>
                                    <td>
                                      {item.item_status}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                </>
                              )}
                            </>
                          )
                        })
                        }
                      </>
                    )
                  })
                  }
                </tbody>
              </table>

            </TabPanel>
          </TabContext>

        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className='cancel_order_modal_close'>
              <FontAwesomeIcon onClick={() => handleClose()} icon={faXmark} />
            </div>
            <div className="modal_wrrpr">
              <div className="modal_header">
                <div classnName="container">
                  <div className="row">
                    <div className="col-3">
                      <h5>Estimated Delivery Date:</h5>
                      <h6>Feb 20,2023</h6>
                      <h2>Monday</h2>
                    </div>
                    <div className="col-9">
                      <div className="product_description_wrrpr">
                        <img src={trackOrder && trackOrder.product.product_image[0].upload_image} alt="" />
                        <div className="product_description">
                          <h1>{trackOrder && trackOrder.product.product_title}</h1>
                          <p dangerouslySetInnerHTML={{ __html: trackOrder && trackOrder.product.description }}></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product_description_bar">
                <h2>Your order is on the way</h2>
              </div>
              <div className="modal_body">
                <div className="col-12">
                  <h6><span>Tracking ID</span> SL0008394</h6>
                </div>

                <div className="modal_body_accordian">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Hide order tracking details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="modal_body_accordian_data" >
                        <div className="container">
                          <div className="row">
                            <div className="col-3">
                              <h6>Feb 16,2023</h6>
                              <h5>Thursday, 07:30PM</h5>
                            </div>
                            <div className="col-9">
                              <h6>Order this item</h6>
                              <h3>New York, USA</h3>
                            </div>
                          </div>
                        </div>
                      </Typography>
                      <Typography className="modal_body_accordian_data" >
                        <div className="container">
                          <div className="row">
                            <div className="col-3">
                              <h6>Feb 16,2023</h6>
                              <h5>Thursday, 07:30PM</h5>
                            </div>
                            <div className="col-9">
                              <h6>Order this item</h6>
                              <h3>New York, USA</h3>
                            </div>
                          </div>
                        </div>
                      </Typography>
                      <Typography className="modal_body_accordian_data" >
                        <div className="container">
                          <div className="row">
                            <div className="col-3">
                              <h6>Feb 16,2023</h6>
                              <h5>Thursday, 07:30PM</h5>
                            </div>
                            <div className="col-9">
                              <h6>Order this item</h6>
                              <h3>New York, USA</h3>
                            </div>
                          </div>
                        </div>
                      </Typography>
                      <Typography className="modal_body_accordian_data" >
                        <div className="container">
                          <div className="row">
                            <div className="col-3">
                              <h6>Feb 16,2023</h6>
                              <h5>Thursday, 07:30PM</h5>
                            </div>
                            <div className="col-9">
                              <h6>Order this item</h6>
                              <h3>New York, USA</h3>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>



          </Box>
        </Modal>
      </div>

      <LeaveFeedbackHistory
        open2={open2}
        inFeedback={inFeedback}
        handleClose2={handleClose2}
      />
      <CancelOrderHistory
        open3={open3}
        cancelProduct={cancelProduct}
        handleClose3={handleClose3}
      />
    </>
  )
};

export default NewOrders;
