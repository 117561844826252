import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const ProductRating = ({ ratingValue }) => {
  let rating = [];

  for (let i = 0; i < 5; i++) {
    rating.push(<li className="star_empty"><FontAwesomeIcon icon={faStar} /></li>);
  }
  if (ratingValue && ratingValue > 0) {
    for (let i = 0; i <= ratingValue - 1; i++) {
      rating[i] = <li><FontAwesomeIcon icon={faStar} /></li>;
    }
  }
  return <Fragment>
    <ul className='star_rating' >
      {rating}
      <li className='star_count'></li>
    </ul>
  </Fragment>;
};

ProductRating.propTypes = {
  ratingValue: PropTypes.number
};

export default ProductRating;
